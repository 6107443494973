import { CacheTags, diosApi } from 'api/diosApi';
import {
  Assessment,
  PaginatedResult,
  PortfolioValue,
  System,
  SystemBudget,
  SystemByAssessment,
  SystemFilter,
  SystemPaginatedResult,
  SystemsProgress,
  Turnover,
} from 'models';

const statisticsApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get overview for current customer
     */
    getOverview: build.query<
      SystemByAssessment[],
      {
        filter: SystemFilter;
        _customerId?: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        `systems/overview${filterToQuery(filter)}`,
      providesTags: [CacheTags.Overview],
    }),

    /**
     * Get portfolio value for current customer
     */
    getPortfolioValue: build.query<
      PortfolioValue,
      {
        filter: SystemFilter;
        _customerId?: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        `systems/overview/systems/value${filterToQuery(filter)}`,
      providesTags: [CacheTags.SystemPortfolioValue],
    }),

    /**
     * Get overview for current customer
     */
    getTurnover: build.query<
      Turnover,
      {
        filter: SystemFilter;
        _customerId?: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        `systems/overview/turnover${filterToQuery(filter)}`,
      providesTags: [CacheTags.SystemTurnover],
    }),

    /**
     *  Get system list overview
     */
    getSystemsOverview: build.query<
      PaginatedResult<SystemBudget>,
      {
        filter: SystemFilter;
        _customerId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        `systems/overview/systems${systemListFilterToQuery(filter)}`,
      providesTags: [CacheTags.SystemOverview],
    }),

    /**
     *  Get system analytics
     */
    getSystemsAnalytics: build.query<
      SystemPaginatedResult<System>,
      {
        filter: SystemFilter;
        _customerId: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        `systems/overview/systems/analytics${systemListFilterToQuery(filter)}`,
      providesTags: [CacheTags.SystemsAnalytics],
    }),

    /**
     *  Get systems progress
     */
    getSystemsProgress: build.query<
      SystemsProgress,
      {
        filter: SystemFilter;
        _customerId?: string;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId }) =>
        `systems/overview/progress${filterToQuery(filter)}`,
      providesTags: [CacheTags.SystemProgress],
    }),
  }),
});

export const {
  useGetOverviewQuery,
  useGetTurnoverQuery,
  useGetSystemsOverviewQuery,
  useLazyGetSystemsOverviewQuery,
  useGetSystemsAnalyticsQuery,
  useLazyGetSystemsAnalyticsQuery,
  useGetSystemsProgressQuery,
  useGetPortfolioValueQuery,
} = statisticsApi;

const filterToQuery = (filter: SystemFilter): string =>
  `?${filter.instance?.id ? `&versionInstanceId=${filter.instance.id}` : ''}${
    filter.allCustomers ? `&allCustomers=${filter.allCustomers}` : ''
  }${
    filter.assessments && filter.assessments.length > 0
      ? filter.assessments
          .map(
            (assessment) =>
              `&filter.assessment=${
                assessment === Assessment.NotSet ? '' : assessment
              }`
          )
          .join('')
      : ''
  }${
    filter.entityIds && filter.entityIds.length > 0
      ? filter.entityIds
          .map((entityId) => entityId && `&filter.entityIds=${entityId}`)
          .join('')
      : ''
  }`;

const systemListFilterToQuery = (filter: SystemFilter): string =>
  `?pageNumber=${filter.paginator.page}&pageSize=${
    filter.paginator.pageSize
  }&orderBy.propertyName=${filter.paginator.orderBy}&orderBy.direction=${
    filter.paginator.orderDirection
  }${
    filter.instance?.id ? `&filter.versionInstanceId=${filter.instance.id}` : ''
  }${
    filter.assessments && filter.assessments.length > 0
      ? filter.assessments
          .map(
            (assessment) =>
              `&filter.assessment=${
                assessment === Assessment.NotSet ? '' : assessment
              }`
          )
          .join('')
      : ''
  }${
    filter.tagIds && filter.tagIds.length > 0
      ? filter.tagIds.map((tagId) => `&filter.tags=${tagId}`).join('')
      : ''
  }${
    filter.entityIds && filter.entityIds.length > 0
      ? filter.entityIds
          .map((entityId) => entityId && `&filter.entityIds=${entityId}`)
          .join('')
      : ''
  }`;
