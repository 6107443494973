import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Paginator, SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLazyGetSystemsQuery } from 'api/system/systemApi';
import { selectSelectedSystem, systemActions } from 'api/system/systemSlice';
import { EntitiesTree } from 'features/administrate-organisation/components/EntitiesTree';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { System } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowInDown, BsPlus } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isAdminReadOnly } from 'utils';
import { RespondentsFailedMessagesBanner } from './components/RespondentsFailedMessagesBanner';
import { SystemProperties } from './components/SystemProperties';
import { SystemsImport } from './components/SystemsImport';
import { SystemsList } from './components/SystemsList';

export const AdministrateSystem = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedEntity = useAppSelector(selectSelectedEntity);
  const selectedSystem = useAppSelector(selectSelectedSystem);
  const navigate = useNavigate();

  const location = useLocation();
  const searchedEntityId = (location?.state as Record<string, string>)
    ?.systemEntityId as string;
  const searchedSystemId = (location?.state as Record<string, string>)
    ?.systemId as string;
  const [selectedEntityId, setSelectedEntityId] = useState<string>(
    searchedEntityId || ''
  );
  const [searchedSystemForId, setSearchedSystemForId] = useState<string>(
    searchedSystemId || ''
  );

  const [isImporting, setIsImporting] = useState(false);
  const [isSelectingFile, setIsSelectingFile] = useState(false);

  const isFailedMessagesEnabledFlagOn =
    useFlags()[flags.permanent.app.dios.isFailedMessagesEnabled];

  const { data: entities } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 50,
    orderBy: 'name',
    orderDirection: SortDirection.Asc,
  };

  const [getSystems, { data: systems, isLoading, isFetching }] =
    useLazyGetSystemsQuery();

  const handleGetSystems = (paginator: Paginator, searchTerm?: string) => {
    accessToken.customerId &&
      selectedEntity &&
      getSystems(
        {
          filter: {
            paginator,
            searchTerm: searchTerm || '',
            entityIds: [selectedEntity?.id],
          },
          _customerId: accessToken.customerId,
        },
        true
      )
        .unwrap()
        .then((result) => {
          if (searchedSystemForId) {
            const system = result?.records?.find(
              (system) => system.id === searchedSystemId
            );
            if (system) {
              dispatch(
                systemActions.selectedSystem({
                  ...system,
                } as System)
              );
              setSearchedSystemForId?.('');
            }
          }
        });
  };

  const handleCreate = () => {
    if (isReadOnly || !selectedEntity) {
      return;
    }
    setIsImporting(false);

    dispatch(systemActions.selectedSystem({}));
  };

  useEffect(() => {
    setIsSelectingFile(false);
    setIsImporting(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  useEffect(() => {
    setIsSelectingFile(false);
    setIsImporting(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.System.Title')}</Heading1>

        {selectedEntity && (
          <div>
            <BadgeMini
              tooltipTitle={t('Administrate.System.Add.TooltipTitle')}
              tooltip={t('Administrate.System.Add.Tooltip')}
              onClick={handleCreate}
              $inverted={true}
              disabled={isReadOnly}
            >
              <BsPlus />
            </BadgeMini>
          </div>
        )}
      </ViewHeader>

      <CustomerAccountInfo />

      {accessToken && !accessToken?.user?.currentCustomer.isApproved && (
        <Card>
          <AlertInfo>{t('Administrate.System.NotApproved')}</AlertInfo>
        </Card>
      )}

      {accessToken?.user?.currentCustomer.isApproved && (
        <Card>
          <CardBody isLoading={isLoading || isFetching}>
            <div style={{ marginBottom: 20 }}>
              {t('Administrate.System.Text')}
            </div>

            {isFailedMessagesEnabledFlagOn && (
              <RespondentsFailedMessagesBanner />
            )}

            <div style={{ display: 'flex', gap: 20 }}>
              <LinkWithTooltip
                isPrimary={true}
                isDisabled={isReadOnly || !selectedEntity}
                handleClick={handleCreate}
                title={t('Administrate.System.Add.TooltipTitle')}
                tooltipTitle={t('Administrate.System.Add.TooltipTitle')}
                tooltip={t('Administrate.System.Add.Tooltip')}
                icon={<BsPlus />}
                style={{ marginBottom: 20 }}
              />

              <LinkWithTooltip
                style={{ marginBottom: 20 }}
                isDisabled={isReadOnly || !selectedEntity}
                handleClick={() => {
                  setIsSelectingFile(true);
                  dispatch(systemActions.selectedSystem());
                }}
                title={
                  isImporting
                    ? t('Administrate.System.Import.SelectNewFile')
                    : t('Administrate.System.Import.Title')
                }
                tooltipTitle={t('Administrate.System.Import.TooltipTitle')}
                tooltip={t('Administrate.System.Import.Tooltip')}
                icon={<BsBoxArrowInDown />}
              />
            </div>

            {entities?.length === 0 && !selectedEntity ? (
              <>
                <div style={{ marginBottom: 20 }}>
                  {t('Administrate.Entity.NoEntities')}
                </div>

                <ButtonLink
                  type="button"
                  onClick={() => {
                    navigate('/administrate/organisation');
                  }}
                >
                  {t('Administrate.System.NoEntitiesAdministrate')}
                </ButtonLink>
              </>
            ) : (
              <Style>
                <EntitiesTree
                  selectedEntityId={selectedEntityId}
                  setSelectedEntityId={setSelectedEntityId}
                  isDraggable={false}
                />

                {selectedEntity && (
                  <div style={{ flexGrow: 1, maxWidth: 800 }}>
                    {((selectedSystem && selectedSystem.id && !isImporting) ||
                      (!selectedSystem && !isImporting)) && (
                      <SystemsList
                        handleCreate={handleCreate}
                        dataSystems={systems?.records}
                        handleGetSystems={handleGetSystems}
                        totalRecords={systems?.totalRecords}
                        initialPaginator={initialPaginator}
                      />
                    )}

                    {selectedSystem && !selectedSystem.id && (
                      <SystemProperties systems={systems?.records} />
                    )}

                    {!selectedSystem && (
                      <div style={{ flexGrow: 1, maxWidth: 'unset' }}>
                        <SystemsImport
                          isSelectingFile={isSelectingFile}
                          setIsSelectingFile={setIsSelectingFile}
                          isImporting={isImporting}
                          setIsImporting={setIsImporting}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Style>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

AdministrateSystem.displayName = 'AdministrateSystem';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding: 0 30px 30px 0;
  }
`;
