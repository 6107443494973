import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetFailedMessagesQuery } from 'api/system/systemApi';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const RespondentsFailedMessagesBanner = (): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthenticationContext);

  const { data, isFetching } = useGetFailedMessagesQuery(
    accessToken.customerId
      ? {
          _customerId: accessToken.customerId,
          paginator: {
            page: 1,
            pageSize: 25,
            orderBy: 'email',
            orderDirection: SortDirection.Asc,
          },
        }
      : skipToken
  );

  const handleDisplayFailedMessages = () => {
    navigate('/administrate/respondents-failed-messages');
  };

  return !isFetching && data && data.totalRecords > 0 ? (
    <AlertError style={{ marginBottom: 20 }}>
      <ButtonLink onClick={handleDisplayFailedMessages}>
        {t('Administrate.System.Respondent.FailedMessages.BannerText', {
          count: data.totalRecords,
        })}
      </ButtonLink>
    </AlertError>
  ) : null;
};

RespondentsFailedMessagesBanner.displayName = 'RespondentsFailedMessagesBanner';
