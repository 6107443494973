import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  CardFocused,
  CardFocusedBody,
  CardRow,
  CardTile,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CacheTags, diosApi } from 'api/diosApi';
import { selectFilter } from 'api/diosSlice';
import {
  useGetSystemDataQuery,
  useUpdateSystemDataMutation,
} from 'api/system/systemDataApi';
import { useUpsertSystemRelationsMutation } from 'api/system/systemRelationApi';
import {
  useAddSystemTagMutation,
  useDeleteSystemTagMutation,
} from 'api/system/systemTagApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Business,
  CommonRoles,
  Data,
  It,
  Permission,
  SystemRelation,
  Tag,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { hasPermission, isDataReadOnly } from 'utils';
import { BusinessEdit } from './components/BusinessEdit';
import { ItEdit } from './components/ItEdit';
import { RelationsEdit } from './components/RelationsEdit';
import { SystemListEditButtons } from './components/SystemListEditButtons';
import { SystemTagList } from './components/SystemTagList';

/* eslint-disable max-lines-per-function */

export const System = (): JSX.Element | null => {
  const { systemId = '' } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { accessToken } = useContext(AuthenticationContext);
  const isReader = accessToken.isInRole(CommonRoles.Reader);

  const isViewReadOnly = isDataReadOnly(accessToken);

  const systemFilter = useAppSelector(selectFilter);

  const [hasChanges, setHasChanges] = useState(false);
  const [system, setSystem] = useState<Data>();
  const [dataIt, setDataIt] = useState<It | undefined>();
  const [dataBusiness, setDataBusiness] = useState<Business | undefined>();
  const [relations, setRelations] = useState<SystemRelation[]>();
  const [isRelationsValid, setIsRelationsValid] = useState(true);
  const [tags, setTags] = useState<Tag[]>();

  const isSystemConnectionsEnabledFlagOn =
    useFlags()[flags.permanent.app.dios.isSystemConnectionsEnabled];

  const isCurrentPeriod =
    !!(
      systemFilter.instance?.startDate &&
      parseISO(systemFilter.instance.startDate) < new Date()
    ) &&
    !!(
      !systemFilter.instance?.endDate ||
      parseISO(systemFilter.instance.endDate) > new Date()
    );

  const [
    updateSystemData,
    { isLoading: updatePostingData, error: updateDataError },
  ] = useUpdateSystemDataMutation();

  const [
    updateSystemRelations,
    { isLoading: updatePostingRelations, error: updateRelationsError },
  ] = useUpsertSystemRelationsMutation();

  const [deleteSystemTag] = useDeleteSystemTagMutation();
  const [addSystemTag] = useAddSystemTagMutation();

  const {
    data,
    isLoading,
    isFetching,
    error: getSystemError,
  } = useGetSystemDataQuery(
    accessToken.customerId &&
      accessToken.user?.id &&
      systemId &&
      systemFilter.isInstancesLoaded &&
      systemFilter.instance?.id
      ? {
          customerSystemId: systemId,
          versionInstanceId: systemFilter.instance?.id,
          _customerId: accessToken.customerId,
          _userId: accessToken.user?.id,
        }
      : skipToken
  );

  const updatePosting = updatePostingData || updatePostingRelations;
  const error = getSystemError || updateDataError || updateRelationsError;

  const canEdit = hasPermission(Permission.Edit, data);
  const canEditIt = hasPermission(Permission.EditIt, data);
  const canEditBusiness = hasPermission(Permission.EditBusiness, data);

  const invalidateCacheTags = () => {
    dispatch(
      diosApi.util.invalidateTags([
        CacheTags.SystemData,
        CacheTags.SystemsMy,
        CacheTags.Systems,
        CacheTags.SystemsAnalytics,
        CacheTags.SystemsSearch,
        CacheTags.Overview,
        CacheTags.SystemPortfolioValue,
        CacheTags.SystemTurnover,
        CacheTags.SystemOverview,
        CacheTags.SystemProgress,
        CacheTags.SystemHistory,
        CacheTags.SystemWithRelations,
      ])
    );
  };

  const handleBack = () => {
    navigate((location.state as { from: string })?.from ?? `/systems`);
  };

  const handleSave = () => {
    if (isViewReadOnly) {
      return;
    }

    if (!data) {
      return;
    }

    setIsRelationsValid(true);

    const isRelationsInvalid = relations?.some(
      (relation) =>
        !relation.fromCustomerSystemId ||
        !relation.toCustomerSystemId ||
        relation.fromCustomerSystemId === relation.toCustomerSystemId
    );

    if (isRelationsInvalid) {
      setIsRelationsValid(false);
      return;
    }

    updateSystemData({
      data: {
        ...data,
        it: dataIt,
        business: dataBusiness,
      },
      customerSystemId: systemId,
    })
      .unwrap()
      .then(() => {
        if (canEditIt && isSystemConnectionsEnabledFlagOn) {
          updateSystemRelations({
            relations: relations ?? [],
            customerSystemId: systemId as string,
          })
            .unwrap()
            .then(() => {
              addUpdateTags();
              invalidateCacheTags();
            });
        } else {
          addUpdateTags();
          invalidateCacheTags();
        }
      });

    setHasChanges(false);
  };

  const addUpdateTags = () => {
    const initialTags = data?.tags;

    const tagsToAdd = tags?.filter((tag) => !initialTags?.includes(tag)) ?? [];

    const tagsToDelete =
      initialTags?.filter((tag) => !tags?.includes(tag)) ?? [];

    const tagsAdd = tagsToAdd.filter((tag) => tag.id).map((tag) => tag.id);

    const tagsDelete = tagsToDelete
      .filter((tag) => tag.id)
      .map((tag) => tag.id);

    if (tagsAdd.length > 0) {
      addSystemTag({
        customerSystemId: systemId,
        tags: tagsAdd,
      })
        .unwrap()
        .then(() => {
          if (tagsDelete.length > 0) {
            deleteSystemTag({
              customerSystemId: systemId,
              tags: tagsDelete,
            });
          }
        });
    }
    if (tagsAdd.length === 0 && tagsDelete.length > 0) {
      deleteSystemTag({
        customerSystemId: systemId,
        tags: tagsDelete,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setSystem(data);
      setDataIt(data?.it);
      setDataBusiness(data?.business);
      setRelations(data.relations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.tags) {
      setTags(data?.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.tags]);

  return (
    <>
      <Style>
        <div>
          <BackButton handleBack={handleBack} />
        </div>

        <Heading1>{system?.name}</Heading1>

        <SelectExtendedFilters
          isAssessmentFilterShown={false}
          isOnlyPeriodsWithDataShown={true}
          isTagFilterShown={false}
          isEntityFilterShown={false}
        />

        {data && (
          <ViewHeaderActions style={{ marginBottom: 0 }}>
            <SystemListEditButtons
              systemId={systemId}
              handleSave={handleSave}
              canSave={
                !(updatePosting || !canEdit || !hasChanges || isViewReadOnly)
              }
            />
          </ViewHeaderActions>
        )}
      </Style>

      <CardFocused>
        <CardFocusedBody isLoading={isLoading || isFetching || updatePosting}>
          <PropertiesStyle>
            <CardRow>
              <CardTile>
                <Trans i18nKey="System.Text" />

                {!error &&
                  !isReader &&
                  !isCurrentPeriod &&
                  system &&
                  !isLoading &&
                  !isFetching &&
                  !updatePosting && (
                    <AlertWarning style={{ marginTop: 20 }}>
                      {t('System.ReadOnly')}
                    </AlertWarning>
                  )}

                {error && (
                  <AlertErrors
                    error={error}
                    translationPath="System.ValidationError"
                  />
                )}

                {getSystemError && getSystemError.name === 'Forbidden' && (
                  <AlertErrors
                    customValidationErrors={['NoSystem']}
                    translationPath="System.ValidationError"
                  />
                )}

                {!isRelationsValid && (
                  <AlertErrors
                    customValidationErrors={['RelationSystemIdMissing']}
                    translationPath="System.ValidationError"
                  />
                )}
              </CardTile>
            </CardRow>

            {!getSystemError && data && (
              <>
                <CardRow>
                  <CardTile>
                    <SystemTagList
                      canEdit={canEdit && !isViewReadOnly}
                      tags={tags}
                      setTags={setTags}
                      setHasChanges={setHasChanges}
                    />
                  </CardTile>
                </CardRow>

                {isSystemConnectionsEnabledFlagOn && (
                  <CardRow>
                    <CardTile>
                      <RelationsEdit
                        isValid={isRelationsValid}
                        systemId={systemId}
                        data={data}
                        canEdit={canEditIt && !isViewReadOnly}
                        relations={relations}
                        setRelations={(relations: SystemRelation[]) => {
                          setHasChanges(true);
                          setRelations(relations);
                        }}
                      />
                    </CardTile>
                  </CardRow>
                )}

                <SystemRowStyle>
                  <CardTile>
                    <ItEdit
                      systemId={systemId}
                      data={data}
                      canEdit={canEditIt && !isViewReadOnly}
                      dataIt={dataIt}
                      setDataIt={(data: It) => {
                        setHasChanges(true);
                        setDataIt(data);
                      }}
                    />

                    <BusinessEdit
                      systemId={systemId}
                      data={data}
                      canEdit={canEditBusiness && !isViewReadOnly}
                      dataBusiness={dataBusiness}
                      setDataBusiness={(data: Business) => {
                        setHasChanges(true);
                        setDataBusiness(data);
                      }}
                    />
                  </CardTile>
                </SystemRowStyle>

                <CardRow>
                  <CardTile>
                    <Buttons style={{ marginBottom: 20, marginRight: 10 }}>
                      <ButtonSecondary type="button" onClick={handleBack}>
                        {t('Common.Form.Cancel')}
                      </ButtonSecondary>

                      <Button
                        type="button"
                        disabled={
                          updatePosting ||
                          !canEdit ||
                          !hasChanges ||
                          isViewReadOnly
                        }
                        onClick={handleSave}
                      >
                        {t('Common.Form.Save')}
                      </Button>
                    </Buttons>
                  </CardTile>
                </CardRow>
              </>
            )}
          </PropertiesStyle>
        </CardFocusedBody>
      </CardFocused>
    </>
  );
};

System.displayName = 'System';

const Style = styled(ViewHeader)`
  > div:last-of-type {
    @media screen and (max-width: ${Breakpoints.small}) {
      width: 100%;
      justify-content: start;
    }
  }
`;

const PropertiesStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  gap: 15px;
`;

const SystemRowStyle = styled(CardRow)`
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    > div {
      display: flex;
      flex-direction: column;
      flex-basis: 40%;
      flex-grow: 1;

      background-color: ${({ theme }: { theme: Theme }) =>
        theme.colors.background};
      color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};

      min-width: 500px;

      padding: 20px 20px 0 20px;

      > div {
        display: flex;
        align-items: stretch;
        flex-direction: row;
        justify-content: space-between;

        border-bottom: 1px solid
          ${({ theme }: { theme: Theme }) => theme.colors.border};
        margin-bottom: 10px;

        padding: 5px 0 15px 0;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        label {
          margin-right: 10px;
        }

        > div {
          display: flex;
          align-items: center;
        }

        select,
        input:not([type='checkbox']) {
          width: 100px;
          margin-left: 10px;
          flex-shrink: 0;
          align-self: center;
        }

        select {
          width: 180px;
        }
      }
    }
  }
`;
