import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  Buttons,
  ButtonSecondary,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Admin } from '@dimatech/shared/lib/models';
import { selectSelectedSystem } from 'api/system/systemSlice';
import { useAppSelector } from 'hooks';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';

export const RespondentAdd = ({
  handleAdd,
  setIsAdding,
}: {
  handleAdd: (user: Admin) => void;
  setIsAdding: (isAdding: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [user, setUser] = useState<Admin | null>(null);

  const selectedSystem = useAppSelector(selectSelectedSystem);
  const isReadOnly = isAdminReadOnly(accessToken);

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly || !user?.email) {
      return;
    }

    handleAdd(user);
    setIsAdding(false);
  };

  if (!selectedSystem) {
    return null;
  }

  return (
    <Modal
      title={t('Administrate.System.Respondent.Add.Title')}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsAdding(false)}
    >
      <div>{t('Administrate.System.Respondent.Add.Text')}</div>

      <Label htmlFor="respondent">
        {t('Administrate.System.Respondent.Add.Email')}
      </Label>
      <SearchUser user={user} setUser={setUser} />

      <Buttons>
        <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={handleSave} disabled={isReadOnly}>
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </Modal>
  );
};

RespondentAdd.displayName = 'RespondentAdd';
