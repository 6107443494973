import { CacheTags, diosApi } from 'api/diosApi';

const systemTagApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Add system tag
     */
    addSystemTag: build.mutation({
      query: ({
        customerSystemId,
        tags,
      }: {
        customerSystemId: string;
        tags: string[];
      }) => ({
        url: `systems/${customerSystemId}/tags?${
          tags.length > 0 ? tags.map((tag) => `&tags=${tag}`).join('') : ''
        }`,
        method: 'POST',
        body: {
          tags,
        },
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemsMy,
        CacheTags.SystemsAnalytics,
        CacheTags.TagOverview,
        CacheTags.SystemData,
        CacheTags.SystemsMy,
      ],
      transformResponse: (result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     * Delete system tag
     */
    deleteSystemTag: build.mutation({
      query: ({
        customerSystemId,
        tags,
      }: {
        customerSystemId: string;
        tags: string[];
      }) => ({
        url: `systems/${customerSystemId}/tags?${
          tags.length > 0 ? tags.map((tag) => `&tags=${tag}`).join('') : ''
        }`,
        method: 'DELETE',
        body: {
          tags,
        },
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemsMy,
        CacheTags.SystemsAnalytics,
        CacheTags.TagOverview,
        CacheTags.SystemData,
        CacheTags.SystemsMy,
      ],
    }),
  }),
});

export const { useAddSystemTagMutation, useDeleteSystemTagMutation } =
  systemTagApi;
