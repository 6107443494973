import { Label } from '@dimatech/shared/lib/components/form';
import { DateTimePicker } from '@dimatech/shared/lib/components/form/DateTimePicker';
import { Theme } from '@dimatech/shared/lib/themes';
import { isPast, parseISO } from 'date-fns';
import { useId } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCheck } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { nextBankDayAtHours } from 'utils';

export const ScheduleDate = withTheme(
  ({
    date,
    label,
    handleChange,
    hasChanges,
    theme,
    isReadOnly,
  }: {
    date: string | null;
    label: string;
    handleChange: (date: Date | null) => void;
    hasChanges: boolean;
    theme: Theme;
    isReadOnly: boolean;
  }): JSX.Element => {
    const id = useId();

    const hasOccurred = date ? isPast(parseISO(date)) : false;

    return (
      <div style={{ marginTop: 10 }}>
        <div>
          <Label htmlFor={id}>{label}</Label>

          <DateTimePicker
            defaultDateOnFocus={nextBankDayAtHours(7)}
            name={id}
            date={date}
            setDate={(date) => {
              handleChange(date);
            }}
            disabled={isReadOnly || (!hasChanges && hasOccurred)}
            isValid={true}
            min={new Date()}
          />

          <BsCheck
            style={{
              fontSize: 20,
              marginLeft: 15,
              color: theme.colors.success,
              visibility: hasOccurred ? 'visible' : 'hidden',
              display: !isReadOnly && !hasOccurred ? 'none' : 'initial',
            }}
          />
        </div>
      </div>
    );
  }
);

ScheduleDate.displayName = 'ScheduleDate';
