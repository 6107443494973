import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { useVerifyMessageTokenMutation } from 'api/message/messageApi';
import { MessageTokenAction } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { ViewCentered } from 'views';

export const Go = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken, logout } = useContext(AuthenticationContext);

  const { messageToken = '' } = useParams();
  const [action, setAction] = useState<MessageTokenAction>();
  const [email, setEmail] = useState<string>();

  const [verifyMessageToken, { error }] = useVerifyMessageTokenMutation();

  useEffect(() => {
    verifyMessageToken(messageToken)
      .unwrap()
      .then((result) => {
        setAction(result.action);
        setEmail(result.email);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageToken]);

  useEffect(() => {
    if (error && accessToken.isLoggedIn) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, accessToken]);

  if (action === MessageTokenAction.Login) {
    return <Navigate to="/" state={{ email }} />;
  }

  if (action === MessageTokenAction.Welcome) {
    return <Navigate to={`/confirm`} state={{ messageToken, email }} />;
  }

  if (error) {
    return (
      <>
        <ViewCentered title={t('Go.Title')}>
          <AlertError>{t(`Go.ValidationError.${error.name}`)}</AlertError>
        </ViewCentered>
      </>
    );
  }

  return null;
};

Go.displayName = 'Go';
