import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Loader } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Paginator, SortDirection } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency } from '@dimatech/shared/lib/utils';
import { selectFilter } from 'api/diosSlice';
import { useLazyGetSystemsOverviewQuery } from 'api/statistics/statisticsApi';
import { useAppSelector } from 'hooks';
import { Assessment } from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const TableSystems = ({
  assessment,
}: {
  assessment: Assessment;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const systemFilter = useAppSelector(selectFilter);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 25,
    orderBy: 'budget',
    orderDirection: SortDirection.Desc,
  };

  const handlePaginatorChange = (paginator: Paginator = initialPaginator) => {
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId &&
      getSystemListOverview(
        {
          filter: {
            ...systemFilter,
            assessments: [assessment],
            paginator,
          },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange,
  });

  const [
    getSystemListOverview,
    { data: systems, isFetching: isFetchingSystems },
  ] = useLazyGetSystemsOverviewQuery();

  useEffect(() => {
    handlePaginatorChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemFilter]);

  if (isFetchingSystems) {
    return <Loader>{t('Common.UI.Loading')}</Loader>;
  }

  return (
    <Style>
      <Table style={{ width: '100%' }}>
        <thead>
          <Tr>
            <Th sortKey="name" sorter={sorter}>
              {t('CommonTerms.System')}
            </Th>
            <ThRight
              sortKey="budget"
              sorter={sorter}
              style={{ whiteSpace: 'nowrap' }}
            >
              {t('CommonTerms.Budget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
            </ThRight>
          </Tr>
        </thead>

        <tbody>
          {systems?.records.map((system) => (
            <Tr key={system.id}>
              <Td>{system.name}</Td>
              <TdRight>{formatAsCurrency(system.budget)}</TdRight>
            </Tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        currentPage={paginator.page}
        totalCount={systems?.totalRecords || 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />
    </Style>
  );
};

TableSystems.displayName = 'TableSystems';

const Style = styled.div`
  margin-top: 20px;

  td > div {
    color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
  }
`;
