import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { TagMutation } from '@dimatech/shared/lib/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  selectedRelationTag?: TagMutation;
} = {};

export const { reducer: relationTagReducer, actions: relationTagActions } =
  createSlice({
    name: 'system-relation-tag',
    initialState,
    reducers: {
      selectedRelationTag: (
        state,
        action: PayloadAction<TagMutation | undefined>
      ) => {
        state.selectedRelationTag = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedRelationTag = initialState.selectedRelationTag;
      });
    },
  });

export const selectSelectedRelationTag = (state: {
  relationTag: { selectedRelationTag?: TagMutation };
}): TagMutation | undefined => state.relationTag.selectedRelationTag;
