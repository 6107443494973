import { AccessToken } from '@dimatech/features-core/lib/models/token';
import {
  AuthenticatedUser,
  CommonRoleSet,
  CommonRoles,
  Permission,
  Respondent,
} from 'models';

export const isDataReadOnly = (accessToken: AccessToken) => {
  return accessToken.isInRole([CommonRoles.DemoUser, CommonRoles.Researcher]);
};

export const isRespondent = (
  user: AuthenticatedUser | null,
  respondents: Respondent[] | undefined
): boolean => {
  if (!respondents || !user) {
    return false;
  }

  return respondents.some((r) => r.email === user.email);
};

export const canEditData = (
  accessToken: AccessToken,
  respondents?: Respondent[]
) => {
  return (
    isRespondent(accessToken.user, respondents) ||
    accessToken.isInRole([
      CommonRoles.GlobalAdmin,
      CommonRoles.CustomerAccountAdmin,
      CommonRoles.CustomerAdmin,
    ])
  );
};

/**
 * Sets of common roles that are often used together when checking permissions.
 */
export const CommonRoleSets: CommonRoleSet = {
  AnyAccountAdmin: [CommonRoles.GlobalAdmin, CommonRoles.CustomerAccountAdmin],
  AnyAdmin: [
    CommonRoles.GlobalAdmin,
    CommonRoles.CustomerAdmin,
    CommonRoles.CustomerAccountAdmin,
  ],
  AnyExternal: [CommonRoles.Researcher, CommonRoles.DemoUser],
  AnyReader: [CommonRoles.Reader],
};

export const hasPermission = (
  value: Permission,
  system: { permissions?: Permission[] } | undefined
) => {
  if (!system?.permissions) {
    return false;
  }

  return system.permissions.includes(value);
};
