import { Assessment, SystemFilter } from 'models';

const filterToQuery = (filter: SystemFilter) => {
  return `?pageNumber=${filter.paginator.page}&pageSize=${filter.paginator.pageSize}&orderBy.propertyName=${filter.paginator.orderBy}&orderBy.direction=${filter.paginator.orderDirection}`;
};

export const filterToMySystemsQuery = (filter: SystemFilter) => {
  return `${filterToQuery(filter)}${
    filter.tagIds && filter.tagIds.length > 0
      ? filter.tagIds.map((tagId) => `&filter.tags=${tagId}`).join('')
      : ''
  }${filter.searchTerm ? `&filter.searchTerm=${filter.searchTerm}` : ''}`;
};

export const systemsFilterToQuery = (filter: SystemFilter): string =>
  `${filterToQuery(filter)}${
    filter.instance?.id ? `&filter.versionInstanceId=${filter.instance.id}` : ''
  }${
    filter.assessments && filter.assessments.length > 0
      ? filter.assessments
          .map(
            (assessment) =>
              `&filter.assessment=${
                assessment === Assessment.NotSet ? '' : assessment
              }`
          )
          .join('')
      : ''
  }${
    filter.tagIds && filter.tagIds.length > 0
      ? filter.tagIds.map((tagId) => `&filter.tags=${tagId}`).join('')
      : ''
  }${filter.searchTerm ? `&filter.searchTerm=${filter.searchTerm}` : ''}${
    filter.email ? `&filter.email=${filter.email}` : ''
  }${
    filter.entityIds && filter.entityIds.length > 0
      ? filter.entityIds
          .map((entityId) => `&filter.entityIds=${entityId}`)
          .join('')
      : ''
  }`;
