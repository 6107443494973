import {
  messageActions,
  selectIsAddingRecipientsShown,
  selectRecipientsToDelete,
} from '@dimatech/features-core/lib/api/message/messageSlice';
import { useLazyGetUsersQuery } from '@dimatech/features-core/lib/api/user/userApi';
import {
  selectSearchFilter,
  userActions,
} from '@dimatech/features-core/lib/api/user/userSlice';
import { MessageEdit } from '@dimatech/features-core/lib/features/administrate-messages/components/MessageEdit';
import { RecipientsAdd } from '@dimatech/features-core/lib/features/administrate-messages/components/RecipientsAdd';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Paginator,
  SelectableAdmin,
  SortDirection,
} from '@dimatech/shared/lib/models';
import { useLazyGetRespondentsQuery } from 'api/system/systemManagerApi';
import {
  CommonRoles,
  PaginatedResult,
  Recipient,
  SearchAdministrateUsersRole,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const Message = ({
  searchableRoles,
  isEntityFilterShown = true,
}: {
  searchableRoles: (SearchAdministrateUsersRole | CommonRoles)[];
  isEntityFilterShown?: boolean;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isAddingRecipientsShown = useSelector(selectIsAddingRecipientsShown);
  const recipientsToDelete = useSelector(selectRecipientsToDelete);
  const filter = useSelector(selectSearchFilter);
  const [recipients, setRecipients] = useState<Recipient[]>([]);

  const navigate = useNavigate();
  const [customerId] = useState(accessToken.customerId);

  const [getUsers, { data, isLoading, isFetching }] = useLazyGetUsersQuery();
  const [
    getRespondents,
    { data: respondents, isLoading: isLoadingRespondents },
  ] = useLazyGetRespondentsQuery();

  useEffect(() => {
    dispatch(userActions.searchIsLoading(isLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    dispatch(userActions.searchIsLoading(isLoadingRespondents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRespondents]);

  useEffect(() => {
    dispatch(userActions.searchIsFetching(isFetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    dispatch(userActions.searchResult(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!respondents) return;
    const searchResult = {
      totalRecords: respondents?.length || 0,
      records: respondents?.map((respondent) => ({
        email: respondent.email,
        userId: respondent.id,
        isSelected: false,
        uid: respondent.id,
        lastLoginDate: respondent.lastLoginDate,
      })),
    } as PaginatedResult<SelectableAdmin>;
    dispatch(userActions.searchResult(searchResult));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respondents]);

  useEffect(() => {
    if (customerId !== accessToken.customerId) {
      navigate(`/administrate/messages`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 10000,
    orderBy: 'email',
    orderDirection: SortDirection.Asc,
  };
  const handleSearch = (paginator: Paginator = initialPaginator) => {
    if (!accessToken.customerId) {
      return;
    }

    if (filter?.role === CommonRoles.DiosRespondent) {
      getRespondents(
        {
          filter: {
            entityIds: [],
            email: filter?.email,
          },
          _customerId: accessToken.customerId,
        },
        true
      );
    } else {
      getUsers(
        {
          filter: { paginator, ...filter },
          _customerId: accessToken.customerId,
        },
        true
      );
    }
  };

  const handleAddRecipients = (recipientsToAdd: Recipient[]) => {
    dispatch(messageActions.setHasChanges(true));
    dispatch(messageActions.setRecipientsToAdd(recipientsToAdd));

    dispatch(
      messageActions.setRecipientsToDelete(
        recipientsToDelete.filter(
          (r) => !recipientsToAdd.some((n) => n.userId === r.userId)
        )
      )
    );
  };

  const addRecipients = (users: Recipient[]) => {
    const allRecipients = recipients.concat(
      users.filter((user) => !recipients.some((r) => r.userId === user.userId))
    );

    setRecipients(allRecipients);
    handleAddRecipients(users);

    dispatch(messageActions.setIsAddingRecipientsShown(false));

    dispatch(userActions.selectedUsers());
  };
  return (
    <>
      <MessageEdit recipients={recipients} setRecipients={setRecipients} />
      {isAddingRecipientsShown && (
        <RecipientsAdd
          handleAddRecipients={addRecipients}
          searchableRoles={searchableRoles}
          isEntityFilterShown={isEntityFilterShown}
          handleSearch={handleSearch}
          initialPaginator={initialPaginator}
        />
      )}
    </>
  );
};

Message.displayName = 'Message';
