import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  ComboBoxList,
  ComboBoxListItem,
  Input,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGlobalSystemQuery } from 'api/system/systemApi';
import { UseComboboxStateChange, useCombobox } from 'downshift';
import { GlobalSystemSearch } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { useDebounce } from 'use-debounce';

export const SearchGlobalSystem = withTheme(
  ({
    theme,
    setGlobalSystem,
    setSearchValue,
    searchValue,
  }: {
    theme: Theme;
    setGlobalSystem: (globalSystem?: GlobalSystemSearch) => void;
    setSearchValue?: (value?: string) => void;
    searchValue: string;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);
    const [isValid, setIsValid] = useState(true);

    const [debouncedSearch] = useDebounce(searchValue, 300);

    const { data: systems, isFetching } = useGetGlobalSystemQuery(
      accessToken.customerId && debouncedSearch
        ? {
            _customerId: accessToken.customerId,
            searchTerm: debouncedSearch,
          }
        : skipToken
    );

    const {
      isOpen,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
    } = useCombobox({
      inputValue: searchValue,
      items: systems ?? [],
      itemToString: (system: GlobalSystemSearch | null) => system?.name ?? '',
      onInputValueChange: (
        changes: UseComboboxStateChange<GlobalSystemSearch>
      ) => {
        const value = changes.inputValue || '';
        const valid = !!value.trim();

        setIsValid(valid);
        setSearchValue && setSearchValue(value);
        setGlobalSystem(
          changes.selectedItem ?? (valid ? { name: value } : undefined)
        );
      },
    });

    return (
      <div>
        <div {...getComboboxProps()}>
          <Input
            invalid={!isValid}
            {...getInputProps()}
            placeholder={t('Search.SystemPlaceholder')}
          />
        </div>

        <ComboBoxList {...getMenuProps()}>
          {isOpen && isFetching && (
            <ComboBoxListItem>
              <LoaderSmall />
            </ComboBoxListItem>
          )}
          {isOpen &&
            systems?.map((system, index) => (
              <ComboBoxListItem
                $highlighted={highlightedIndex === index}
                key={`${system.id}${index}`}
                {...getItemProps({ item: system, index })}
              >
                {system.name}
              </ComboBoxListItem>
            ))}
        </ComboBoxList>
      </div>
    );
  }
);

SearchGlobalSystem.displayName = 'SearchGlobalSystem';
