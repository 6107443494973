import { Progress } from '@dimatech/shared/lib/components/Progress';
import { Sorter } from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { Respondent, System, Tag as TagModel } from 'models';
import { useTranslation } from 'react-i18next';
import { BsServer } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { withTheme } from 'styled-components';

export const SystemsList = withTheme(
  ({
    systems,
    sorter,
    showRespondents,
    theme,
  }: {
    systems: System[];
    sorter: Sorter<System>;
    showRespondents: boolean;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const handleSelect = (e: React.SyntheticEvent, system: System) => {
      e.stopPropagation();

      navigate(`/system/${system.id}`, {
        state: { from: location.pathname },
      });
    };

    return (
      <TableResponsiveContainer>
        <Table>
          <thead>
            <tr>
              <Th style={{ width: 35 }} />
              <Th style={{ minWidth: 150 }} sortKey="name" sorter={sorter}>
                {t('Systems.Name')}
              </Th>
              <Th sortKey="entityPath" sorter={sorter}>
                {t('Systems.Organisation')}
              </Th>
              {showRespondents && (
                <>
                  <Th>{t('Systems.ItRespondents')}</Th>
                  <Th>{t('Systems.BusinessRespondents')}</Th>
                </>
              )}
              <Th>{t('Systems.Tags')}</Th>
              <ThCentered
                sortKey="answeredIt"
                sorter={sorter}
                style={{ width: 150 }}
              >
                {t('Systems.ItStatus', {
                  year: new Date().getFullYear(),
                })}
              </ThCentered>
              <ThCentered
                sortKey="answeredBusiness"
                sorter={sorter}
                style={{ width: 190 }}
              >
                {t('Systems.BusinessStatus', {
                  year: new Date().getFullYear(),
                })}
              </ThCentered>
            </tr>
          </thead>

          <tbody>
            {systems.map((system) => (
              <Tr
                isHoverable={true}
                key={system.id}
                onSelect={(e) => handleSelect(e, system)}
              >
                <Td>
                  <BsServer />
                </Td>
                <Td className="b">{system.name}</Td>
                <Td>{system.entityPath}</Td>

                {showRespondents && (
                  <>
                    <Td>
                      {[...(system.itRespondents ?? [])]
                        ?.sort((a, b) =>
                          (a.email as string) > (b.email as string) ? 1 : -1
                        )
                        .map((r: Respondent) => (
                          <div key={r.email}>{r.email}</div>
                        ))}
                    </Td>
                    <Td>
                      {[...(system.businessRespondents ?? [])]
                        ?.sort((a, b) =>
                          (a.email as string) > (b.email as string) ? 1 : -1
                        )
                        .map((r: Respondent) => (
                          <div key={r.email}>{r.email}</div>
                        ))}
                    </Td>
                  </>
                )}

                <Td>
                  {[...(system.tags ?? [])]
                    ?.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                    .map((tag: TagModel) => tag.displayName)
                    .join(', ')}
                </Td>

                <TdCentered>
                  {system.data?.it && (
                    <Progress
                      formatter={(value) => formatAsPercent(value, '0', 0)}
                      value={(system.answeredIt ?? 0) * 100}
                      singleValue={true}
                      color={theme.colors.success}
                    />
                  )}
                </TdCentered>

                <TdCentered>
                  {system.data?.business && (
                    <Progress
                      formatter={(value) => formatAsPercent(value, '0', 0)}
                      value={(system.answeredBusiness ?? 0) * 100}
                      singleValue={true}
                      color={theme.colors.success}
                    />
                  )}
                </TdCentered>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableResponsiveContainer>
    );
  }
);

SystemsList.displayName = 'SystemsList';
