import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { FileIcon } from '@dimatech/shared/lib/components/FileIcon';
import {
  Button,
  ButtonFooter,
  ButtonSecondary,
  Buttons,
  Input,
  Label,
} from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Theme } from '@dimatech/shared/lib/themes';
import { usePreviewSystemImportMutation } from 'api/system/systemApi';
import { config } from 'config';
import { useAppSelector } from 'hooks';
import { HttpStatus, ImportSystem } from 'models';
import { useContext, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';
import styled from 'styled-components';
import { isAdminReadOnly } from 'utils';
import { SystemsImportList } from './SystemsImportList';

export const SystemsImport = ({
  isSelectingFile,
  setIsSelectingFile,
  isImporting,
  setIsImporting,
}: {
  isSelectingFile: boolean;
  setIsSelectingFile: (isImporting: boolean) => void;
  isImporting: boolean;
  setIsImporting: (showingImportList: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedEntity = useAppSelector(selectSelectedEntity);

  const fileReference = useRef<HTMLInputElement>(null);

  const [previewSystemImport, { isLoading: isPosting, error }] =
    usePreviewSystemImportMutation();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [validationError, setValidationError] = useState('');
  const [dataImportSystems, setDataImportSystems] = useState<ImportSystem[]>(
    []
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError('');

    const file = event.currentTarget.files?.[0];

    if (file) {
      const sizeInKb = file.size / 1024;

      if (sizeInKb > config.ui.fileUpload.maxSizeInKb) {
        setValidationError(
          t('Administrate.System.Import.FileToBig', {
            maxSizeInMb: Math.ceil(config.ui.fileUpload.maxSizeInKb / 1024),
          })
        );
        return;
      }
    }

    setFile(file);
  };

  const handleCancel = () => {
    handleSelectNew();
    setIsSelectingFile(false);
  };

  const handleSelectNew = () => {
    setFile(undefined);

    if (fileReference?.current) {
      fileReference.current.value = '';
    }
  };

  const handleFileUpload = async (file: File | undefined) => {
    const data = new FormData();

    if (isReadOnly || file === undefined) {
      return;
    }

    data.append('file', file);
    if (selectedEntity) {
      const dataImport = await previewSystemImport({
        data,
        entityId: selectedEntity.id,
      }).unwrap();
      setDataImportSystems(dataImport);
    }

    setFile(undefined);
    setIsSelectingFile(false);
    setIsImporting(true);
  };

  if (isPosting) {
    return <LoaderOverlay />;
  }

  return (
    <>
      {isImporting && (
        <SystemsImportList
          dataImportSystems={dataImportSystems}
          setShowingImportList={setIsImporting}
        />
      )}

      {isSelectingFile && (
        <Modal
          title={t('Administrate.System.Import.Title')}
          handleKeyEsc={() => setIsSelectingFile(false)}
        >
          <Style>
            <div>
              <Trans i18nKey="Administrate.System.Import.Text" />
            </div>

            {validationError && (
              <AlertWarning style={{ marginTop: 15 }}>
                {validationError}
              </AlertWarning>
            )}

            <AlertErrors
              error={error}
              includeErrorStatuses={[HttpStatus.BadRequest]}
              translationPath="Administrate.System.Import.ValidationError"
            />

            <div>
              {!file && (
                <Upload htmlFor="file">
                  <BsUpload />
                  <span>{t('Administrate.System.Import.SelectFile')}</span>
                </Upload>
              )}

              <Input
                type="file"
                id="file"
                ref={fileReference}
                onChange={(e) => handleChange(e)}
              />

              {file && (
                <div style={{ padding: '20px 0 10px 0' }}>
                  <FileIcon fileName={file.name as string} />

                  <span style={{ paddingLeft: 5 }}>{file.name}</span>
                </div>
              )}

              <ButtonFooter>
                <Buttons style={{ justifyContent: 'flex-start' }}>
                  <ButtonSecondary
                    type="button"
                    onClick={handleSelectNew}
                    disabled={isReadOnly || isPosting || !file}
                  >
                    {t('Administrate.System.Import.SelectNewFile')}
                  </ButtonSecondary>
                </Buttons>

                <Buttons>
                  <ButtonSecondary type="button" onClick={handleCancel}>
                    {t('Common.Form.Cancel')}
                  </ButtonSecondary>

                  <Button
                    onClick={() => handleFileUpload(file)}
                    type="button"
                    disabled={isReadOnly || isPosting || !file}
                  >
                    {t('Administrate.System.Import.Upload')}
                  </Button>
                </Buttons>
              </ButtonFooter>
            </div>
          </Style>
        </Modal>
      )}
    </>
  );
};

SystemsImport.displayName = 'SystemsImport';

const Style = styled.div`
  ul {
    margin-left: 20px;
  }

  > div:last-of-type {
    padding: 0 20px 10px 0;

    input[type='file'] {
      display: none;
    }
  }
`;

const Upload = styled(Label)`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: baseline;
  vertical-align: middle;

  margin-top: 20px;
  margin-bottom: 10px;

  border-bottom: 1px solid
    ${({ theme }: { theme: Theme }) => theme.colors.secondary};

  span {
    padding-left: 10px;
  }

  svg {
    margin-bottom: 0;
  }
`;
