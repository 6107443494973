import { CacheTags, diosApi } from 'api/diosApi';
import { Data } from 'models';
import { nanoid } from 'nanoid';

/* eslint-disable max-lines-per-function */

const systemDataApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get system data
     */
    getSystemData: build.query<
      Data,
      {
        customerSystemId: string;
        versionInstanceId: string;
        _customerId: string;
        _userId: string;
      }
    >({
      query: ({ customerSystemId, versionInstanceId, _customerId, _userId }) =>
        `systems/${customerSystemId}/${versionInstanceId}/data`,
      transformResponse: (result: Data) => {
        return {
          ...result,
          tags: result.tags?.sort((a, b) =>
            (a.displayName as string) > (b.displayName as string) ? 1 : -1
          ),
          relations: result.relations?.map((relation) => ({
            ...relation,
            uid: nanoid(6),
          })),
          uid: nanoid(6),
        };
      },
      providesTags: [CacheTags.SystemData],
    }),

    /**
     *  Update customer system data
     */
    updateSystemData: build.mutation({
      query: ({
        data,
        customerSystemId,
      }: {
        data: Data;
        customerSystemId: string;
      }) => ({
        url: `systems/${customerSystemId}/data`,
        method: 'POST',
        body: {
          business: data.business,
          it: data.it,
        },
      }),
      // NOTE: invalidatesTags is called from System view so we can control reloading of data when relations is saved
    }),
  }),
});

export const { useUpdateSystemDataMutation, useGetSystemDataQuery } =
  systemDataApi;
