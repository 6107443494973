import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { System } from 'models';
import { useTranslation } from 'react-i18next';
import { BsCheckAll, BsX } from 'react-icons/bs';

export const SystemsActionSelectAll = ({
  systems,
  handleSelectAllSystems,
}: {
  systems: System[];
  handleSelectAllSystems: (checked: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const noOfSelected = systems.filter((r) => r.isSelected).length;
  const noOfTotal = systems.length;

  const isAllChecked = noOfSelected === noOfTotal;

  const handleSelectAll = () => {
    const checked = !isAllChecked;
    handleSelectAllSystems(checked);
  };

  return (
    <LinkWithTooltip
      style={{ margin: '0 5px 0 0' }}
      handleClick={handleSelectAll}
      title={
        isAllChecked
          ? t(`Administrate.System.DeselectAll.Title`)
          : t(`Administrate.System.SelectAll.Title`)
      }
      tooltipTitle={
        isAllChecked
          ? t(`Administrate.System.DeselectAll.TooltipTitle`)
          : t(`Administrate.System.SelectAll.TooltipTitle`)
      }
      tooltip={
        isAllChecked
          ? t(`Administrate.System.DeselectAll.Tooltip`)
          : t(`Administrate.System.SelectAll.Tooltip`)
      }
      icon={isAllChecked ? <BsX /> : <BsCheckAll />}
    />
  );
};

SystemsActionSelectAll.displayName = 'SystemsActionSelectAll';
