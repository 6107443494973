import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import {
  ButtonLink,
  Checkbox,
  Input,
} from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  Td,
  TdRight,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { selectSelectedSystem, systemActions } from 'api/system/systemSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Paginator, System } from 'models';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { SystemListActions } from './SystemListActions';
import { SystemProperties } from './SystemProperties';
import { SystemsActionDeleteSelected } from './SystemsActionDeleteSelected';
import { SystemsActionSelectAll } from './SystemsActionSelectAll';

export const SystemsList = ({
  handleCreate,
  dataSystems,
  handleGetSystems,
  totalRecords,
  initialPaginator,
}: {
  handleCreate: () => void;
  dataSystems?: System[];
  handleGetSystems: (paginator: Paginator, searchTerm?: string) => void;
  totalRecords: number | undefined;
  initialPaginator: Paginator;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const searchedForName = (location?.state as Record<string, string>)
    ?.systemName as string;

  const selectedSystem = useAppSelector(selectSelectedSystem);
  const selectedEntity = useAppSelector(selectSelectedEntity);

  const [systems, setSystems] = useState<System[]>([]);
  const [systemName, setSystemName] = useState<string>(searchedForName || '');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearch] = useDebounce(searchTerm, 300);

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: (paginator) =>
      handleGetSystems(paginator, searchTerm),
  });

  const handleSelect = (system: System) => {
    dispatch(
      systemActions.selectedSystem(
        selectedSystem?.id === system.id ? undefined : system
      )
    );
  };

  const handleMark = (e: ChangeEvent<HTMLInputElement>, system: System) => {
    const newSystems = [...systems];
    const index = newSystems.findIndex((s) => s.id === system.id);

    newSystems[index] = {
      ...newSystems[index],
      isSelected: e.target.checked,
    };

    setSystems(newSystems);
  };

  const handleMarkAll = (checked: boolean) => {
    setSystems(
      systems.map((system) => ({
        ...system,
        isSelected: checked && systems.some((r) => r.id === system.id),
      }))
    );
  };

  useEffect(() => {
    setPage(1);
    if (debouncedSearch) {
      handleGetSystems(
        {
          ...paginator,
          page: 1,
        },
        debouncedSearch.toLowerCase()
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    setSearchTerm(systemName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemName]);

  useEffect(() => {
    if (dataSystems) {
      setSystems(dataSystems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSystems]);

  useEffect(() => {
    dispatch(systemActions.selectedSystem());

    if (selectedEntity && !debouncedSearch) {
      handleGetSystems(initialPaginator);
    }
    if (selectedEntity && debouncedSearch) {
      handleGetSystems(initialPaginator, debouncedSearch.toLowerCase());
      setSearchTerm('');
      setSystemName('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  return (
    <>
      {selectedEntity && (
        <Heading2 style={{ marginBottom: 10 }}>{selectedEntity.name}</Heading2>
      )}

      {selectedEntity && systems?.length === 0 && !debouncedSearch && (
        <>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.System.NoSystemsInEntity', {
              name: selectedEntity?.name,
            })}
          </div>
          <ButtonLink type="button" onClick={handleCreate}>
            {t('Administrate.System.NoSystemsAddFirst')}
          </ButtonLink>
        </>
      )}

      <div>
        {((systems && systems?.length > 0) || debouncedSearch) && (
          <SearchContainer>
            <Input
              value={searchTerm as string}
              placeholder={t('Common.UI.Search')}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
            <BsSearch />
          </SearchContainer>
        )}

        {!(systems && systems?.length > 0) && debouncedSearch && (
          <div>{t('Administrate.System.NoSystemsForSearch')}</div>
        )}
      </div>

      {systems && systems.length > 0 && (
        <div
          style={{
            marginBottom: 20,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <SystemsActionSelectAll
            systems={systems}
            handleSelectAllSystems={handleMarkAll}
          />
          <SystemsActionDeleteSelected systems={systems} />
        </div>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={totalRecords || 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
        style={{ marginBottom: 30 }}
      />

      {selectedEntity?.id && systems && systems?.length > 0 && (
        <Table style={{ width: '100%' }}>
          <thead>
            <tr>
              <Th />
              <Th sortKey="name" sorter={sorter}>
                {t('Systems.Name')}
              </Th>
              <Th />
            </tr>
          </thead>
          <tbody>
            {systems?.map((system, index) => (
              <Fragment key={system.id}>
                <Tr
                  isHoverable={true}
                  key={index}
                  isSelected={selectedSystem?.id === system.id}
                  onSelect={(e) => {
                    if (
                      ['INPUT', 'SELECT'].includes(
                        (e.target as HTMLElement).nodeName
                      )
                    ) {
                      return;
                    }

                    handleSelect(system);
                  }}
                >
                  <Td style={{ width: 25 }}>
                    <Checkbox
                      onChange={(e) => handleMark(e, system)}
                      id={index + `_${system.id}`}
                      name={system.id}
                      value={system.id}
                      checked={!!system.isSelected}
                    />
                  </Td>
                  <Td>{system.name}</Td>
                  <TdRight
                    style={{
                      verticalAlign: 'middle',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <SystemListActions system={system} />
                  </TdRight>
                </Tr>

                {selectedSystem?.id === system.id && (
                  <>
                    <Tr isHoverable={false}>
                      <Td
                        colSpan={4}
                        style={{
                          padding: '0 0 20px 0',
                        }}
                      >
                        <SystemProperties systems={systems} />
                      </Td>
                    </Tr>
                  </>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={totalRecords || 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
        style={{ marginTop: 30 }}
      />
    </>
  );
};

SystemsList.displayName = 'SystemsList';

const SearchContainer = styled.div`
  width: 300px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 20px;

  > input {
    padding-right: 20px;
  }

  > svg {
    margin-left: -20px;
    cursor: pointer;
  }
`;
