import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { System } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export const SystemActionNavigateToHistory = ({
  system,
}: {
  system: System;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const isHistoryEnabledFlagOn =
    useFlags()[flags.permanent.app.dios.isHistoryEnabled];

  const handleNavigateToHistory = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/system/${system.id}/history`, {
      state: { from: location.pathname },
    });
  };

  if (!isHistoryEnabledFlagOn) {
    return null;
  }

  return (
    <BadgeMini
      tooltip={t('Administrate.Navigate.OpenSystemHistory')}
      onClick={(e) => handleNavigateToHistory(e)}
    >
      <BsClockHistory />
    </BadgeMini>
  );
};

SystemActionNavigateToHistory.displayName = 'SystemActionNavigateToHistory';
