import {
  BusinessArea,
  BusinessAreaGovernment,
  BusinessAreaMunicipality,
  BusinessAreaRegion,
  BusinessAreaShared,
  CustomerType,
} from 'models';

export const getBusinessAreaKeyForCustomer = (
  customerType: CustomerType,
  businessArea: BusinessArea
): string => {
  let name = 'Shared';
  if (
    customerType === CustomerType.Municipality &&
    Object.keys(BusinessAreaMunicipality).includes(businessArea)
  ) {
    name = CustomerType.Municipality;
  } else if (
    customerType === CustomerType.Region &&
    Object.keys(BusinessAreaRegion).includes(businessArea)
  ) {
    name = CustomerType.Region;
  } else if (
    customerType === CustomerType.Government &&
    Object.keys(BusinessAreaGovernment).includes(businessArea)
  ) {
    name = CustomerType.Government;
  }
  return `System.Business.BusinessAreasSupported.Values.${name}.${businessArea}`;
};

export const getBusinessAreasForCustomer = (
  customerType: CustomerType
): {
  key: string;
  value: BusinessArea;
}[] => {
  const getBusinessAreas = (
    values: string[],
    name: string
  ): {
    key: string;
    value: BusinessArea;
  }[] => {
    return values.map((value) => ({
      value: value as BusinessArea,
      key: `System.Business.BusinessAreasSupported.Values.${name}.${value}`,
    }));
  };

  let businessAreas = getBusinessAreas(
    Object.keys(BusinessAreaShared),
    'Shared'
  );

  if (customerType === CustomerType.Municipality) {
    businessAreas = [
      ...getBusinessAreas(
        Object.keys(BusinessAreaMunicipality),
        'Municipality'
      ),
      ...businessAreas,
    ];
  } else if (customerType === CustomerType.Region) {
    businessAreas = [
      ...getBusinessAreas(Object.keys(BusinessAreaRegion), 'Region'),
      ...businessAreas,
    ];
  } else if (customerType === CustomerType.Government) {
    businessAreas = [
      ...getBusinessAreas(Object.keys(BusinessAreaGovernment), 'Government'),
      ...businessAreas,
    ];
  }

  return businessAreas;
};
