import { Color, Theme, ThemeType } from '@dimatech/shared/lib/themes';
import { light } from './light';

// Dark theme, only overrides necessary properties
export const dark: Theme = {
  ...light,
  name: 'Dios Dark',
  type: ThemeType.Dark,

  colors: {
    ...light.colors,

    background: Color.Grey10,
    onBackground: Color.White,

    surface: Color.Grey15,
    onSurface: Color.White,

    surfaceVariant: Color.Grey20,
    onSurfaceVariant: Color.White,

    border: Color.Grey20,

    chart: {
      ...light.colors.chart,

      gridStroke: Color.Grey20,
      axisStroke: Color.Grey60,
    },
  },
};
