import { SystemsMap as SystemsMapFeature } from 'features/systems/SystemsMap';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const SystemsMap = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('SystemsMap.Title')}>
      <SystemsMapFeature />
    </View>
  );
};

SystemsMap.displayName = 'SystemsMap';
