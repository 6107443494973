import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ApplicationContext } from '@dimatech/shared/lib/application';
import { Heading1, Heading4 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Theme } from '@dimatech/shared/lib/themes';
import { ViewHeader } from 'components/ViewHeader';
import { config } from 'config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { View } from 'views';

export const Help = (): JSX.Element => {
  const { t } = useTranslation();
  const application = useContext(ApplicationContext);
  const { accessToken } = useContext(AuthenticationContext);

  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicApiEnabled];

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  return (
    <View title={t('Help.Title')}>
      <ViewHeader>
        <Heading1>{t('Help.Title')}</Heading1>

        {config.version && (
          <Heading1>
            {' '}
            - {application.title} v{config.version}
          </Heading1>
        )}
      </ViewHeader>

      <Card width="100%" title={t('Help.Support')}>
        <CardBody>
          <Trans
            i18nKey="Help.Text"
            values={{
              supportUrl: config.support.url,
              supportEmail: config.support.email,
            }}
          >
            <a href={config.support.url} target="_blank" rel="noreferrer">
              {config.support.url}
            </a>
            <a href={`mailto: ${config.support.email}`}>
              {config.support.email}
            </a>
          </Trans>
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Messaging')}>
        <CardBody>
          <BsExclamationCircleFill
            className="size-xl"
            style={{ marginRight: 8 }}
          />
          {t('Help.Whitelisting')}

          <p>{t('Help.TestMessage')}</p>
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Roles.Title')}>
        <CardBody>
          <PermissionsStyle>
            <div>
              <Heading4>{t('UserRoles.CustomerAccountAdmin.Title')}</Heading4>
              <Trans i18nKey="UserRoles.CustomerAccountAdmin.Text" />
              <TransArray i18nKey="UserRoles.CustomerAccountAdmin.Help" />
            </div>

            <div>
              <Heading4>{t('UserRoles.CustomerAdmin.Title')}</Heading4>
              <Trans i18nKey="UserRoles.CustomerAdmin.Text" />
              <TransArray i18nKey="UserRoles.CustomerAdmin.Help" />
            </div>

            <div>
              <Heading4>{t('UserRoles.DiosRespondent.Title')}</Heading4>
              <Trans i18nKey="UserRoles.DiosRespondent.Text" />
              <TransArray i18nKey="UserRoles.DiosRespondent.Help" />
            </div>

            <div>
              <Heading4>{t('UserRoles.Reader.Title')}</Heading4>
              <Trans i18nKey="UserRoles.Reader.Text" />
              <TransArray i18nKey="UserRoles.Reader.Help" />
            </div>
          </PermissionsStyle>
        </CardBody>
      </Card>

      {isPublicApiEnabledFlagOn && isCustomerAccountAdmin && (
        <Card width="100%" title={t('Help.PublicApi.Title')}>
          <CardBody>
            <Style>
              <div>
                <Heading4>{t('Help.PublicApi.ApiKey.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.ApiKey.Text" />{' '}
                <Link to="/administrate/account">
                  {t('Common.UI.ClickHere')}
                </Link>
              </div>

              <div>
                <Heading4>{t('Help.PublicApi.Response.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.Response.Text" />
              </div>

              <div>
                <Heading4>{t('Help.PublicApi.Documentation.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.Documentation.Text" />{' '}
                <a
                  target="_blank"
                  href={`${config.apiUrl.product.current}swagger/index.html?urls.primaryName=Dimatech.Backend.Api.Dios.Public`}
                  rel="noreferrer"
                >
                  {t('Common.UI.ClickHere')}
                </a>
              </div>
            </Style>
          </CardBody>
        </Card>
      )}
    </View>
  );
};

Help.displayName = 'Help';

const Style = styled.div`
  > div {
    margin-bottom: 20px;
  }
`;

const PermissionsStyle = styled(Style)`
  > div {
    > h4 {
      color: ${({ theme }: { theme: Theme }) => theme.colors.primaryVariant};
      width: fit-content;
    }
  }
`;
