import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useGetVersionInstancesQuery } from 'api/instances/instancesApi';
import { getSystems } from 'api/report/reportSystemsSlice';
import { Report, Reports } from 'components/Reports';
import { useAppDispatch } from 'hooks';
import { Report as ReportModel, VersionInstance } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ReportList = (): JSX.Element => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetVersionInstancesQuery();

  return (
    <>
      <Reports>
        <Heading3 style={{ marginBottom: 10 }}>
          {t('GlobalAdministrate.Reports.AllCustomers.Title')}
        </Heading3>

        {isLoading && (
          <div>
            <LoaderSmall />
          </div>
        )}

        {!isLoading && !data?.length && (
          <span style={{ fontStyle: 'italic' }}>
            {t('GlobalAdministrate.Reports.AllCustomers.NoData')}
          </span>
        )}

        {data &&
          data.map((instance, index) => {
            return <ReportItem key={index} instance={instance} />;
          })}
      </Reports>
    </>
  );
};

ReportList.displayName = 'ReportList';

export const ReportItem = ({
  instance,
}: {
  instance: VersionInstance;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (report: ReportModel) => {
    setIsDownloading(true);

    dispatch(
      getSystems({
        allCustomers: true,
        instanceId: instance.id as string,
        fileName: report.fileName,
      })
    ).finally(() => setIsDownloading(false));
  };

  return (
    <Report
      report={{
        id: '',
        name: t('GlobalAdministrate.Reports.AllCustomers.Name', {
          instanceName: instance.name,
        }),
        fileName: t('GlobalAdministrate.Reports.AllCustomers.FileName', {
          period: instance.name,
          fileExtension: 'xlsx',
        }),
        contentType: '',
        description: t('GlobalAdministrate.Reports.AllCustomers.Description', {
          instanceName: instance.name,
        }),
      }}
      isDownloading={isDownloading}
      handleDownload={handleDownload}
    />
  );
};
