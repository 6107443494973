import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Label, MultiSelect } from '@dimatech/shared/lib/components/form';
import { DateTimePicker } from '@dimatech/shared/lib/components/form/DateTimePicker';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { useDeleteReminderMutation } from 'api/schedule/reminderApi';
import { addHours, isPast, parseISO } from 'date-fns';
import { validateReminders } from 'features/administrate-schedule/validation';
import produce from 'immer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MessageSchedule, Node, Reminder, Tag, Tag as TagModel } from 'models';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsCheck, BsTrash } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { nextBankDayAtHours } from 'utils';

export const ScheduleReminder = withTheme(
  ({
    schedule,
    reminder,
    reminders,
    setReminders,
    setIsValidReminder,
    label,
    theme,
    isReadOnly,
    tags,
  }: {
    schedule?: MessageSchedule;
    reminder: Reminder;
    reminders: Reminder[];
    setReminders: (reminders: Reminder[]) => void;
    setIsValidReminder: (isValid: boolean) => void;
    label: string;
    theme: Theme;
    isReadOnly: boolean;
    tags?: Node<Tag>[];
  }): JSX.Element => {
    const { t } = useTranslation();
    const [deleteReminder] = useDeleteReminderMutation();

    const hasOccurred = reminder.sendDate
      ? isPast(parseISO(reminder.sendDate))
      : false;
    const isDisabled = hasOccurred && !reminder.isDirty;

    const isScheduleEnableTagsForRemindersOn =
      useFlags()[flags.permanent.app.dios.scheduleEnableTagsForReminders];

    const handleDeleteReminder = (): void => {
      if (isReadOnly) {
        return;
      }

      if (!reminders) {
        return;
      }

      const index = reminders.findIndex((item) => item.uid === reminder.uid);

      setReminders(
        produce(reminders, (draftState) => {
          draftState.splice(index, 1);
        })
      );

      if (reminder.id) {
        deleteReminder({
          reminderId: reminder.id,
        });
      }
    };

    const handleChangeReminder = (date: Date | null) => {
      if (!reminders || !date) {
        return;
      }

      const index = reminders.findIndex((item) => item.uid === reminder.uid);

      const newReminders = produce(reminders, (draftState) => {
        draftState[index] = {
          ...reminder,
          sendDate: date.toISOString(),
          isDirty: true,
        };
      });

      setReminders(newReminders);

      const validatedReminders = validateReminders(
        newReminders,
        schedule?.startDate ? schedule.startDate : null
      );

      setIsValidReminder(
        !validatedReminders?.some((reminder) => !reminder.isValid)
      );
    };

    const handleAddTag = (reminder: Reminder, tags: Tag[]) => {
      if (!reminders) {
        return;
      }

      const index = reminders.findIndex((item) => item.uid === reminder.uid);

      const newReminders = produce(reminders, (draftState) => {
        draftState[index] = {
          ...reminder,
          isDirty: true,
          tags: [...tags],
        };
      });

      setReminders(newReminders);

      const validatedReminders = validateReminders(
        newReminders,
        schedule?.startDate ?? null
      );

      setIsValidReminder(
        !validatedReminders?.some((reminder) => !reminder.isValid)
      );
    };

    return (
      <div>
        <div>
          <Label htmlFor={reminder.uid}>{label}</Label>

          <DateTimePicker
            defaultDateOnFocus={nextBankDayAtHours(
              addHours(new Date(), 1).getHours()
            )}
            name={reminder.uid}
            date={reminder.sendDate}
            setDate={(date) => {
              handleChangeReminder(date);
            }}
            disabled={isDisabled}
            isValid={reminder.isValid !== false}
            min={new Date()}
            openToDate={
              new Date(reminder.sendDate ?? schedule?.startDate ?? new Date())
            }
          />

          <BsCheck
            style={{
              fontSize: 20,
              marginLeft: 15,
              color: theme.colors.success,
              visibility: hasOccurred ? 'visible' : 'hidden',
              display: !isReadOnly && !hasOccurred ? 'none' : 'initial',
            }}
          />

          {handleDeleteReminder && !hasOccurred && (
            <BadgeMini
              style={{ marginLeft: 10 }}
              disabled={isReadOnly}
              onClick={handleDeleteReminder}
            >
              <BsTrash />
            </BadgeMini>
          )}
        </div>

        {isScheduleEnableTagsForRemindersOn && tags && tags.length > 0 && (
          <div style={{ marginBottom: 10 }}>
            <Label>{t('Administrate.Schedule.Criteria.Tags')}</Label>

            {isDisabled && reminder?.tags && reminder.tags.length > 0 && (
              <Label>
                {[...(reminder?.tags ?? [])]
                  ?.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                  .map((tag: TagModel) => tag.displayName)
                  .join(', ')}
              </Label>
            )}

            {!isDisabled && (
              <MultiSelect
                placeholder={t('System.Tag.AnyTag')}
                placeholderAll={t('System.Tag.AnyTag')}
                options={tags}
                showSelectAll={false}
                setSelected={(selectedNodes) => {
                  handleAddTag(
                    reminder,
                    selectedNodes.map((node) => node.payload) ?? []
                  );
                }}
                disabled={hasOccurred && !reminder.isDirty}
                selected={tags.filter((node) =>
                  reminder?.tags?.some((tag) => tag.id === node.id)
                )}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

ScheduleReminder.displayName = 'ScheduleReminder';
