import { Color, ProductPrimaryColor, Theme } from '@dimatech/shared/lib/themes';
import { selectFilter } from 'api/diosSlice';
import {
  selectSystemMapFilter,
  selectSystemMapSettings,
} from 'api/system/systemMapSlice';
import { useAppSelector } from 'hooks';
import { Assessment, Criticality, LayoutDirection, System } from 'models';
import { ReactNode, useEffect, useState } from 'react';
import { BsArrowRightSquare } from 'react-icons/bs';
import { FaProjectDiagram } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';

// cspell:ignore reactflow

export const SystemMapNode = ({
  id,
  data,
}: {
  id: string;
  data: {
    label: string;
    icon?: ReactNode;
    backgroundColor?: string;
    color?: string;
    system: System;
  };
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const systemMapSettings = useAppSelector(selectSystemMapSettings);
  const systemMapFilter = useAppSelector(selectSystemMapFilter);
  const systemFilter = useAppSelector(selectFilter);

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isDimmed, setIsDimmed] = useState(false);

  const handleNavigate = (e: React.SyntheticEvent, system: System) => {
    e.stopPropagation();

    navigate(`/system/${system.id}`, {
      state: { from: location.pathname },
    });
  };

  useEffect(() => {
    if (!systemMapFilter.system) {
      setIsHighlighted(false);
      return;
    }

    setIsHighlighted(
      !!(systemMapFilter.system && id === systemMapFilter.system.id)
    );
  }, [systemMapFilter.system, id]);

  useEffect(() => {
    if (
      data.system.assessment &&
      !systemFilter.assessments?.includes(data.system?.assessment)
    ) {
      setIsDimmed(true);
      return;
    }

    if (
      !data.system.assessment &&
      !systemFilter.assessments?.includes(Assessment.NotSet)
    ) {
      setIsDimmed(true);
      return;
    }

    if (
      systemFilter.tagIds &&
      systemFilter.tagIds.length > 0 &&
      !systemFilter.tagIds.every((tagId) =>
        data.system.tags?.map((t) => t.id).includes(tagId)
      )
    ) {
      setIsDimmed(true);
      return;
    }

    if (
      data.system.entityId &&
      systemFilter.entityIds &&
      systemFilter.entityIds.length > 0 &&
      !systemFilter.entityIds.includes(data.system.entityId)
    ) {
      setIsDimmed(true);
      return;
    }

    if (
      systemMapFilter.relationTagIds &&
      systemMapFilter.relationTagIds.length > 0 &&
      !systemMapFilter.relationTagIds.some((tagId) =>
        data.system.relations?.some((r) =>
          r.tags?.map((t) => t.id).includes(tagId)
        )
      )
    ) {
      setIsDimmed(true);
      return;
    }

    if (
      systemFilter.criticality &&
      systemFilter.criticality.length > 0 &&
      !systemFilter.criticality.some((criticality) =>
        data.system.relations?.some(
          (r) =>
            (criticality === Criticality.NotSet &&
              !r.customerSystemRelationCriticality) ||
            r.customerSystemRelationCriticality === criticality
        )
      )
    ) {
      setIsDimmed(true);
      return;
    }

    setIsDimmed(false);
  }, [data.system, systemFilter, systemMapFilter.relationTagIds]);

  return (
    <>
      {/*
      NOTE: Removed due to performance issues when rendering large number of nodes, need to use a different approach
      <SystemMapNodeTooltip id={`tt-${data.system?.id}`} system={data.system} />
      */}

      <Node
        isHighlighted={isHighlighted}
        isDimmed={isDimmed}
        backgroundColor={data.backgroundColor}
        color={data.color}
        // data-tip
        // data-for={`tt-${data.system?.id}`}
      >
        <div>
          <Handle
            type="target"
            position={
              systemMapSettings.layoutDirection === LayoutDirection.TB
                ? Position.Top
                : Position.Left
            }
          />

          <NodeLabel>
            {data.icon && <>{data.icon}</>}
            <div>{data.label}</div>

            <div>
              <BsArrowRightSquare
                style={{ cursor: 'pointer' }}
                onClick={(e) => handleNavigate(e, data.system)}
              />

              {data.system.data?.it?.externalConnections &&
                data.system.data.it.externalConnections.length > 0 && (
                  <FaProjectDiagram />
                )}
            </div>
          </NodeLabel>

          <Handle
            type="source"
            position={
              systemMapSettings.layoutDirection === LayoutDirection.TB
                ? Position.Bottom
                : Position.Right
            }
          />
        </div>
      </Node>
    </>
  );
};

SystemMapNode.displayName = 'SystemMapNode';

const NodeLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 5px;

  div:first-of-type {
    max-width: 100px;
    overflow: hidden;
    flex-shrink: 0;
  }

  div:last-of-type {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  width: 120px;
`;

const Node = styled.div<{
  readonly backgroundColor?: string;
  readonly color?: string;
  readonly isHighlighted?: boolean;
  readonly isDimmed?: boolean;
}>`
  border: 1px solid
    ${(props) => props.backgroundColor ?? ProductPrimaryColor.Dios};
  background: ${(props) =>
    props.backgroundColor ?? props.theme.colors.background};
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.onBackground};

  border-radius: 5px;
  padding: 10px;

  ${(props) =>
    props.isDimmed &&
    !props.isHighlighted &&
    css`
      background: ${({ theme }: { theme: Theme }) => theme.colors.surface};
      color: ${() => Color.Grey40};
      border: 1px solid ${() => Color.Grey40};
    `}

  ${(props) =>
    props.isHighlighted &&
    css`
      border: 3px solid ${() => Color.BrightYellow};
    `}
`;
