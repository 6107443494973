import { CacheTags, diosApi } from 'api/diosApi';
import { Admin, SearchAdministrateUsers } from 'models';

const systemManagerApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get respondents
     */
    getRespondents: build.query<
      Admin[],
      {
        filter: SearchAdministrateUsers;
        _customerId?: string | undefined;
      }
    >({
      query: ({ filter, _customerId }) =>
        `systems/respondents?1=1${filter.entityIds
          ?.map((entity) => `&entityIds=${entity}`)
          .join('')}${filter.email ? `&email=${filter.email}` : ''}`,

      providesTags: [CacheTags.Respondent],
    }),

    /**
     * Add  respondents
     */
    addRespondents: build.mutation({
      query: ({
        customerSystemId,
        respondents,
      }: {
        customerSystemId: string;
        respondents: {
          email?: string;
          it: boolean;
        }[];
      }) => ({
        url: `systems/${customerSystemId}/respondents`,
        method: 'PUT',
        body: respondents,
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemsMy,
        CacheTags.Respondent,
        CacheTags.SystemHistory,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     * Delete respondents
     */
    deleteRespondents: build.mutation({
      query: ({
        customerSystemId,
        respondents,
      }: {
        customerSystemId: string;
        respondents: {
          email?: string;
          it: boolean;
        }[];
      }) => ({
        url: `systems/${customerSystemId}/respondents`,
        method: 'DELETE',
        body: respondents,
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemsMy,
        CacheTags.Respondent,
        CacheTags.SystemHistory,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     * Remove all system respondents for user
     */
    removeUserRespondents: build.mutation({
      query: ({ email }: { email: string }) => ({
        url: `systems/respondents?email=${email}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemsMy,
        CacheTags.Respondent,
        CacheTags.SystemHistory,
      ],
    }),
  }),
});

export const {
  useAddRespondentsMutation,
  useDeleteRespondentsMutation,
  useGetRespondentsQuery,
  useLazyGetRespondentsQuery,
  useRemoveUserRespondentsMutation,
} = systemManagerApi;
