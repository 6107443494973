import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { BadgeLarge } from '@dimatech/shared/lib/components/Badge';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/diosSlice';
import { useGetSystemDataQuery } from 'api/system/systemDataApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { SystemHistory as SystemHistoryComponent } from 'features/systems/components/SystemHistory';
import { useAppSelector } from 'hooks';
import { System as SystemModel } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsX } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

export const SystemHistory = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { systemId = '' } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useContext(AuthenticationContext);

  const systemFilter = useAppSelector(selectFilter);
  const [system, setSystem] = useState<SystemModel>();

  const { data, isLoading, isFetching } = useGetSystemDataQuery(
    accessToken.customerId &&
      accessToken.user?.id &&
      systemId &&
      systemFilter.isInstancesLoaded &&
      systemFilter.instance?.id
      ? {
          customerSystemId: systemId,
          versionInstanceId: systemFilter.instance.id,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleBack = () => {
    navigate(
      (location.state as { from: string })?.from ?? `/system/${systemId}`
    );
  };

  useEffect(() => {
    if (data) {
      setSystem({ ...data });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Style>
        <div>
          <BackButton handleBack={handleBack} />
        </div>

        <Heading1>{system?.name} </Heading1>

        <SelectExtendedFilters
          isAssessmentFilterShown={false}
          isOnlyPeriodsWithDataShown={true}
          isTagFilterShown={false}
          isEntityFilterShown={false}
        />

        {system && (
          <ViewHeaderActions style={{ marginBottom: 0 }}>
            <BadgeLarge
              tooltip={t('Common.Form.Cancel')}
              onClick={handleBack}
              $inverted={true}
            >
              <BsX />
            </BadgeLarge>
          </ViewHeaderActions>
        )}
      </Style>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {system && (
            <Card>
              <CardBody isLoading={isLoading || isFetching}>
                <SystemHistoryComponent systemId={systemId} />
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  );
};

SystemHistory.displayName = 'SystemHistory';

const Style = styled(ViewHeader)`
  > div:last-of-type {
    @media screen and (max-width: ${Breakpoints.small}) {
      width: 100%;
      justify-content: start;
    }
  }
`;
