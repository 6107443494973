import dagre from '@dagrejs/dagre';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { selectFilter } from 'api/diosSlice';
import {
  selectSystemMapFilter,
  selectSystemMapSettings,
  systemMapActions,
} from 'api/system/systemMapSlice';
import { SearchSystem } from 'components/SearchSystem';
import { useAppDispatch, useAppSelector } from 'hooks';
import { LayoutDirection, SystemWithRelations } from 'models';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArrowsFullscreen,
  BsDistributeHorizontal,
  BsDistributeVertical,
  BsFileImage,
  BsSearch,
  BsZoomIn,
  BsZoomOut,
} from 'react-icons/bs';
import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  Edge,
  Node,
  Panel,
  Position,
  getNodesBounds,
  getViewportForBounds,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useViewport,
} from 'reactflow';
import styled, { css, withTheme } from 'styled-components';
import {
  createEdges,
  createNodes,
  isAdminReadOnly,
  saveHtmlElementToPng,
} from 'utils';
import { SystemMapNode } from './SystemMapNode';

/* eslint-disable max-lines-per-function */
// cspell:ignore dagreGraph, dagre, graphlib, rankdir, reactflow, dagrejs

export const SystemsMap = withTheme(
  ({
    systems,
    theme,
  }: {
    systems: SystemWithRelations[];
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { accessToken } = useContext(AuthenticationContext);
    const isReadOnly = isAdminReadOnly(accessToken);

    const systemMapSettings = useAppSelector(selectSystemMapSettings);
    const systemMapFilter = useAppSelector(selectSystemMapFilter);
    const systemFilter = useAppSelector(selectFilter);

    const { zoomIn, zoomOut, fitView, setCenter, getNodes } = useReactFlow();
    const { zoom } = useViewport();
    const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

    const [previousNodes, setPreviousNodes] = useState('');
    const [previousInstanceId, setPreviousInstanceId] = useState(
      systemFilter.instance?.id
    );

    const initialNodes = useMemo(() => createNodes(systems), [systems]);
    const initialEdges = useMemo(
      () => createEdges(systems, theme),
      [systems, theme]
    );

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 150;
    const nodeHeight = 50;
    const maxZoom = 2;
    const minZoom = 0.1;

    const layoutElements = (
      nodes: Node[],
      edges: Edge[],
      direction = LayoutDirection.LR
    ) => {
      const isHorizontal = direction === LayoutDirection.LR;

      // Use dagre to calculate layout for the nodes
      dagreGraph.setGraph({ rankdir: direction });

      nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
      });

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      dagre.layout(dagreGraph);

      nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);

        node.targetPosition = isHorizontal ? Position.Left : Position.Top;
        node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
      });

      return { nodes, edges };
    };

    const focusNode = () => {
      if (!systemMapFilter.system) {
        return;
      }

      const focused = nodes.find(
        (node) => node.id === systemMapFilter.system?.id
      );

      if (focused) {
        const node = focused;

        const x = node.position.x + nodeWidth / 2;
        const y = node.position.y + nodeHeight / 2;
        const zoom = 1;

        setCenter(x, y, {
          zoom,
          duration: 400,
        });
      }
    };

    const nodeTypes = useMemo(
      () => ({
        systemMapNode: SystemMapNode,
      }),
      []
    );

    const handleTransform = useCallback(() => {
      fitView({ duration: 400 });
    }, [fitView]);

    const handleLayout = useCallback(
      (direction: LayoutDirection) => {
        dispatch(
          systemMapActions.setSystemMapSettings({
            ...systemMapSettings,
            layoutDirection: direction,
          })
        );

        const { nodes: nodesWithLayout, edges: edgesWithLayout } =
          layoutElements(nodes, edges, direction);

        setNodes([...nodesWithLayout]);
        setEdges([...edgesWithLayout]);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [nodes, edges]
    );

    const handleSaveAsPng = () => {
      if (isReadOnly) {
        return;
      }

      const width = 4096;
      const height = 3072;

      const nodesBounds = getNodesBounds(getNodes());
      const transform = getViewportForBounds(
        nodesBounds,
        width,
        height,
        minZoom,
        maxZoom
      );

      const options = {
        width,
        height,
        style: {
          width: width.toString(),
          height: height.toString(),
          transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
        },
      };

      saveHtmlElementToPng(
        document.querySelector('.react-flow__viewport'),
        `${t('SystemsMap.Title')}.png`,
        options
      );
    };

    useEffect(() => {
      if (systemMapFilter.system) {
        focusNode();
      } else {
        handleTransform();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemMapFilter.system]);

    useEffect(() => {
      // Only include nodes that have edges
      const nodesWithEdges = initialNodes.filter((node) =>
        initialEdges.some(
          (edge) => edge.source === node.id || edge.target === node.id
        )
      );

      if (nodesWithEdges.length === 0) {
        setNodes([]);
        setEdges([]);

        return;
      }

      // Calculate the layout
      const { nodes: nodesWithLayout, edges: edgesWithLayout } = layoutElements(
        nodesWithEdges,
        initialEdges,
        systemMapSettings.layoutDirection
      );

      setNodes(nodesWithLayout);
      setEdges(edgesWithLayout);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systems]);

    useEffect(() => {
      // Strip out irrelevant data to avoid unnecessary re-renders and
      // stringifies the nodes to compare with the previous nodes
      const current = JSON.stringify(
        nodes.map((node) => {
          const {
            position,
            selected,
            dragging,
            positionAbsolute,
            data,
            ...newNode
          } = node;
          return newNode;
        })
      );

      if (
        current !== previousNodes ||
        systemFilter.instance?.id !== previousInstanceId
      ) {
        // Only re-layout if the nodes have changed or if instance has changed
        if (systemMapFilter.system) {
          focusNode();
        } else {
          handleTransform();
        }

        setPreviousNodes(current);
        setPreviousInstanceId(systemFilter.instance?.id);
      }

      if (nodes.length === 0 || edges.length === 0) {
        dispatch(
          systemMapActions.setSystemMapFilter({
            ...systemMapFilter,
            system: undefined,
          })
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, edges]);

    if (nodes.length === 0 || edges.length === 0) {
      return <>{t('SystemsMap.NoSystems')}</>;
    }

    return (
      <SystemMapStyle
        isRelationLabelsShown={systemMapFilter.isRelationLabelsShown}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          connectionLineType={ConnectionLineType.SmoothStep}
          attributionPosition="bottom-right"
          fitView
          maxZoom={maxZoom}
          minZoom={minZoom}
          // https://reactflow.dev/examples/interaction/interaction-props
          nodesConnectable={false}
        >
          <Panel position="top-right" style={{ margin: 0 }}>
            <PanelStyle>
              <SearchStyle>
                <SearchSystem
                  setSystem={(system) =>
                    dispatch(
                      systemMapActions.setSystemMapFilter({
                        ...systemMapFilter,
                        system,
                      })
                    )
                  }
                  setSearchValue={(search) => {
                    if (search === '') {
                      dispatch(
                        systemMapActions.setSystemMapFilter({
                          ...systemMapFilter,
                          system: undefined,
                        })
                      );
                    }
                  }}
                />
                <BsSearch onClick={focusNode} />
              </SearchStyle>

              <LinkWithTooltip
                handleClick={() => handleLayout(LayoutDirection.TB)}
                isDisabled={
                  systemMapSettings.layoutDirection === LayoutDirection.TB
                }
                icon={<BsDistributeVertical />}
                disabledTimerInMs={1}
                isInverted={true}
                tooltip={t('SystemsMap.VerticalView')}
              />

              <LinkWithTooltip
                handleClick={() => handleLayout(LayoutDirection.LR)}
                isDisabled={
                  systemMapSettings.layoutDirection === LayoutDirection.LR
                }
                icon={<BsDistributeHorizontal />}
                disabledTimerInMs={1}
                isInverted={true}
                tooltip={t('SystemsMap.HorizontalView')}
              />

              <LinkWithTooltip
                handleClick={handleTransform}
                icon={<BsArrowsFullscreen />}
                disabledTimerInMs={200}
                isInverted={true}
                tooltip={t('SystemsMap.CenterView')}
              />

              <LinkWithTooltip
                handleClick={() => zoomIn({ duration: 200 })}
                isDisabled={zoom >= maxZoom}
                icon={<BsZoomIn />}
                disabledTimerInMs={1}
                isInverted={true}
                tooltip={t('SystemsMap.ZoomIn')}
              />

              <LinkWithTooltip
                handleClick={() => zoomOut({ duration: 200 })}
                isDisabled={zoom <= minZoom}
                icon={<BsZoomOut />}
                disabledTimerInMs={1}
                isInverted={true}
                tooltip={t('SystemsMap.ZoomOut')}
              />

              <LinkWithTooltip
                handleClick={handleSaveAsPng}
                icon={<BsFileImage />}
                disabledTimerInMs={1000}
                isInverted={true}
                tooltip={t('SystemsMap.SaveAsImage')}
                isDisabled={isReadOnly}
              />
            </PanelStyle>
          </Panel>

          {/* <Controls /> */}
          {/* <MiniMap /> */}

          <Background variant={BackgroundVariant.Dots} gap={20} size={1} />
        </ReactFlow>
      </SystemMapStyle>
    );
  }
);

SystemsMap.displayName = 'SystemsMap';

const SystemMapStyle = styled.div<{
  readonly isRelationLabelsShown: boolean;
}>`
  width: 100%;
  height: 80vh;

  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  // cspell:disable-next-line
  .react-flow__edge-textbg {
    fill: ${({ theme }: { theme: Theme }) => theme.colors.primary};
  }

  .react-flow__edge-text {
    fill: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xxs};
  }

  ${(props) =>
    !props.isRelationLabelsShown &&
    css`
      // cspell:disable-next-line
      .react-flow__edge-textwrapper {
        display: none;
      }
    `}
`;

const PanelStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.background}AA;

  padding: 5px 10px;
  border-radius: 5px;

  > button {
    max-height: 30px;
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.background};
  }

  > svg {
    cursor: pointer;
  }
`;

const SearchStyle = styled.div`
  min-width: 300px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  > div > div {
    min-width: 300px;
  }

  input {
    width: 100% !important;
    max-width: 300px;
    padding-right: 20px;
  }

  svg {
    margin-left: -20px;
    cursor: pointer;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    order: 2;
    margin-right: 0;
    width: 100%;

    input {
      width: 100%;
      max-width: unset;
    }
  }
`;
