import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { RespondentMessageStatus as MessageStatus } from 'models';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { withTheme } from 'styled-components';

export const RespondentMessageStatus = withTheme(
  ({
    theme,
    status,
    error,
  }: {
    theme: Theme;
    status: MessageStatus;
    error?: string | null;
  }): JSX.Element => {
    const { t } = useTranslation();
    const id = useId();

    const getColor = (status: MessageStatus): string => {
      switch (status) {
        case MessageStatus.Scheduled:
          return theme.colors.surface;

        case MessageStatus.Sent:
          return theme.colors.secondary;

        case MessageStatus.InProgress:
        case MessageStatus.Deffered:
          return theme.colors.warning;

        case MessageStatus.Delivered:
        case MessageStatus.Opened:
          return theme.colors.success;

        case MessageStatus.Blocked:
        case MessageStatus.Bounced:
        case MessageStatus.Dropped:
        case MessageStatus.Error:
          return theme.colors.error;

        default:
          return theme.colors.error;
      }
    };

    return (
      <>
        <div style={{ whiteSpace: 'break-spaces' }}>
          <TooltipContent
            id={id}
            title={t(
              `Administrate.System.Respondent.Messages.MessageStatus.${status}`
            )}
            text={
              <>
                {t(
                  `Administrate.System.Respondent.Messages.MessageStatusTooltip.${status}`
                )}
                {error && (
                  <div style={{ marginTop: 10 }}>
                    <strong>
                      {t(`Administrate.System.Respondent.Messages.Error`)}:
                    </strong>{' '}
                    {error}
                  </div>
                )}
              </>
            }
          />
        </div>

        <BsCircleFill
          data-tip
          data-for={id}
          style={{ marginRight: 5, fontSize: theme.fonts.size.s }}
          color={getColor(status)}
        />

        <span data-tip data-for={id}>
          {t(`Administrate.System.Respondent.Messages.MessageStatus.${status}`)}
        </span>
      </>
    );
  }
);

RespondentMessageStatus.displayName = 'RespondentMessageStatus';
