import { NationalResult as NationalResultFeature } from 'features/national-result';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const NationalResult = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('NationalResult.Title')}>
      <NationalResultFeature />
    </View>
  );
};

NationalResult.displayName = 'NationalResult';
