import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Loader } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Paginator } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency, formatAsNumber } from '@dimatech/shared/lib/utils';
import {
  BusinessArea,
  CustomerType,
  RiskOfLockInEffects,
  System,
  SystemPaginatedResult,
  YesNo,
} from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsServer } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { getBusinessAreaKeyForCustomer } from 'utils';

/* eslint-disable max-lines-per-function */

export const TableSystems = withTheme(
  ({
    theme,
    systems,
    handleGetSystems,
    initialPaginator,
  }: {
    theme: Theme;
    systems: SystemPaginatedResult<System> | undefined;
    handleGetSystems: (paginator: Paginator) => void;
    initialPaginator: Paginator;
  }): JSX.Element => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const { accessToken } = useContext(AuthenticationContext);

    const customerType =
      accessToken.user?.currentCustomer.customerType ?? CustomerType.Government;

    const { setPage, paginator, sorter } = useSortablePaginator({
      ...initialPaginator,
      handlePaginatorChange: handleGetSystems,
    });

    const handleSelect = (e: React.SyntheticEvent, system: System) => {
      e.stopPropagation();

      navigate(`/system/${system.id}`, {
        state: { from: location.pathname },
      });
    };

    if (!systems) {
      return <Loader>{t('Common.UI.Loading')}</Loader>;
    }

    return (
      <>
        <Pagination
          currentPage={paginator.page}
          totalCount={systems?.totalRecords || 0}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
          style={{ marginBottom: 20 }}
        />

        <TableResponsiveContainer>
          <TableStyle style={{ width: '100%' }} $striped={false}>
            <thead>
              <TrHeader>
                <ThCentered
                  colSpan={3}
                  style={{
                    backgroundColor: theme.colors.surface,
                    borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                  }}
                />
                <ThCentered
                  colSpan={18}
                  style={{
                    borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                  }}
                >
                  <Heading3>{t('System.It.Title')}</Heading3>
                </ThCentered>
                <ThCentered
                  colSpan={12}
                  style={{
                    borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                  }}
                >
                  <Heading3>{t('System.Business.Title')}</Heading3>
                </ThCentered>
              </TrHeader>
            </thead>
            <thead>
              <Tr>
                <Th style={{ width: 35 }} />
                <Th sortKey="name" sorter={sorter}>
                  {t('Systems.Name')}
                </Th>
                <Th
                  sortKey="assessment"
                  sorter={sorter}
                  style={{
                    borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                  }}
                >
                  {t('System.Shared.Assessment.Short')}
                </Th>
                <Th sortKey="data.it.assessment" sorter={sorter}>
                  {t('System.Shared.Assessment.Short')}
                </Th>
                <Th sortKey="data.it.noOfActiveUsers" sorter={sorter}>
                  {t('System.It.NoOfActiveUsers.Short')}
                </Th>
                <Th
                  sortKey="data.it.operationInternalOrExternal"
                  sorter={sorter}
                >
                  {t('System.It.OperationInternalOrExternal.Short')}
                </Th>
                <Th sortKey="data.it.loginsPerUserAndYear" sorter={sorter}>
                  {t('System.It.LoginsPerUserAndYear.Short')}
                </Th>
                <Th
                  sortKey="data.it.percentageVerySatisfiedUsers"
                  sorter={sorter}
                >
                  {t('System.It.PercentageVerySatisfiedUsers.Short')}
                </Th>
                <Th sortKey="data.it.noOfIncidentsLastYear" sorter={sorter}>
                  {t('System.It.NoOfIncidentsLastYear.Short')}
                </Th>
                <Th sortKey="data.it.noOfUpgradesLast3Years" sorter={sorter}>
                  {t('System.It.NoOfUpgradesLast3Years.Short')}
                </Th>
                <Th sortKey="data.it.noOfIntegrations" sorter={sorter}>
                  {t('System.It.NoOfIntegrations.Short')}
                </Th>
                <Th sortKey="data.it.hasSAD" sorter={sorter}>
                  {t('System.It.HasSAD.Short')}
                </Th>
                <Th sortKey="data.it.hasControlOverData" sorter={sorter}>
                  {t('System.It.HasControlOverData.Short')}
                </Th>
                <Th sortKey="data.it.hasOpenApiSupport" sorter={sorter}>
                  {t('System.It.HasOpenApiSupport.Short')}
                </Th>
                <Th sortKey="data.it.isPartOfDataShared" sorter={sorter}>
                  {t('System.It.IsPartOfDataShared.Short')}
                </Th>
                <Th sortKey="data.it.lifeCyclePhase" sorter={sorter}>
                  {t('System.It.LifeCyclePhase.Short')}
                </Th>
                <Th sortKey="data.it.yearOfDecommission" sorter={sorter}>
                  {t('System.It.YearOfDecommission.Short')}
                </Th>
                <Th sortKey="data.it.risk" sorter={sorter}>
                  {t('System.It.Risk.Short')}
                </Th>
                <Th sortKey="data.it.externalConnections" sorter={sorter}>
                  {t('System.It.ExternalConnections.Short')}
                </Th>
                <Th sortKey="data.it.description" sorter={sorter}>
                  {t('System.Shared.Description.Short')}
                </Th>
                <Th
                  sortKey="data.it.notes"
                  sorter={sorter}
                  style={{
                    borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                  }}
                >
                  {t('System.Shared.Notes.Short')}
                </Th>

                <Th sortKey="data.business.assessment" sorter={sorter}>
                  {t('System.Shared.Assessment.Short')}
                </Th>
                <Th sortKey="data.business.contractExpiresYear" sorter={sorter}>
                  {t('System.Business.ContractExpiresYear.Short')}
                </Th>
                <Th
                  sortKey="data.business.operationCostForYear"
                  sorter={sorter}
                >
                  {t('System.Business.OperationCostForYear.Short')}
                </Th>
                <Th
                  sortKey="data.business.developmentCostForYear"
                  sorter={sorter}
                >
                  {t('System.Business.DevelopmentCostForYear.Short')}
                </Th>
                <Th sortKey="data.business.plannedClosingYear" sorter={sorter}>
                  {t('System.Business.PlannedClosingYear.Short')}
                </Th>
                <Th sortKey="data.business.riskOfLockInEffects" sorter={sorter}>
                  {t('System.Business.RiskOfLockInEffects.Short')}
                </Th>
                <Th sortKey="data.business.criticality" sorter={sorter}>
                  {t('System.Business.Criticality.Short')}
                </Th>
                <Th
                  sortKey="data.business.functionalityOverlap"
                  sorter={sorter}
                >
                  {t('System.Business.FunctionalityOverlap.Short')}
                </Th>
                <Th
                  sortKey="data.business.businessAreasSupported"
                  sorter={sorter}
                >
                  {t('System.Business.BusinessAreasSupported.Short')}
                </Th>
                <Th sortKey="data.business.operationYear" sorter={sorter}>
                  {t('System.Business.OperationYear.Short')}
                </Th>
                <Th sortKey="data.business.description" sorter={sorter}>
                  {t('System.Shared.Description.Short')}
                </Th>
                <Th
                  sortKey="data.business.notes"
                  sorter={sorter}
                  style={{
                    borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                  }}
                >
                  {t('System.Shared.Notes.Short')}
                </Th>
              </Tr>
            </thead>
            <tbody>
              {systems?.records?.map((system) => (
                <Tr
                  key={system.id}
                  isHoverable={true}
                  onSelect={(e) => handleSelect(e, system)}
                >
                  <Td
                    style={{
                      borderRight: 'none',
                    }}
                  >
                    <BsServer />
                  </Td>
                  <Td
                    className="b"
                    style={{
                      paddingLeft: 0,
                    }}
                  >
                    {system.name}
                  </Td>
                  <Td
                    style={{
                      borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                    }}
                  >
                    {!!system.assessment &&
                      t(`System.Shared.Assessment.Values.${system.assessment}`)}
                  </Td>

                  <Td>
                    {!!system.data?.it?.assessment &&
                      t(
                        `System.Shared.Assessment.Values.${system.data?.it?.assessment}`
                      )}
                  </Td>
                  <Td>
                    {formatAsNumber(system.data?.it?.noOfActiveUsers, '')}
                  </Td>
                  <Td>
                    {!!system.data?.it?.operationInternalOrExternal &&
                      t(
                        `System.It.OperationInternalOrExternal.Values.${system.data?.it?.operationInternalOrExternal}`
                      )}
                  </Td>
                  <Td>
                    {formatAsNumber(system.data?.it?.loginsPerUserAndYear, '')}
                  </Td>
                  <Td>
                    {!!system.data?.it?.percentageVerySatisfiedUsers &&
                      t(
                        `System.It.PercentageVerySatisfiedUsers.Values.${system.data?.it?.percentageVerySatisfiedUsers}`
                      )}
                  </Td>
                  <Td>
                    {formatAsNumber(system.data?.it?.noOfIncidentsLastYear, '')}
                  </Td>
                  <Td>
                    {formatAsNumber(
                      system.data?.it?.noOfUpgradesLast3Years,
                      ''
                    )}
                  </Td>
                  <Td>
                    {formatAsNumber(system.data?.it?.noOfIntegrations, '')}
                  </Td>
                  <Td>
                    {system.data?.it?.hasSAD === true
                      ? t(`System.It.HasSAD.Values.${YesNo.Yes}`)
                      : system.data?.it?.hasSAD === false
                      ? t(`System.It.HasSAD.Values.${YesNo.No}`)
                      : null}
                  </Td>
                  <Td>
                    {system.data?.it?.hasControlOverData === true
                      ? t(`System.It.HasControlOverData.Values.${YesNo.Yes}`)
                      : system.data?.it?.hasControlOverData === false
                      ? t(`System.It.HasControlOverData.Values.${YesNo.No}`)
                      : null}
                  </Td>
                  <Td>
                    {system.data?.it?.hasOpenApiSupport
                      ? t(
                          `System.It.HasOpenApiSupport.Values.${system.data?.it?.hasOpenApiSupport}`
                        )
                      : null}
                  </Td>
                  <Td>
                    {system.data?.it?.isPartOfDataShared === true
                      ? t(`System.It.IsPartOfDataShared.Values.${YesNo.Yes}`)
                      : system.data?.it?.isPartOfDataShared === false
                      ? t(`System.It.IsPartOfDataShared.Values.${YesNo.No}`)
                      : null}
                  </Td>
                  <Td>
                    {system.data?.it?.lifeCyclePhase
                      ? t(
                          `System.It.LifeCyclePhase.Values.${system.data?.it?.lifeCyclePhase}`
                        )
                      : null}
                  </Td>
                  <Td>{system.data?.it?.yearOfDecommission}</Td>
                  <Td>
                    {!!system.data?.it?.risk &&
                      t(`System.It.Risk.Values.${system.data?.it?.risk}`)}
                  </Td>
                  <Td>{system.data?.it?.externalConnections}</Td>
                  <Td>
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: 150,
                      }}
                    >
                      {system.data?.it?.description}
                    </div>
                  </Td>
                  <Td
                    style={{
                      borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                    }}
                  >
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: 150,
                      }}
                    >
                      {system.data?.it?.notes}
                    </div>
                  </Td>

                  <Td>
                    {!!system.data?.business?.assessment &&
                      t(
                        `System.Shared.Assessment.Values.${system.data?.business?.assessment}`
                      )}
                  </Td>
                  <Td>
                    {system.data?.business?.contractExpiresYear === -1
                      ? t(`System.Business.ContractExpiresYear.Continuous`)
                      : system.data?.business?.contractExpiresYear}
                  </Td>
                  <Td>
                    {system.data?.business?.operationCostForYear !== null &&
                      formatAsCurrency(
                        system.data?.business?.operationCostForYear
                      )}
                  </Td>
                  <Td>
                    {system.data?.business?.developmentCostForYear !== null &&
                      formatAsCurrency(
                        system.data?.business?.developmentCostForYear
                      )}
                  </Td>
                  <Td>{system.data?.business?.plannedClosingYear}</Td>
                  <Td>
                    {system.data?.business?.riskOfLockInEffects?.length !==
                      0 && (
                      <ul style={{ listStyle: 'none', margin: 0 }}>
                        {system.data?.business?.riskOfLockInEffects?.map(
                          (risk: RiskOfLockInEffects, index: number) => {
                            return (
                              <li key={index}>
                                {t(
                                  `System.Business.RiskOfLockInEffects.Values.${risk}`
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </Td>
                  <Td>
                    {!!system.data?.business?.criticality &&
                      t(
                        `System.Business.Criticality.Values.${system.data?.business?.criticality}`
                      )}
                  </Td>
                  <Td>
                    {!!system.data?.business?.functionalityOverlap &&
                      t(
                        `System.Business.FunctionalityOverlap.Values.${system.data?.business?.functionalityOverlap}`
                      )}
                  </Td>
                  <Td>
                    {system.data?.business?.businessAreasSupported?.length !==
                      0 && (
                      <ul style={{ listStyle: 'none', margin: 0 }}>
                        {system.data?.business?.businessAreasSupported?.map(
                          (businessArea: BusinessArea, index: number) => {
                            return (
                              <li key={index}>
                                {t(
                                  getBusinessAreaKeyForCustomer(
                                    customerType as CustomerType,
                                    businessArea
                                  )
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </Td>
                  <Td>{system.data?.business?.operationYear}</Td>
                  <Td>
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: 150,
                      }}
                    >
                      {system.data?.business?.description}
                    </div>
                  </Td>
                  <Td
                    style={{
                      borderRight: `2px solid ${theme.colors.chart.gridStroke}`,
                    }}
                  >
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: 150,
                      }}
                    >
                      {system.data?.business?.notes}
                    </div>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </TableStyle>
        </TableResponsiveContainer>

        <Pagination
          currentPage={paginator.page}
          totalCount={systems?.totalRecords || 0}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
        />
      </>
    );
  }
);

TableSystems.displayName = 'TableSystems';

const TableStyle = styled(Table)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
  border-top: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.border};

  td {
    border-right: 1px solid
      ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
`;

const TrHeader = styled(Tr)`
  height: 50px;
  box-shadow: ${({ theme }: { theme: Theme }) => theme.boxShadows.default};
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};

  th {
    padding-bottom: 10px;
  }
`;
