import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { UserSystem } from '@dimatech/shared/lib/models';
import { useTranslation } from 'react-i18next';
import { BsArrowRightSquare, BsBriefcase, BsGear } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export const SystemsListForUser = ({
  list,
}: {
  list?: UserSystem[];
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          padding: 20,
          paddingTop: 0,
        }}
      >
        <div style={{ padding: 10 }}>
          {t(`Administrate.SystemManagers.SystemText`)}
        </div>
        <Table
          $striped={true}
          style={{ width: 'fit-content', minWidth: '50%', marginBottom: 30 }}
        >
          <tbody>
            {[...(list ?? [])]
              ?.sort((a, b) =>
                (a.customerSystemName as string) >
                (b.customerSystemName as string)
                  ? 1
                  : -1
              )
              ?.map((item, index) => (
                <Tr key={index}>
                  <Td style={{ width: 25 }}>
                    <BsBriefcase />
                  </Td>
                  <Td>{item.customerSystemName}</Td>
                  <TdRight>
                    <EditButtons
                      systemId={item.customerSystemId}
                      systemEntityId={item.customerSystemEntityId}
                      systemName={item.customerSystemName}
                    />
                  </TdRight>
                </Tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

SystemsListForUser.displayName = 'SystemsListForUser';

const EditButtons = ({
  systemId,
  systemEntityId,
  systemName,
}: {
  systemId: string;
  systemEntityId: string;
  systemName: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateAdministrateSystem = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate('/administrate/system', {
      state: { systemId, systemEntityId, systemName },
    });
  };

  const handleNavigateSystem = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`/system/${systemId}`, {
      state: { from: location.pathname },
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <LinkWithTooltip
        tooltip={t('Administrate.Navigate.OpenAdministrateSystem')}
        icon={<BsGear />}
        handleClick={(e) => handleNavigateAdministrateSystem(e)}
      />

      <LinkWithTooltip
        tooltip={t('Administrate.Navigate.OpenSystem')}
        icon={<BsArrowRightSquare />}
        style={{ marginLeft: 10 }}
        handleClick={(e) => handleNavigateSystem(e)}
      />
    </div>
  );
};
