import { appReducer } from '@dimatech/features-core/lib/api/appSlice';
import { customer } from '@dimatech/features-core/lib/api/customer/customerSlice';
import { entityReducer } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { featuresCoreApi } from '@dimatech/features-core/lib/api/featuresCoreApi';
import { messageTemplateReducer } from '@dimatech/features-core/lib/api/message-template/messageTemplateSlice';
import { messageReducer } from '@dimatech/features-core/lib/api/message/messageSlice';
import { tagReducer } from '@dimatech/features-core/lib/api/tag/tagSlice';
import { userExportReducer } from '@dimatech/features-core/lib/api/user/userExportSlice';
import { userReducer } from '@dimatech/features-core/lib/api/user/userSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { diosApi } from 'api/diosApi';
import { diosReducer } from 'api/diosSlice';
import { report } from 'api/report/reportSlice';
import { managerExportReducer } from 'api/system/managerExportSlice';
import { relationTagReducer } from 'api/system/relationTagSlice';
import { systemMapReducer } from 'api/system/systemMapSlice';
import { systemReducer } from 'api/system/systemSlice';

export const rootReducer = combineReducers({
  report,
  app: appReducer,
  dios: diosReducer,
  tag: tagReducer,
  relationTag: relationTagReducer,
  system: systemReducer,
  systemMap: systemMapReducer,
  messageTemplate: messageTemplateReducer,
  customer,
  userExport: userExportReducer,
  message: messageReducer,
  user: userReducer,
  entity: entityReducer,
  managerExport: managerExportReducer,
  [diosApi.reducerPath]: diosApi.reducer,
  [featuresCoreApi.reducerPath]: featuresCoreApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
