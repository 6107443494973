import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { diosActions } from 'api/diosSlice';
import { LayoutDirection, System } from 'models';
import { RootState } from 'store';

type SystemMapFilter = {
  search?: string;
  system?: System;
  isRelationLabelsShown: boolean;
  relationTagIds?: string[];
};

type SystemMapSettings = {
  layoutDirection: LayoutDirection;
};

type State = {
  settings: SystemMapSettings;
  filter: SystemMapFilter;
};

const initialState: State = {
  settings: {
    layoutDirection: LayoutDirection.LR,
  },
  filter: {
    isRelationLabelsShown: false,
  },
};

export const { reducer: systemMapReducer, actions: systemMapActions } =
  createSlice({
    name: 'system-map',
    initialState,
    reducers: {
      setSystemMapFilter: (state, action: PayloadAction<SystemMapFilter>) => {
        state.filter = action.payload;
      },
      setSystemMapSettings: (
        state,
        action: PayloadAction<SystemMapSettings>
      ) => {
        state.settings = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, () => initialState);
      build.addCase(diosActions.resetFilter, (state) => {
        state.filter = initialState.filter;
      });
    },
  });

export const selectSystemMapFilter = (state: RootState) =>
  state.systemMap.filter;

export const selectSystemMapSettings = (state: RootState) =>
  state.systemMap.settings;
