import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import {
  Button,
  Buttons,
  ButtonSecondary,
  Label,
  Select,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Admin } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { SearchSystem } from 'components/SearchSystem';
import { RespondentType, System } from 'models';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

export const RespondentAdd = withTheme(
  ({
    handleAdd,
    setIsAdding,
    theme,
  }: {
    handleAdd: (
      user: Admin,
      system: System,
      respondentType: RespondentType
    ) => void;
    setIsAdding: (isAdding: boolean) => void;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const [user, setUser] = useState<Admin | null>(null);
    const [system, setSystem] = useState<System>();
    const [respondentType, setRespondentType] = useState<RespondentType>();
    const [isValid, setIsValid] = useState(true);

    const handleSave = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setIsValid(true);

      if (!user?.email || !system || !respondentType) {
        setIsValid(false);
        return;
      }

      handleAdd({ ...user }, system, respondentType);
      setIsAdding(false);
    };

    const handleChangeRespondentType = (
      e: SyntheticEvent<HTMLSelectElement>
    ) => {
      e.stopPropagation();

      if (!e.currentTarget.value) {
        return;
      }

      setRespondentType(e.currentTarget.value as RespondentType);
    };

    return (
      <Modal
        title={t('Administrate.SystemManagers.Add.Title')}
        handleKeyEnter={handleSave}
        handleKeyEsc={() => setIsAdding(false)}
      >
        <div>{t('Administrate.SystemManagers.Add.Text')}</div>

        <Label htmlFor="respondent">
          {t('Administrate.SystemManagers.Add.Email')}
        </Label>
        <SearchUser user={user} setUser={setUser} />

        <SearchSystem
          setSystem={setSystem}
          style={{
            marginTop: 20,
            borderBottomColor:
              !isValid && !system ? theme.colors.error : theme.colors.secondary,
          }}
        />

        <Select
          onChange={(e) => handleChangeRespondentType(e)}
          invalid={!isValid && !respondentType}
          style={{ marginTop: 20 }}
        >
          <option>- {t('Search.SelectRespondentType')}</option>
          <option value={RespondentType.It}>
            {t('System.RespondentType.It')}
          </option>
          <option value={RespondentType.Business}>
            {t('System.RespondentType.Business')}
          </option>
          <option value={RespondentType.All}>
            {t('System.RespondentType.All')}
          </option>
        </Select>

        <Buttons>
          <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button type="button" onClick={handleSave}>
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </Modal>
    );
  }
);

RespondentAdd.displayName = 'RespondentAdd';
