import { Systems as SystemsFeature } from 'features/systems/Systems';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Systems = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Systems.Title')}>
      <SystemsFeature />
    </View>
  );
};

Systems.displayName = 'Systems';
