import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { Paginator, SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/diosSlice';
import { getSystems } from 'api/report/reportSystemsSlice';
import {
  useGetPortfolioValueQuery,
  useGetTurnoverQuery,
  useLazyGetSystemsAnalyticsQuery,
} from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel } from 'react-icons/bs';
import { isAdminReadOnly } from 'utils';
import { Statistics } from './components/Statistics';
import { StatisticsKpi } from './components/StatisticsKpi';
import { TableSystems } from './components/TableSystems';

export const Analytics = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const systemFilter = useAppSelector(selectFilter);
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 25,
    orderBy: 'name',
    orderDirection: SortDirection.Asc,
  };

  const [getSystemsAnalytics, { data: systems, isLoading, isFetching }] =
    useLazyGetSystemsAnalyticsQuery();

  const {
    data: turnover,
    isLoading: isLoadingByTurnover,
    isFetching: isFetchingTurnover,
  } = useGetTurnoverQuery(
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId
      ? {
          filter: systemFilter,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const {
    data: portfolioValue,
    isLoading: isLoadingPortfolioValue,
    isFetching: isFetchingPortfolioValue,
  } = useGetPortfolioValueQuery(
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId
      ? {
          filter: systemFilter,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handleGetSystems = (paginator: Paginator) => {
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId &&
      getSystemsAnalytics(
        {
          filter: {
            ...systemFilter,
            paginator,
          },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const hasData = systems?.records && systems.records.length > 0;

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    dispatch(
      getSystems({
        allCustomers: false,
        instanceId: systemFilter.instance?.id as string,
        fileName: `${t('Systems.Title')}.xlsx`,
      })
    );
  };

  useEffect(() => {
    if (systemFilter.isInstancesLoaded && systemFilter.instance) {
      handleGetSystems(initialPaginator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken.customerId,
    systemFilter.isInstancesLoaded,
    systemFilter.instance,
  ]);

  useEffect(() => {
    handleGetSystems(initialPaginator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemFilter]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Analytics.Title')}</Heading1>

        {hasData && (
          <ViewHeaderActions>
            <LinkWithTooltip
              title={t('Analytics.Export.Download.Download')}
              tooltipTitle={t('Analytics.Export.Download.TooltipTitle')}
              tooltip={t('Analytics.Export.Download.Tooltip')}
              icon={<BsFileExcel />}
              handleClick={handleExportDownload}
              isInverted={true}
              isDisabled={isReadOnly}
              style={{ marginLeft: 20 }}
            />
          </ViewHeaderActions>
        )}
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters isAssessmentFilterShown={true} />
        </CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {systemFilter.hasInstances && systems?.totalRecords !== 0 && (
            <>
              <Card>
                <CardBody
                  isLoading={
                    isLoadingByTurnover ||
                    isFetchingTurnover ||
                    isFetchingPortfolioValue ||
                    isLoadingPortfolioValue
                  }
                >
                  <StatisticsKpi
                    turnover={turnover}
                    portfolioValueData={portfolioValue}
                  />
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Statistics systems={systems} />
                </CardBody>
              </Card>
            </>
          )}

          {systems?.totalRecords === 0 && (
            <Card>
              <AlertInfo>{t('Analytics.NoData')}</AlertInfo>
            </Card>
          )}

          {!systemFilter.hasInstances && systemFilter.isInstancesLoaded && (
            <Card>
              <AlertWarning>{t('Overview.NoInstances')}</AlertWarning>
            </Card>
          )}

          {systemFilter.hasInstances && systems && systems.totalRecords !== 0 && (
            <Card>
              <CardBody isLoading={isFetching}>
                <TableSystems
                  systems={systems}
                  handleGetSystems={handleGetSystems}
                  initialPaginator={initialPaginator}
                />
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  );
};

Analytics.displayName = 'Analytics';
