import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import {
  Checkbox,
  InputNumber,
  Label,
  MultiSelect,
  Select,
  TextArea,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import {
  Assessment,
  Business,
  BusinessArea,
  Criticality,
  CustomerType,
  Data,
  FunctionalityOverlap,
  RiskOfLockInEffects,
} from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getBusinessAreasForCustomer } from 'utils';
import { SystemCopyFromPrevious } from './SystemCopyFromPrevious';

/* eslint-disable max-lines-per-function */

export const BusinessEdit = ({
  systemId,
  data,
  dataBusiness,
  setDataBusiness,
  canEdit,
}: {
  systemId: string;
  data: Data;
  dataBusiness: Business | undefined;
  setDataBusiness: (data: Business) => void;
  canEdit: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const customerType = accessToken.user?.currentCustomer.customerType;

  const risks = Object.keys(RiskOfLockInEffects).map((value) => ({
    id: value as RiskOfLockInEffects,
    name: t(`System.Business.RiskOfLockInEffects.Values.${value}`),
    parentId: null,
    payload: value as RiskOfLockInEffects,
  }));

  const businessAreas = getBusinessAreasForCustomer(
    customerType as CustomerType
  ).map((businessArea) => ({
    id: businessArea.value,
    name: t(businessArea.key),
    parentId: null,
    payload: businessArea.value as BusinessArea,
  }));

  return dataBusiness ? (
    <div>
      <Heading3>{t('System.Business.Title')}</Heading3>

      {canEdit &&
        data &&
        (!data.answeredBusiness || data.answeredBusiness === 0) && (
          <SystemCopyFromPrevious
            setDataBusiness={setDataBusiness}
            systemId={systemId}
          />
        )}

      <div style={{ flexDirection: 'column' }}>
        <div>
          <Label htmlFor="description">
            {t('System.Shared.Description.Title')}
          </Label>
        </div>

        <TextArea
          id="description"
          name="description"
          value={dataBusiness.description ?? ''}
          onChange={(e) => {
            setDataBusiness({
              ...dataBusiness,
              description: e.currentTarget.value,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="assessment">
            {t('System.Shared.Assessment.Title')}
          </Label>

          <HelpIcon
            title={t('System.Shared.Assessment.Short')}
            text={<Trans i18nKey={'System.Shared.Assessment.Help'} />}
          />
        </div>

        <Select
          name="assessment"
          id="assessment"
          value={dataBusiness.assessment ?? undefined}
          onChange={(e) => {
            setDataBusiness({
              ...dataBusiness,
              assessment: e.currentTarget.value
                ? (e.currentTarget.value as Assessment)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(Assessment)
            .filter((value) => value !== Assessment.NotSet)
            .map((value) => (
              <option key={value} value={value}>
                {t(`System.Shared.Assessment.Values.${value}`)}
              </option>
            ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="contractExpiresYear">
            {t('System.Business.ContractExpiresYear.Title')}
          </Label>
        </div>
        <div style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>
            <Label htmlFor="contractExpiresYear_continuous" disabled={!canEdit}>
              <Checkbox
                onChange={(e) =>
                  setDataBusiness(
                    dataBusiness
                      ? {
                          ...dataBusiness,
                          contractExpiresYear: e.currentTarget.checked
                            ? -1
                            : undefined,
                        }
                      : dataBusiness
                  )
                }
                id="contractExpiresYear_continuous"
                name="contractExpiresYear_continuous"
                value={-1}
                style={{ marginRight: 10 }}
                disabled={!canEdit}
                defaultChecked={dataBusiness?.contractExpiresYear === -1}
              />
              {t('System.Business.ContractExpiresYear.Continuous')}
            </Label>
          </div>

          <YearPicker
            name="contractExpiresYear"
            date={
              !dataBusiness?.contractExpiresYear ||
              dataBusiness?.contractExpiresYear === -1
                ? ''
                : dataBusiness?.contractExpiresYear?.toString()
            }
            setDate={(date: Date | null) =>
              setDataBusiness(
                dataBusiness
                  ? {
                      ...dataBusiness,
                      contractExpiresYear: date?.getFullYear(),
                    }
                  : dataBusiness
              )
            }
            disabled={!canEdit || dataBusiness?.contractExpiresYear === -1}
          />
        </div>
      </div>

      <div>
        <div>
          <Label htmlFor="plannedClosingYear">
            {t('System.Business.PlannedClosingYear.Title')}
          </Label>

          <HelpIcon
            title={t('System.Business.PlannedClosingYear.Title')}
            text={
              <Trans i18nKey="System.Business.PlannedClosingYear.Description" />
            }
          />
        </div>

        <YearPicker
          name="plannedClosingYear"
          date={dataBusiness?.plannedClosingYear?.toString() ?? ''}
          setDate={(date: Date | null) =>
            setDataBusiness(
              dataBusiness
                ? {
                    ...dataBusiness,
                    plannedClosingYear: date?.getFullYear(),
                  }
                : dataBusiness
            )
          }
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="operationCostForYear">
            {t('System.Business.OperationCostForYear.Title')}
          </Label>

          <HelpIcon
            title={t('System.Business.OperationCostForYear.Title')}
            text={
              <Trans
                i18nKey="System.Business.OperationCostForYear.Description"
                values={{
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                }}
              />
            }
          />
        </div>

        <InputNumber
          id="operationCostForYear"
          name="operationCostForYear"
          value={dataBusiness.operationCostForYear ?? ''}
          onValueChange={({ value }) => {
            setDataBusiness({
              ...dataBusiness,
              operationCostForYear: !isNaN(parseInt(value))
                ? +value
                : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="developmentCostForYear">
            {t('System.Business.DevelopmentCostForYear.Title')}
          </Label>

          <HelpIcon
            title={t('System.Business.DevelopmentCostForYear.Title')}
            text={
              <Trans
                i18nKey="System.Business.DevelopmentCostForYear.Description"
                values={{
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                }}
              />
            }
          />
        </div>

        <InputNumber
          id="developmentCostForYear"
          name="developmentCostForYear"
          value={dataBusiness?.developmentCostForYear ?? ''}
          onValueChange={({ value }) => {
            setDataBusiness({
              ...dataBusiness,
              developmentCostForYear: !isNaN(parseInt(value))
                ? +value
                : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="riskOfLockInEffects">
            {t('System.Business.RiskOfLockInEffects.Title')}
          </Label>
        </div>

        <div>
          <MultiSelect
            style={{ width: 250, marginLeft: 10 }}
            placeholder={`- ${t('Common.UI.Select')}`}
            placeholderAll={t('Common.UI.All')}
            options={risks}
            setSelected={(selectedNodes) => {
              const riskOfLockInEffects = selectedNodes.map(
                (node) => node.payload
              );

              if (riskOfLockInEffects.includes(RiskOfLockInEffects.No)) {
                setDataBusiness({
                  ...dataBusiness,
                  riskOfLockInEffects: [RiskOfLockInEffects.No],
                });

                return;
              }

              setDataBusiness({
                ...dataBusiness,
                riskOfLockInEffects,
              });
            }}
            selected={risks.filter((node) =>
              dataBusiness.riskOfLockInEffects?.includes(node.id)
            )}
            disabled={!canEdit}
            showSelectAll={false}
          />
        </div>
      </div>

      <div>
        <div>
          <Label htmlFor="criticality">
            {t('System.Business.Criticality.Title')}
          </Label>
        </div>

        <Select
          name="criticality"
          id="criticality"
          value={dataBusiness.criticality ?? undefined}
          onChange={(e) => {
            setDataBusiness({
              ...dataBusiness,
              criticality: e.currentTarget.value
                ? (e.currentTarget.value as Criticality)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(Criticality)
            .filter((value) => value !== Criticality.NotSet)
            .map((value) => (
              <option key={value} value={value}>
                {t(`System.Business.Criticality.Values.${value}`)}
              </option>
            ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="functionalityOverlap">
            {t('System.Business.FunctionalityOverlap.Title')}
          </Label>
        </div>

        <Select
          name="functionalityOverlap"
          id="functionalityOverlap"
          value={dataBusiness.functionalityOverlap ?? undefined}
          onChange={(e) => {
            setDataBusiness({
              ...dataBusiness,
              functionalityOverlap: e.currentTarget.value
                ? (e.currentTarget.value as FunctionalityOverlap)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(FunctionalityOverlap).map((value) => (
            <option key={value} value={value}>
              {t(`System.Business.FunctionalityOverlap.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="businessAreasSupported">
            {t('System.Business.BusinessAreasSupported.Title')}
          </Label>

          <HelpIcon
            title={t('System.Business.BusinessAreasSupported.Title')}
            text={
              <Trans i18nKey="System.Business.BusinessAreasSupported.Description" />
            }
          />
        </div>

        <div>
          <MultiSelect
            style={{ width: 250, marginLeft: 10 }}
            placeholder={`- ${t('Common.UI.Select')}`}
            placeholderAll={t('Common.UI.All')}
            options={businessAreas}
            setSelected={(selectedNodes) => {
              const businessAreasSupported = selectedNodes.map(
                (node) => node.payload
              );
              setDataBusiness({
                ...dataBusiness,
                businessAreasSupported,
              });
            }}
            selected={businessAreas.filter((node) =>
              dataBusiness.businessAreasSupported?.includes(node.id)
            )}
            disabled={!canEdit}
          />
        </div>
      </div>

      <div>
        <div>
          <Label htmlFor="operationYear">
            {t('System.Business.OperationYear.Title')}
          </Label>
        </div>

        <YearPicker
          name="operationYear"
          date={dataBusiness?.operationYear?.toString() ?? ''}
          setDate={(date: Date | null) =>
            setDataBusiness(
              dataBusiness
                ? {
                    ...dataBusiness,
                    operationYear: date?.getFullYear(),
                  }
                : dataBusiness
            )
          }
          disabled={!canEdit}
        />
      </div>

      <div style={{ flexDirection: 'column' }}>
        <div>
          <Label htmlFor="notes">{t('System.Shared.Notes.Title')}</Label>
        </div>

        <TextArea
          id="notes"
          name="notes"
          value={dataBusiness.notes ?? ''}
          onChange={(e) => {
            setDataBusiness({
              ...dataBusiness,
              notes: e.currentTarget.value,
            });
          }}
          disabled={!canEdit}
        />
      </div>
    </div>
  ) : null;
};

BusinessEdit.displayName = 'BusinessEdit';
