import { useDeleteEntityMutation } from '@dimatech/features-core/lib/api/entity/entityApi';
import { entityActions } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { selectFilter } from 'api/diosSlice';
import { useLazyGetSystemsQuery } from 'api/system/systemApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Entity } from 'models';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EntityPropertiesEditButtons = ({
  item,
  setSelectedItem,
  isReadOnly,
}: {
  item: Entity;
  setSelectedItem: React.Dispatch<React.SetStateAction<Entity | undefined>>;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const systemFilter = useAppSelector(selectFilter);
  const { accessToken } = useContext(AuthenticationContext);

  const [deleteEntity] = useDeleteEntityMutation();
  const [getSystems] = useLazyGetSystemsQuery();

  const [isDeleting, setIsDeleting] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [hasSystems, setHasSystems] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly || !item.id) {
      return;
    }

    deleteEntity(item.id);
    setSelectedItem(undefined);

    dispatch(entityActions.selectedEntity());

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setHasChildren(false);
    setHasSystems(false);

    if (!show) {
      setIsDeleting(false);
      return;
    }

    if (item.childCount && item.childCount > 0) {
      setHasChildren(true);
      setIsDeleting(true);
      return;
    }

    accessToken.customerId &&
      getSystems(
        {
          filter: {
            paginator: systemFilter.paginator,
            entityIds: [item?.id],
          },
          _customerId: accessToken.customerId,
        },
        true
      )
        .unwrap()
        .then((response) => {
          setHasSystems(response.totalRecords > 0);
          setIsDeleting(true);
        });
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Administrate.Entity.Delete.ConfirmTitle')}>
          {(hasChildren || hasSystems) && (
            <>
              <div>
                {hasChildren
                  ? t('Administrate.Entity.Delete.HasChildren')
                  : t('Administrate.Entity.Delete.HasSystems')}
              </div>
              <Buttons>
                <Button
                  type="button"
                  onClick={(e) => handleConfirmDelete(e, false)}
                >
                  {t('Common.UI.Ok')}
                </Button>
              </Buttons>
            </>
          )}

          {!hasChildren && !hasSystems && (
            <>
              <div>{t('Administrate.Entity.Delete.ConfirmText')}</div>
              <Buttons>
                <ButtonSecondary
                  type="button"
                  onClick={(e) => handleConfirmDelete(e, false)}
                >
                  {t('Common.Form.Cancel')}
                </ButtonSecondary>

                <Button
                  type="button"
                  onClick={(e) => handleDelete(e)}
                  disabled={isReadOnly}
                >
                  {t('Common.UI.Yes')}
                </Button>
              </Buttons>
            </>
          )}
        </Modal>
      )}

      <ButtonSecondary
        type="button"
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginLeft: '0' }}
      >
        {t('Common.Form.Delete')}
      </ButtonSecondary>
    </>
  );
};

EntityPropertiesEditButtons.displayName = 'EntityPropertiesEditButtons';
