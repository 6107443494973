import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useDeleteSystemMutation } from 'api/system/systemApi';
import { systemActions } from 'api/system/systemSlice';
import { useAppDispatch } from 'hooks';
import { System } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

export const SystemActionDelete = ({
  system,
  showListButton = false,
}: {
  system: System;
  showListButton?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [deleteSystem] = useDeleteSystemMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    if (system.id) {
      deleteSystem([system.id]);
    }

    setIsDeleting(false);
    dispatch(systemActions.selectedSystem());
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Administrate.System.Delete.ConfirmTitle')}>
          <div>{t('Administrate.System.Delete.ConfirmText')}</div>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {!showListButton && (
        <ButtonSecondary
          type="button"
          onClick={(e) => handleConfirmDelete(e, true)}
          style={{ marginLeft: '0' }}
        >
          {t('Common.Form.Delete')}
        </ButtonSecondary>
      )}

      {showListButton && (
        <BadgeMini
          tooltip={t('Common.Form.Delete')}
          onClick={(e) => handleConfirmDelete(e, true)}
        >
          <BsTrash />
        </BadgeMini>
      )}
    </>
  );
};

SystemActionDelete.displayName = 'SystemActionDelete';
