import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Reminder } from 'models';
import { nanoid } from 'nanoid';
import { Dispatch, SetStateAction } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';

export const ScheduleReminderAdd = ({
  reminders,
  setReminders,
}: {
  reminders: Reminder[];
  setReminders: Dispatch<SetStateAction<Reminder[]>>;
}): JSX.Element => {
  const { t } = useTranslation();

  const handleAddReminder = (): void => {
    const newReminder = {
      uid: nanoid(6),
      sendDate: null,
      criteria: {
        tags: [],
      },
      isDirty: true,
      isValid: true,
    };

    setReminders([...reminders, newReminder]);
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <div />

        <ButtonLink
          style={{
            whiteSpace: 'nowrap',
          }}
          onClick={handleAddReminder}
        >
          <BsPlus /> {t('Administrate.Schedule.AddReminder')}
        </ButtonLink>
      </div>
    </div>
  );
};

ScheduleReminderAdd.displayName = 'ScheduleReminderAdd';
