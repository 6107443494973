import { CacheTags, diosApi } from 'api/diosApi';
import { Reminder } from 'models';
import { nanoid } from 'nanoid';

const reminderApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get reminders
     */
    getReminders: build.query<Reminder[], string>({
      query: (_customerId) => `reminders`,
      providesTags: [CacheTags.Reminder],
      transformResponse: (result: Reminder[]) => {
        return result.map((reminder) => ({
          ...reminder,
          isDirty: false,
          isValid: true,
          tags: reminder.tags ?? [],
          uid: reminder.id ?? nanoid(6),
        }));
      },
    }),

    /**
     * Add reminder
     */
    addReminder: build.mutation({
      query: (reminder: Reminder) => ({
        url: `reminders`,
        method: 'POST',
        body: {
          sendDate: reminder.sendDate,
          tags: reminder.tags?.map((tag) => tag.id) ?? [],
        },
      }),
      invalidatesTags: [CacheTags.Reminder],
      transformResponse: (result: { id: string }, _meta, arg) => {
        return {
          id: result.id,
          sendDate: arg.sendDate,
          isDirty: false,
          isValid: true,
          uid: result.id,
        };
      },
    }),

    /**
     * Update reminder
     */
    updateReminder: build.mutation({
      query: (reminder: Reminder) => ({
        url: `reminders/${reminder.id}`,
        method: 'PUT',
        body: {
          sendDate: reminder.sendDate,
          tags: reminder.tags?.map((tag) => tag.id) ?? [],
        },
      }),
      invalidatesTags: [CacheTags.Reminder],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
          isDirty: false,
          isValid: true,
        };
      },
    }),

    /**
     * Delete  reminder
     */
    deleteReminder: build.mutation({
      query: ({ reminderId }: { reminderId: string }) => ({
        url: `reminders/${reminderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTags.Reminder],
    }),
  }),
});

export const {
  useGetRemindersQuery,
  useAddReminderMutation,
  useUpdateReminderMutation,
  useDeleteReminderMutation,
} = reminderApi;
