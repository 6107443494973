import { isPast, isSameDay } from 'date-fns';
import { Reminder } from 'models';
import 'react-datepicker/dist/react-datepicker.css';

export const validateReminders = (
  reminders: Reminder[],
  startDate: string | null
) => {
  const messages = [...reminders];

  messages.forEach((reminder, index) => {
    messages[index] = {
      ...messages[index],
      isValid: true,
    };

    const isOnSameDay = messages.some(
      (message) =>
        message.sendDate &&
        reminder.sendDate &&
        message.id !== reminder.id &&
        isSameDay(new Date(message.sendDate), new Date(reminder.sendDate))
    );

    // Do not allow multiple reminders on same date
    if (isOnSameDay) {
      messages[index] = {
        ...messages[index],
        isValid: false,
      };
    }

    if (reminder.sendDate != null) {
      const sendDate = new Date(reminder.sendDate);

      // Do not allow reminders before or on start date and
      // do not allow reminders after or on end date
      if (startDate != null && sendDate <= new Date(startDate))
        messages[index] = {
          ...messages[index],
          isValid: false,
        };

      // Do not allow reminders in the past (if is dirty)
      if (reminder.isDirty && isPast(sendDate))
        messages[index] = {
          ...messages[index],
          isValid: false,
        };
    }
  });

  return messages;
};
