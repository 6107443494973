import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Switch } from '@dimatech/shared/lib/components/form';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { diosActions, selectFilter, selectFilterSettings } from 'api/diosSlice';
import { useGetRelationTagsQuery } from 'api/system/relationTagApi';
import {
  selectSystemMapFilter,
  systemMapActions,
} from 'api/system/systemMapSlice';
import { useLazyGetSystemsForMapQuery } from 'api/system/systemRelationApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { SelectRelationTags } from 'components/SelectRelationTags';
import { ViewHeader } from 'components/ViewHeader';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { SystemsMap as SystemsMapComponent } from './components/SystemsMap';

// cspell:ignore reactflow

export const SystemsMap = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);

  const systemMapFilter = useAppSelector(selectSystemMapFilter);
  const systemFilter = useAppSelector(selectFilter);
  const systemFilterSettings = useAppSelector(selectFilterSettings);

  const { data: relationTags } = useGetRelationTagsQuery(
    accessToken.customerId ?? skipToken
  );

  const [getSystemsForMap, { data: systems, isLoading, isFetching }] =
    useLazyGetSystemsForMapQuery();

  const handleGetSystems = () => {
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId &&
      getSystemsForMap(
        {
          versionInstanceId: systemFilter.instance.id,
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const handleChangeTag = (tagIds: string[]) => {
    dispatch(
      systemMapActions.setSystemMapFilter({
        ...systemMapFilter,
        relationTagIds: tagIds,
      })
    );
    dispatch(diosActions.setFilterHasChanges());
  };

  const handleToggleIsRelationLabelsShown = (checked: boolean) => {
    dispatch(
      systemMapActions.setSystemMapFilter({
        ...systemMapFilter,
        isRelationLabelsShown: checked,
      })
    );
    dispatch(diosActions.setFilterHasChanges());
  };

  const handleResetFilter = () => {
    dispatch(diosActions.resetFilter());
  };

  useEffect(() => {
    if (systemFilter?.isInstancesLoaded && systemFilter?.instance) {
      handleGetSystems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken.customerId,
    systemFilter?.isInstancesLoaded,
    systemFilter?.instance,
  ]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('SystemsMap.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isAssessmentFilterShown={true}
            isCriticalityFilterShown={true}
            isResetButtonShown={false}
          >
            {relationTags && relationTags.length > 0 && (
              <div>
                <SelectRelationTags
                  tagIds={systemMapFilter?.relationTagIds}
                  setTagIds={handleChangeTag}
                />
              </div>
            )}

            <div>
              <Switch
                label={t('SystemsMap.IsRelationLabelShown.Label')}
                tooltipText={
                  <Trans i18nKey="SystemsMap.IsRelationLabelShown.Tooltip" />
                }
                setIsChecked={handleToggleIsRelationLabelsShown}
                isChecked={systemMapFilter.isRelationLabelsShown}
              />
            </div>

            <div>
              <LinkWithTooltip
                isDisabled={!systemFilterSettings.hasChanges}
                isInverted={true}
                handleClick={() => {
                  handleResetFilter();
                }}
                tooltip={t('Search.ResetFilters')}
                icon={<BsArrowCounterclockwise />}
              >
                <span className="show-xs">{t('Search.ResetFilters')}</span>
              </LinkWithTooltip>
            </div>
          </SelectExtendedFilters>
        </CardBody>
      </Card>

      <Card>
        <CardBody isLoading={isLoading || isFetching}>
          {systems && (
            <ReactFlowProvider>
              <SystemsMapComponent systems={systems} />
            </ReactFlowProvider>
          )}
        </CardBody>
      </Card>
    </>
  );
};

SystemsMap.displayName = 'SystemsMap';
