import { CacheTags, diosApi } from 'api/diosApi';
import { filterToMySystemsQuery, systemsFilterToQuery } from 'api/utils';
import {
  AddSystem,
  GlobalSystem,
  PaginatedResult,
  Paginator,
  System,
  SystemFailedMessage,
  SystemFilter,
  SystemPaginatedResult,
} from 'models';
import { nanoid } from 'nanoid';

/* eslint-disable max-lines-per-function */

const systemApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get systems
     */
    getSystems: build.query<
      SystemPaginatedResult<System>,
      {
        filter: SystemFilter;
        _customerId: string;
      }
    >({
      query: ({ filter, _customerId }) =>
        `systems${systemsFilterToQuery(filter)}`,
      transformResponse: (result: SystemPaginatedResult<System>) => {
        return {
          ...result,
          records: result.records.map((system) => ({
            ...system,
            tags: system.tags?.sort((a, b) =>
              (a.displayName as string) > (b.displayName as string) ? 1 : -1
            ),
            uid: nanoid(6),
          })),
        };
      },
      providesTags: [CacheTags.Systems],
    }),

    /**
     * Search systems
     */
    searchSystems: build.query<
      System[],
      {
        searchTerm: string;
        _customerId: string;
      }
    >({
      query: ({ searchTerm, _customerId }) =>
        `systems/search?searchTerm=${searchTerm}`,
      providesTags: [CacheTags.SystemsSearch],
    }),

    /**
     * Add customer system
     */

    addSystem: build.mutation({
      query: ({
        system,
        entityId,
      }: {
        system: AddSystem[];
        entityId: string;
      }) => ({
        url: `systems/${entityId}`,
        method: 'POST',
        body: system,
      }),
      invalidatesTags: [
        CacheTags.Overview,
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemData,
        CacheTags.SystemsMy,
        CacheTags.SystemPortfolioValue,
        CacheTags.SystemTurnover,
        CacheTags.SystemOverview,
        CacheTags.SystemsAnalytics,
        CacheTags.SystemProgress,
        CacheTags.Respondent,
        CacheTags.SystemWithRelations,
      ],
    }),

    /**
     * Update customer system
     */
    updateSystem: build.mutation({
      query: (system: System) => ({
        url: `systems/${system.id}`,
        method: 'PUT',
        body: {
          name: system.name,
          globalSystemId: system.globalSystemId,
        },
      }),
      invalidatesTags: [
        CacheTags.Overview,
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemData,
        CacheTags.SystemsMy,
        CacheTags.SystemPortfolioValue,
        CacheTags.SystemTurnover,
        CacheTags.SystemOverview,
        CacheTags.SystemsAnalytics,
        CacheTags.SystemProgress,
        CacheTags.SystemHistory,
        CacheTags.SystemWithRelations,
        CacheTags.SystemData,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     *  Move system
     */
    moveSystem: build.mutation({
      query: ({
        systemId,
        entityId,
        copy,
      }: {
        systemId: string;
        entityId: string;
        copy: boolean;
      }) => ({
        url: `systems/${systemId}/${entityId}?copy=${copy}`,
        method: 'POST',
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemData,
        CacheTags.SystemsMy,
        CacheTags.Overview,
        CacheTags.SystemPortfolioValue,
        CacheTags.SystemTurnover,
        CacheTags.SystemOverview,
        CacheTags.SystemsAnalytics,
        CacheTags.SystemProgress,
        CacheTags.SystemHistory,
        CacheTags.SystemWithRelations,
      ],
    }),

    /**
     * Delete system
     */
    deleteSystem: build.mutation({
      query: (customerSystemIds: string[]) => ({
        url: `systems?1=1${customerSystemIds
          .map((id) => id && `&customerSystemIds=${id}`)
          .join('')}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemData,
        CacheTags.SystemsAnalytics,
        CacheTags.SystemOverview,
        CacheTags.SystemTurnover,
        CacheTags.Overview,
        CacheTags.SystemProgress,
        CacheTags.SystemPortfolioValue,
        CacheTags.Respondent,
        CacheTags.SystemWithRelations,
        CacheTags.SystemData,
      ],
    }),

    /**
     * Get global systems
     */
    getGlobalSystem: build.query<
      GlobalSystem[],
      {
        _customerId: string;
        searchTerm: string;
      }
    >({
      query: ({ searchTerm }) => `systems/global?searchTerm=${searchTerm}`,
      providesTags: [CacheTags.GlobalSystem],
      transformResponse: (result: GlobalSystem[]) => {
        return result.sort((a, b) => (a.name > b.name ? 1 : -1));
      },
    }),

    /**
     * Get my customer systems
     */
    getMySystems: build.query<
      SystemPaginatedResult<System>,
      {
        filter: SystemFilter;
        _customerId: string;
        _userId: string;
      }
    >({
      query: ({ filter, _customerId, _userId }) =>
        `systems/my${filterToMySystemsQuery(filter)}`,
      transformResponse: (result: SystemPaginatedResult<System>) => {
        return {
          ...result,
          records: result.records.map((system) => ({
            ...system,
            tags: system.tags?.sort((a, b) =>
              (a.displayName as string) > (b.displayName as string) ? 1 : -1
            ),
          })),
        };
      },
      providesTags: [CacheTags.SystemsMy],
    }),

    /**
     * Import systems
     */
    previewSystemImport: build.mutation({
      query: ({ data, entityId }: { data: FormData; entityId: string }) => ({
        url: `systems/${entityId}/import/preview`,
        method: 'POST',
        body: data,
      }),
    }),

    /**
     * Get all failed respondent messages for current customer
     */
    getFailedMessages: build.query<
      PaginatedResult<SystemFailedMessage>,
      { _customerId: string; paginator: Paginator }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ _customerId, paginator }) =>
        // cspell: disable-next-line
        `systems/overview/failedemails?pageNumber=${paginator.page}&pageSize=${paginator.pageSize}&orderBy.propertyName=${paginator.orderBy}&orderBy.direction=${paginator.orderDirection}`,
      providesTags: [CacheTags.SystemFailedMessages],
    }),
  }),
});

export const {
  useAddSystemMutation,
  useGetGlobalSystemQuery,
  useGetMySystemsQuery,
  useDeleteSystemMutation,
  useUpdateSystemMutation,
  usePreviewSystemImportMutation,
  useGetSystemsQuery,
  useLazyGetSystemsQuery,
  useLazyGetMySystemsQuery,
  useLazyGetFailedMessagesQuery,
  useGetFailedMessagesQuery,
  useMoveSystemMutation,
  useSearchSystemsQuery,
  useLazySearchSystemsQuery,
} = systemApi;
