import { MySystems as MySystemsFeature } from 'features/systems/MySystems';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const MySystems = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('MySystems.Title')}>
      <MySystemsFeature />
    </View>
  );
};

MySystems.displayName = 'MySystems';
