import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { System } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRightSquare } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export const SystemActionNavigateToSystem = ({
  system,
}: {
  system: System;
}): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/system/${system.id}`, {
      state: { from: location.pathname },
    });
  };

  return (
    <>
      <BadgeMini
        tooltip={t('Administrate.Navigate.OpenSystem')}
        onClick={(e) => handleNavigate(e)}
      >
        <BsArrowRightSquare />
      </BadgeMini>
    </>
  );
};

SystemActionNavigateToSystem.displayName = 'SystemActionNavigateToSystem';
