import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { Assessment, Node } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectAssessment = ({
  assessments,
  onSelectAssessment,
  style,
}: {
  assessments?: Assessment[];
  onSelectAssessment: (assessments: Assessment[]) => void;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const nodes: Node<Assessment>[] = Object.keys(Assessment).map((value) => ({
    id: value,
    name: t(`System.Shared.Assessment.Values.${value}`),
    parentId: null,
    payload: value as Assessment,
  }));

  const handleSelectAssessment = (assessments: Node<Assessment>[]) => {
    onSelectAssessment(assessments.map((node) => node.payload));
  };

  return (
    <>
      <MultiSelect
        placeholder={t('System.Shared.Assessment.All')}
        options={nodes}
        setSelected={(selectedNodes) => {
          handleSelectAssessment(selectedNodes);
        }}
        selected={nodes.filter((node) => assessments?.includes(node.payload))}
      />
    </>
  );
};

SelectAssessment.displayName = 'SelectAssessment';
