import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { AuthenticationContext } from '../features/authentication';
export var PrivateSection = function (_a) {
    var _b;
    var requireRole = _a.requireRole, forbidRole = _a.forbidRole, requireFlag = _a.requireFlag, children = _a.children;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var featureFlags = useFlags();
    var ldContext = (_b = useLDClient()) === null || _b === void 0 ? void 0 : _b.getContext();
    var isFlagOn = !requireFlag || featureFlags[requireFlag];
    if (!isFlagOn || (ldContext === null || ldContext === void 0 ? void 0 : ldContext.anonymous)) {
        // If ldContext.anonymous is true, it means that the user is not
        // synced with LD yet (and all flags will have default state) so
        // in this case we just return null and wait for sync to complete
        return null;
    }
    if (!accessToken.isValid()) {
        return null;
    }
    if (requireRole && !accessToken.isValid()) {
        return null;
    }
    if (requireRole && !accessToken.isInRole(requireRole)) {
        return null;
    }
    if (forbidRole && accessToken.isInRole(forbidRole)) {
        return null;
    }
    return _jsx(_Fragment, { children: children });
};
PrivateSection.displayName = 'PrivateSection';
