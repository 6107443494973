import { CommonMessageTemplate } from '@dimatech/features-core/lib/features/administrate-message-template/CommonMessageTemplate';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonRoleSets } from 'utils';
import { MessageTemplate } from './components/MessageTemplate';

export const AdministrateMessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    accessToken.isInRole(CommonRoleSets.AnyAccountAdmin);

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <>
      <ViewHeader>
        <Heading1>
          {t('Administrate.MessageTemplate.MessageTemplates')}
        </Heading1>
      </ViewHeader>

      <CustomerAccountInfo />

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === 0}
          handleSelect={() => handleSelectTab(0)}
        >
          {t('Administrate.MessageTemplate.MessageTemplates')}
        </Tab>

        {isCustomerAccountAdmin && (
          <Tab
            isSelected={selectedTabIndex === 1}
            handleSelect={() => handleSelectTab(1)}
          >
            {t('Administrate.MessageTemplate.CommonMessageTemplates')}
          </Tab>
        )}
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === 0 && <MessageTemplate />}

          {selectedTabIndex === 1 && <CommonMessageTemplate />}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplate';
