import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  Buttons,
  ButtonSecondary,
  Checkbox,
  Label,
  Select,
} from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { isValidEmail } from '@dimatech/shared/lib/utils';
import {
  useAddSystemMutation,
  useDeleteSystemMutation,
} from 'api/system/systemApi';
import { useAppSelector } from 'hooks';
import {
  HttpStatus,
  ImportSystem,
  ImportSystemStatus,
  SortDirection,
} from 'models';
import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsCheckAll, BsCircleFill, BsX } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

/* eslint-disable max-lines-per-function */

export const SystemsImportList = withTheme(
  ({
    dataImportSystems,
    theme,
    setShowingImportList,
  }: {
    dataImportSystems: ImportSystem[];
    theme: Theme;
    setShowingImportList: (showingImportList: boolean) => void;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const selectedEntity = useAppSelector(selectSelectedEntity);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [systems, setSystems] = useState<ImportSystem[]>([]);
    const [systemsImport, setSystemsImport] = useState<ImportSystem[]>([]);
    const [invalidSystems, setInvalidSystems] = useState<ImportSystem[]>([]);

    const [addSystem, { isLoading: addIsLoading, error: addError }] =
      useAddSystemMutation();

    const [deleteSystem, { isLoading: deleteIsLoading, error: deleteError }] =
      useDeleteSystemMutation();

    const error = addError ?? deleteError;

    const { items, sorter } = useSortableData(systems ?? [], {
      key: 'status',
      direction: SortDirection.Asc,
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
      const newSystems = [...systems];
      const index = newSystems.findIndex((system) => system.name === name);

      newSystems[index] = {
        ...newSystems[index],
        include: e.target.checked,
      };

      setSystems(newSystems);
    };

    const handleSelectAll = () => {
      if (
        !systems.some((system) => system.status !== ImportSystemStatus.Invalid)
      ) {
        return;
      }

      const checked = !isAllChecked;
      setIsAllChecked(checked);

      setSystems(
        systems.map((system) => ({
          ...system,
          include: checked,
        }))
      );
    };

    const handleSave = async () => {
      const includedAdd = systems
        .filter(
          (system) =>
            !!system.name &&
            system.include &&
            system.status !== ImportSystemStatus.NotInSource &&
            system.status !== ImportSystemStatus.Invalid &&
            system.status !== ImportSystemStatus.Exists
        )
        .map((systems) => systems);

      const includedDelete = systems
        .filter(
          (system) =>
            system.include && system.status === ImportSystemStatus.NotInSource
        )
        .map((system) => system.id);

      if (selectedEntity?.id && includedAdd.length > 0) {
        await addSystem({
          system: includedAdd,
          entityId: selectedEntity.id,
        }).unwrap();
      }
      if (includedDelete.length > 0) {
        await deleteSystem(includedDelete).unwrap();
      }

      setShowingImportList(false);
    };

    const handleChangeCandidate = (
      e: ChangeEvent<HTMLSelectElement>,
      name: string
    ) => {
      const target = e.currentTarget;
      const newSystems = [...systems];
      const index = newSystems.findIndex((system) => system.name === name);

      if (target.value === 'null' || '') {
        newSystems[index] = {
          ...newSystems[index],
          name: systemsImport[index].name,
          globalSystemId: systemsImport[index].globalSystemId,
        };

        setSystems(newSystems);
      }

      if (target.value !== 'null') {
        const candidate = newSystems[index].candidates.find(
          (candidate) => candidate.globalSystemId === target.value
        );

        if (candidate) {
          newSystems[index] = {
            ...newSystems[index],
            name: candidate?.name,
            globalSystemId: candidate?.globalSystemId,
          };

          setSystems(newSystems);
        }
      }
    };

    useEffect(() => {
      // Filter out duplicate name and already existing
      const unique = dataImportSystems.filter(
        (system, index, self) =>
          !!system.name &&
          index ===
            self.findIndex(
              (t) => t.name.toLowerCase() === system.name.toLowerCase()
            )
      );

      setSystems(
        [...unique].map((system) => ({
          ...system,
          include:
            system.status === ImportSystemStatus.New ||
            system.status === ImportSystemStatus.CandidatesFound ||
            system.status === ImportSystemStatus.NoCandidates,
        }))
      );

      setSystemsImport(
        [...unique].map((system) => ({
          ...system,
          include:
            system.status === ImportSystemStatus.New ||
            system.status === ImportSystemStatus.CandidatesFound ||
            system.status === ImportSystemStatus.NoCandidates,
        }))
      );

      setInvalidSystems(
        dataImportSystems.filter(
          (system) =>
            system.status === ImportSystemStatus.Invalid && !!system.name
        )
      );
    }, [dataImportSystems]);

    if (addIsLoading || deleteIsLoading) {
      return <LoaderOverlay />;
    }

    return (
      <>
        {systems.length === 0 && (
          <AlertWarning style={{ marginBottom: 20 }}>
            {t(`Administrate.System.Import.ValidationError.Empty`)}
          </AlertWarning>
        )}

        {!error && invalidSystems.length > 0 && (
          <AlertWarning style={{ marginBottom: 20 }}>
            {t(`Administrate.System.Import.ValidationError.InvalidSystems`, {
              count: invalidSystems.length,
            })}
            <Trans i18nKey="Administrate.System.Import.Text" />
          </AlertWarning>
        )}

        <AlertErrors
          style={{ marginBottom: 30 }}
          error={error}
          includeErrorStatuses={[HttpStatus.BadRequest]}
        />

        {systems.length > 0 && (
          <>
            <LinkWithTooltip
              style={{ marginBottom: 20 }}
              handleClick={handleSelectAll}
              isDisabled={
                !systems.some(
                  (system) => system.status !== ImportSystemStatus.Invalid
                )
              }
              title={
                isAllChecked
                  ? t(`Administrate.System.DeselectAll.Title`)
                  : t(`Administrate.System.SelectAll.Title`)
              }
              tooltipTitle={
                isAllChecked
                  ? t(`Administrate.System.DeselectAll.TooltipTitle`)
                  : t(`Administrate.System.SelectAll.TooltipTitle`)
              }
              tooltip={
                isAllChecked
                  ? t(`Administrate.System.DeselectAll.Tooltip`)
                  : t(`Administrate.System.SelectAll.Tooltip`)
              }
              icon={isAllChecked ? <BsX /> : <BsCheckAll />}
            />

            <TableStyle>
              <thead>
                <tr>
                  <Th style={{ width: 25 }} />
                  <Th sortKey="name" sorter={sorter}>
                    {t(`Administrate.System.Title`)}
                  </Th>

                  {systems.some(
                    (system) =>
                      system.candidates && system.candidates.length > 0
                  ) && <Th>{t(`Administrate.System.Import.Candidates`)}</Th>}

                  <Th>{t(`Administrate.System.Respondent.It`)}</Th>
                  <Th>{t(`Administrate.System.Respondent.Business`)}</Th>

                  <ThRight
                    sortKey="status"
                    sorter={sorter}
                    style={{ minWidth: 160 }}
                  >
                    {t(`Administrate.System.Import.Suggestion`)}
                  </ThRight>
                </tr>
              </thead>

              <tbody>
                {items.map((system, index) => (
                  <Tr key={index}>
                    <Td>
                      {system.status !== ImportSystemStatus.Invalid &&
                        system.status !== ImportSystemStatus.Exists && (
                          <Checkbox
                            type="checkbox"
                            onChange={(e) => handleChange(e, system.name)}
                            id={index + `_${system.name}`}
                            name={system.name}
                            value={system.name}
                            checked={!!system.include}
                          />
                        )}
                    </Td>
                    <Td>
                      <Label
                        style={{ margin: 0 }}
                        htmlFor={index + `_${system.name}`}
                      >
                        {system.name}
                      </Label>
                    </Td>

                    {systems.some(
                      (system) =>
                        system.candidates && system.candidates.length > 0
                    ) && (
                      <Td>
                        {system.candidates !== null &&
                          system.status !== ImportSystemStatus.NoCandidates && (
                            <Select
                              style={{ margin: 0, maxWidth: 200 }}
                              value={system.globalSystemId ?? undefined}
                              onChange={(e) =>
                                handleChangeCandidate(e, system.name)
                              }
                            >
                              {system.globalSystemId === null ? (
                                <option value="">
                                  {t('Common.UI.Select')}
                                </option>
                              ) : (
                                <option value="null">
                                  {t(
                                    'Administrate.System.Import.ReturnOriginal'
                                  )}
                                </option>
                              )}

                              {system?.candidates?.map((candidate, index) => (
                                <option
                                  key={index}
                                  value={candidate.globalSystemId}
                                >
                                  {candidate.name}
                                </option>
                              ))}
                            </Select>
                          )}
                      </Td>
                    )}

                    <Td>
                      <SystemManager
                        status={system.status}
                        respondents={system.itRespondents}
                      />
                    </Td>

                    <Td>
                      <SystemManager
                        status={system.status}
                        respondents={system.businessRespondents}
                      />
                    </Td>

                    <TdRight style={{ whiteSpace: 'nowrap' }}>
                      {system.status === ImportSystemStatus.Invalid && (
                        <InvalidStatus system={system} />
                      )}

                      {system.status !== ImportSystemStatus.Invalid && (
                        <>
                          {t(
                            `Administrate.System.Import.Status.${system.status}`
                          )}

                          <BsCircleFill
                            style={{
                              marginLeft: 5,
                              color: importStatusToColor(system.status, theme),
                            }}
                          />
                        </>
                      )}
                    </TdRight>
                  </Tr>
                ))}
              </tbody>
            </TableStyle>
          </>
        )}

        <Buttons>
          <ButtonSecondary
            type="button"
            onClick={() => setShowingImportList(false)}
          >
            {t('Common.Form.Cancel')}
          </ButtonSecondary>

          <Button
            type="button"
            onClick={handleSave}
            disabled={
              systems.filter((system) => system.include).length === 0 ||
              addIsLoading ||
              deleteIsLoading
            }
          >
            {t('Administrate.System.Button.SaveSystems')}
          </Button>
        </Buttons>
      </>
    );
  }
);

SystemsImportList.displayName = 'SystemsImportList';

const TableStyle = styled(Table)`
  margin-top: 10px;

  td {
    vertical-align: middle;
  }
`;

const InvalidStatus = withTheme(
  ({ theme, system }: { theme: Theme; system: ImportSystem }) => {
    const { t } = useTranslation();

    const hasMissingEmail =
      !system.itRespondents ||
      system.itRespondents.length === 0 ||
      !system.itRespondents[0] ||
      !system.businessRespondents ||
      system.businessRespondents.length === 0 ||
      !system.businessRespondents[0];

    const hasInvalidEmail =
      !hasMissingEmail &&
      (isValidEmail(system.itRespondents[0]) || system.businessRespondents[0]);

    return (
      <>
        {hasMissingEmail && t(`Administrate.System.Import.Status.Missing`)}

        {hasInvalidEmail && t(`Administrate.System.Import.Status.Invalid`)}

        <BsCircleFill
          style={{
            marginLeft: 5,
            color: importStatusToColor(ImportSystemStatus.Invalid, theme),
          }}
        />
      </>
    );
  }
);

const SystemManager = withTheme(
  ({
    theme,
    respondents,
    status,
  }: {
    theme: Theme;
    respondents: string[];
    status: ImportSystemStatus;
  }) => {
    return (
      <>
        {respondents && respondents.length > 0 && !!respondents[0] && (
          <>
            {isValidEmail(respondents[0]) ? (
              <div>{respondents[0]}</div>
            ) : (
              <div
                className="i"
                style={{
                  display: 'inline-block',
                  color: theme.colors.error,
                  borderBottom: `1px dashed ${theme.colors.error}`,
                }}
              >
                {respondents[0]}
              </div>
            )}
          </>
        )}
      </>
    );
  }
);

const importStatusToColor = (status: ImportSystemStatus, theme: Theme) => {
  if (status === ImportSystemStatus.Invalid) {
    return theme.colors.error;
  }

  if (status === ImportSystemStatus.NotInSource) {
    return theme.colors.warning;
  }

  if (status === ImportSystemStatus.Exists) {
    return theme.colors.lowPriority;
  }

  return theme.colors.success;
};
