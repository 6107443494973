import { diosApi } from 'api/diosApi';
import { MessageTokenAction } from 'models';

const userApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Verify message token
     */
    verifyMessageToken: build.mutation<
      { action: MessageTokenAction; email: string },
      string
    >({
      query: (token: string) => ({
        url: `go`,
        method: 'POST',
        body: { token },
      }),
    }),
  }),
});

export const { useVerifyMessageTokenMutation } = userApi;
