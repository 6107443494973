import { Table, Td, Tr } from '@dimatech/shared/lib/components/table';
import { Tags } from '@dimatech/shared/lib/components/Tag';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Tag } from '@dimatech/shared/lib/models';
import { SelectTag } from 'components/SelectTag';
import { useTranslation } from 'react-i18next';

export const SystemTagList = ({
  canEdit,
  tags,
  setTags,
  setHasChanges,
}: {
  canEdit: boolean;
  tags?: Tag[];
  setTags: (tags?: Tag[]) => void;
  setHasChanges: (hasChanges: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const handleDeleteTag = (tag: Tag) => {
    setTags(tags?.filter((t) => t.id !== tag.id));
    setHasChanges(true);
  };

  const handleAddTag = (
    e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => {
    if (tag) {
      if (tags?.some((t) => t.id === tag.id)) {
        return;
      }
      if (tags) {
        setTags([...tags, tag]);
        setHasChanges(true);
      }
    }

    e.currentTarget.selectedIndex = 0;
  };

  return (
    <>
      <Heading4
        style={{
          marginTop: 30,
        }}
      >
        {t('System.Tag.Tags')}
      </Heading4>
      <Table
        style={{
          marginTop: 10,
        }}
      >
        <tbody>
          {tags && tags.length === 0 && (
            <Tr>
              <Td>{t('System.Tag.NoTags')}</Td>
            </Tr>
          )}

          {tags && tags.length > 0 && (
            <Tr>
              <Td>
                <Tags
                  onDelete={canEdit ? handleDeleteTag : undefined}
                  tags={tags ?? []}
                />
              </Td>
            </Tr>
          )}

          {canEdit && (
            <Tr>
              <Td
                style={{
                  borderBottom: 'none',
                }}
              >
                <SelectTag
                  onSelectTag={handleAddTag}
                  style={{ minWidth: 150, width: 'fit-content' }}
                  placeholder={t('System.Tag.Add')}
                />
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

SystemTagList.displayName = 'SystemTagList';
