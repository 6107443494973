import { axiosBaseQueryWithReAuthentication } from '@dimatech/features-core/lib/middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '@dimatech/features-core/lib/middleware/axiosRequestInterceptor';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

// Initialize an empty Dios api service that we'll inject endpoints into

export enum CacheTags {
  Tag = 'Tag',
  CustomerAccountAdmin = 'CustomerAccountAdmin',
  Report = 'Report',
  TagOverview = 'TagOverview',
  Schedule = 'Schedule',
  Reminder = 'Reminder',
  Overview = 'Overview',
  RelationTag = 'RelationTag',
  SystemWithRelations = 'SystemWithRelations',
  Systems = 'Systems',
  SystemsAnalytics = 'SystemsAnalytics',
  SystemsSearch = 'SystemsSearch',
  SystemsMy = 'SystemMy',
  SystemData = 'SystemData',
  SystemPortfolioValue = 'SystemPortfolioValue',
  SystemTurnover = 'SystemTurnover',
  SystemOverview = 'SystemOverview',
  SystemHistory = 'SystemHistory',
  SystemProgress = 'SystemProgress',
  GlobalSystem = 'GlobalSystem',
  Respondent = 'Respondent',
  Instances = 'Instances',
  ApiKey = 'ApiKey',
  SystemFailedMessages = 'SystemFailedMessages',
}

initializeAxiosConfig({ ...config.axiosDefaults });
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();

// Api
export const diosApi = createApi({
  reducerPath: 'diosApi',
  keepUnusedDataFor: 3600,
  tagTypes: [Object.values(CacheTags).join(',')],
  baseQuery: axiosBaseQueryWithReAuthentication({
    baseUrl: config.apiUrl.product.current,
  }),
  endpoints: () => ({}),
});
