import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
  Input,
  Label,
  TextArea,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Tag, TagMutation } from '@dimatech/shared/lib/models';
import {
  useAddRelationTagMutation,
  useMoveRelationTagMutation,
  useUpdateRelationTagMutation,
} from 'api/system/relationTagApi';
import {
  relationTagActions,
  selectSelectedRelationTag,
} from 'api/system/relationTagSlice';
import { SelectRelationTag } from 'components/SelectRelationTag';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RelationTagPropertiesEditButtons } from './RelationTagPropertiesEditButtons';

export const RelationTagProperties = ({
  handleMoved,
  handleDeleted,
  isDescriptionEnabled = true,
}: {
  handleMoved?: () => void;
  handleDeleted?: () => void;
  isDescriptionEnabled?: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addTag, { isLoading: addPosting, error: addErrors }] =
    useAddRelationTagMutation();
  const [updateTag, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateRelationTagMutation();
  const [moveTag] = useMoveRelationTagMutation();

  const selectedTag = useSelector(selectSelectedRelationTag);
  const [tag, setTag] = useState<TagMutation | undefined>();
  const isAdding = selectedTag && !selectedTag.id;
  const isPosting = updatePosting || addPosting;

  const [isValid, setIsValid] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [tagId, setTagId] = useState<string | undefined>();
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  useEffect(() => {
    setTag(selectedTag ? { ...selectedTag } : undefined);
    setIsValid(true);
  }, [selectedTag]);

  const handleSave = (e: React.SyntheticEvent) => {
    if (isReadOnly) {
      return;
    }

    setIsValid(true);

    if (!tag?.displayName) {
      setIsValid(false);
      return;
    }

    if (tag?.id) {
      updateTag({
        ...tag,
      })
        .unwrap()
        .then(() => {
          dispatch(
            relationTagActions.selectedRelationTag({
              ...tag,
            })
          );
          setIsChangesSaved(true);
          setHasChanges(false);
        });

      return;
    }

    addTag({
      ...tag,
    })
      .unwrap()
      .then((result) => {
        dispatch(
          relationTagActions.selectedRelationTag({
            ...tag,
            ...result,
          })
        );
        setIsChangesSaved(true);
        setHasChanges(false);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    dispatch(relationTagActions.selectedRelationTag());
  };

  const handleMove = () => {
    if (isReadOnly || !tag?.id || tag.parentId === tagId) {
      return;
    }

    moveTag({ tagId: tag?.id, parentId: tagId ?? '' })
      .unwrap()
      .then(() => handleMoved?.());

    setIsMoving(false);
    setTagId(undefined);
  };

  const handleAddTag = (
    _e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => {
    setTagId(tag?.id);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === 'Enter') {
      handleSave(e);
    }

    if (e?.key === 'Escape') {
      handleCancel(e);
    }
  };

  return (
    <>
      {isValid && (
        <AlertErrors
          error={addErrors ?? updateErrors}
          translationPath="Administrate.CustomDimension.RelationTag.ValidationError"
        />
      )}

      <Label htmlFor="displayName">
        {' '}
        {t('Administrate.CustomDimension.RelationTag.Name')}
      </Label>
      <Input
        maxLength={100}
        autoFocus
        type="text"
        id="displayName"
        name="displayName"
        placeholder={t(
          'Administrate.CustomDimension.RelationTag.NamePlaceholder'
        )}
        value={tag?.displayName ?? ''}
        invalid={!isValid && !tag?.displayName}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setHasChanges(true);
          setTag({
            ...tag,
            displayName: e.currentTarget.value,
          });
        }}
      />

      {isDescriptionEnabled && (
        <>
          <Label htmlFor="description">
            {t('Administrate.CustomDimension.RelationTag.Description')}
          </Label>
          <div className="size-s i">
            {t('Administrate.CustomDimension.RelationTag.DescriptionInfo')}
          </div>
          <TextArea
            id="description"
            name="description"
            value={tag?.description ?? ''}
            onChange={(e) => {
              setHasChanges(true);
              setTag({
                ...tag,
                description: e.currentTarget.value,
              });
            }}
          />
        </>
      )}

      {isMoving && !isAdding && (
        <Modal title={t('Administrate.CustomDimension.RelationTag.Move.Title')}>
          <div>{t('Administrate.CustomDimension.RelationTag.Move.Text')}</div>

          <Label>
            {t('Administrate.CustomDimension.RelationTag.Move.To', {
              name: tag?.displayName,
            })}
          </Label>
          <SelectRelationTag
            autoFocus
            filter={(t: Tag) => t.id !== tag?.id}
            onSelectTag={handleAddTag}
            placeholder={t(
              'Administrate.CustomDimension.RelationTag.Move.NoParent'
            )}
          />

          <Buttons>
            <ButtonSecondary type="button" onClick={() => setIsMoving(false)}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button type="button" onClick={handleMove} disabled={isReadOnly}>
              {t('Administrate.CustomDimension.RelationTag.Button.Move')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && tag && (
          <Buttons style={{ justifyContent: 'flex-start' }}>
            <RelationTagPropertiesEditButtons
              item={tag}
              handleDeleted={handleDeleted}
              setSelectedItem={setTag}
              isReadOnly={isReadOnly}
            />
            <ButtonSecondary type="button" onClick={() => setIsMoving(true)}>
              {t('Administrate.CustomDimension.RelationTag.Button.Move')}
            </ButtonSecondary>
          </Buttons>
        )}

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={isReadOnly || isPosting || !hasChanges}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

RelationTagProperties.displayName = 'RelationTagProperties';
