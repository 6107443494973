import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import {
  messageTemplateActions,
  selectSelectedMessageTemplate,
} from '@dimatech/features-core/lib/api/message-template/messageTemplateSlice';
import { MessageTemplateProperties } from '@dimatech/features-core/lib/features/administrate-message-template/components/MessageTemplateProperties';
import { MessageTemplatesList } from '@dimatech/features-core/lib/features/administrate-message-template/components/MessageTemplatesList';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { EntitiesTree } from 'features/administrate-organisation/components/EntitiesTree';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const MessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedMessageTemplate = useSelector(selectSelectedMessageTemplate);
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const navigate = useNavigate();

  const { accessToken } = useContext(AuthenticationContext);
  const { data: entities } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const handleEntitySelect = () => {
    dispatch(messageTemplateActions.selectedMessageTemplate());
  };

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        {t('Administrate.MessageTemplate.Text')}
      </div>

      {entities?.length === 0 && !selectedEntity ? (
        <>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.Entity.NoEntities')}
          </div>
          <ButtonLink
            type="button"
            onClick={() => {
              navigate('/administrate/organisation');
            }}
          >
            {t('Administrate.Entity.NoEntitiesAddFirst')}
          </ButtonLink>
        </>
      ) : (
        <Style>
          <EntitiesTree
            handleEntitySelect={handleEntitySelect}
            isDraggable={false}
          />

          <div style={{ flexGrow: 1, maxWidth: 700 }}>
            {selectedEntity && <MessageTemplatesList />}

            {selectedMessageTemplate && <MessageTemplateProperties />}
          </div>
        </Style>
      )}
    </>
  );
};

MessageTemplate.displayName = 'MessageTemplate';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding: 0 30px 30px 0;
  }
`;
