import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { Administrators } from '@dimatech/features-core/lib/features/administrate-users/Administrators';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Respondents } from './components/Respondents';

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { data: entities, isLoading } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const rootEntityId = entities?.find((e) => !e.parentId);

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('Administrate.Users.Title')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === 0}
          handleSelect={() => handleSelectTab(0)}
        >
          {t('UserRoles.DiosRespondent.Title')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === 1}
          handleSelect={() => handleSelectTab(1)}
        >
          {t('UserRoles.CustomerAdmin.Title')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === 2}
          handleSelect={() => handleSelectTab(2)}
        >
          {t('UserRoles.Reader.Title')}
        </Tab>
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === 0 && <Respondents />}

          {selectedTabIndex === 1 && (
            <Administrators isLoading={isLoading} entities={entities} />
          )}

          {selectedTabIndex === 2 && (
            <Viewers
              isEntityFilterShown={false}
              rootEntityId={rootEntityId?.id}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
