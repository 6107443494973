import { Assessment, Criticality, PercentageVerySatisfiedUsers } from 'models';

export const mapCriticalityByAssessment = (
  assessment?: Assessment,
  criticality?: Criticality
): number | null => {
  switch (assessment) {
    case Assessment.Liquidation:
    case Assessment.Consolidation:
      return mapCriticality(criticality) || 0;

    case Assessment.Development:
    case Assessment.Maintenance:
      return 4 + (mapCriticality(criticality) || 0);

    default:
      return null;
  }
};

export const mapQualityByAssessment = (
  assessment?: Assessment,
  quality?: PercentageVerySatisfiedUsers
): number | null => {
  switch (assessment) {
    case Assessment.Liquidation:
    case Assessment.Development:
      return mapQuality(quality) || 0;

    case Assessment.Consolidation:
    case Assessment.Maintenance:
      return 5 + (mapQuality(quality) || 0);

    default:
      return null;
  }
};

const mapCriticality = (criticality?: Criticality): number | null => {
  switch (criticality) {
    case Criticality.Completely:
      return 3;
    case Criticality.NotAtAll:
      return 0;
    case Criticality.SignificantlyNot:
      return 1;
    case Criticality.SignificantlyYes:
      return 2;
    default:
      return null;
  }
};

const mapQuality = (quality?: PercentageVerySatisfiedUsers): number | null => {
  switch (quality) {
    case PercentageVerySatisfiedUsers.To100:
      return 4;
    case PercentageVerySatisfiedUsers.To80:
      return 3;
    case PercentageVerySatisfiedUsers.To60:
      return 2;
    case PercentageVerySatisfiedUsers.To40:
      return 1;
    case PercentageVerySatisfiedUsers.To20:
      return 0;
    default:
      return null;
  }
};
