import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useDeleteSystemMutation } from 'api/system/systemApi';
import { systemActions } from 'api/system/systemSlice';
import { useAppDispatch } from 'hooks';
import { System } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

export const SystemsActionDeleteSelected = ({
  systems,
}: {
  systems: System[];
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [deleteSystem] = useDeleteSystemMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  const noOfSelected = systems.filter((r) => r.isSelected).length;

  const handleConfirmDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    const selected = systems
      .filter((r) => r.isSelected)
      .map((r) => r.id as string);

    if (!selected || selected.length === 0) {
      return;
    }

    deleteSystem(selected);

    setIsDeleting(false);
    dispatch(systemActions.selectedSystem());
  };

  const handleDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t('Administrate.System.DeleteSelected.ConfirmTitle')}
          handleKeyEnter={(e) => handleConfirmDelete(e)}
          handleKeyEsc={(e) => handleDelete(e, false)}
        >
          <div>
            {t('Administrate.System.DeleteSelected.ConfirmText', {
              count: noOfSelected,
            })}
          </div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleConfirmDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <LinkWithTooltip
        style={{ margin: '0 0 0 5px' }}
        handleClick={(e) => handleDelete(e, true)}
        title={t(`Administrate.System.DeleteSelected.Title`)}
        tooltipTitle={t(`Administrate.System.DeleteSelected.TooltipTitle`)}
        tooltip={t(`Administrate.System.DeleteSelected.Tooltip`)}
        icon={<BsTrash />}
        isDisabled={noOfSelected === 0}
      />
    </>
  );
};

SystemsActionDeleteSelected.displayName = 'SystemsActionDeleteSelected';
