import {
  formatAsNumber,
  getMetaData,
  nameOf,
} from '@dimatech/shared/lib/utils';
import {
  Business,
  CustomerType,
  Data,
  HistoryLogChange,
  HistoryLogChangeMetaData,
  HistoryLogChangeType,
  HistoryObjectType,
  It,
  System,
  SystemRelation,
} from 'models';
import { TFunction } from 'react-i18next';
import { getBusinessAreasForCustomer } from './tools';

export const parseHistoryChange = (
  history: HistoryLogChange,
  customerType: CustomerType,
  t?: TFunction<'translation', undefined>
): HistoryLogChangeMetaData => {
  const metaData = getMetaData(history);
  const diosMetaData = getDiosMetaData(history, metaData, customerType, t);

  return diosMetaData;
};

const getDiosMetaData = (
  history: HistoryLogChange,
  metaData: HistoryLogChangeMetaData,
  customerType: CustomerType,
  t?: TFunction<'translation', undefined>
): HistoryLogChangeMetaData => {
  const diosMetaData: HistoryLogChangeMetaData = {
    type: history.type,
    valueName: metaData.valueName,
    newValue: history.newValue || metaData.newValue,
    oldValue: history.oldValue,
  };

  const normalizedName = metaData.name?.toUpperCase();

  diosMetaData.displayName = t?.(
    `System.${metaData.name?.replace('.Value', '').replace('IT', 'It')}.Short`
  );

  getBusinessMetaData(history, metaData, diosMetaData, customerType, t);
  getItMetaData(history, metaData, diosMetaData, t);
  getRelationsMetaData(history, metaData, diosMetaData, t);

  if (metaData.name === HistoryObjectType.Tags) {
    diosMetaData.displayName = t?.(`System.Tag.Tag`);
  }

  if (normalizedName === nameOf<System>('businessRespondents').toUpperCase()) {
    diosMetaData.displayName = t?.(`Systems.BusinessRespondents`);
  }

  if (normalizedName === nameOf<System>('itRespondents').toUpperCase()) {
    diosMetaData.displayName = t?.(`Systems.ItRespondents`);
  }

  if (normalizedName === nameOf<System>('name').toUpperCase()) {
    diosMetaData.displayName = t?.(`Systems.Name`);
  }

  return diosMetaData;
};

const getRelationsMetaData = (
  history: HistoryLogChange,
  metaData: HistoryLogChangeMetaData,
  diosMetaData: HistoryLogChangeMetaData,
  t?: TFunction<'translation', undefined>
) => {
  const normalizedPath = history.path.replace('.Value', '').toUpperCase();
  const normalizedName = metaData.name?.toUpperCase();

  if (!normalizedName?.endsWith(nameOf<System>('relations').toUpperCase())) {
    return;
  }

  diosMetaData.displayName = t?.(`System.Relations.Title`);

  if (normalizedPath.endsWith(']')) {
    if (
      diosMetaData.type === HistoryLogChangeType.RemoveFromSet ||
      diosMetaData.type === HistoryLogChangeType.AddToSet
    ) {
      diosMetaData.oldValue = history.displayName;
      diosMetaData.newValue = history.displayName;
    }

    if (normalizedPath.includes('=>')) {
      diosMetaData.additionalPropertyName = `${history.displayName},
        ${t?.(`System.Relations.Type.FromShort`)}`;
    }

    if (normalizedPath.includes('=<')) {
      diosMetaData.additionalPropertyName = `${history.displayName},
        ${t?.(`System.Relations.Type.ToShort`)}`;
    }
  }

  if (
    normalizedPath.endsWith(
      nameOf<SystemRelation>('customerSystemRelationCriticality').toUpperCase()
    )
  ) {
    diosMetaData.oldValue = history.oldValue
      ? t?.(`System.Business.Criticality.Values.${history.oldValue}`)
      : '';

    diosMetaData.newValue = history.newValue
      ? t?.(`System.Business.Criticality.Values.${history.newValue}`)
      : '';

    diosMetaData.additionalPropertyName = `${history.displayName},
      ${t?.(`System.Relations.Criticality`)}`;
  }

  if (normalizedPath.endsWith(nameOf<SystemRelation>('tags').toUpperCase())) {
    diosMetaData.additionalPropertyName = `${history.displayName},
      ${t?.(`Administrate.CustomDimension.RelationTag.Title`)}`;
  }
};

const getBusinessMetaData = (
  history: HistoryLogChange,
  metaData: HistoryLogChangeMetaData,
  diosMetaData: HistoryLogChangeMetaData,
  customerType: CustomerType,
  t?: TFunction<'translation', undefined>
  // eslint-disable-next-line max-params
) => {
  const normalizedName = metaData.name?.toUpperCase();
  const namespace = nameOf<Data>('business').toUpperCase();
  const businessAreas = getBusinessAreasForCustomer(customerType);
  const area = t?.(`System.Business.Title`);

  if (
    normalizedName ===
    `${namespace}.${nameOf<Business>('description').toUpperCase()}`
  ) {
    diosMetaData.displayName = `${t?.(
      `System.Shared.Description.Short`
    )}, ${area}`;

    return;
  }

  if (
    normalizedName === `${namespace}.${nameOf<Business>('notes').toUpperCase()}`
  ) {
    diosMetaData.displayName = `${t?.(`System.Shared.Notes.Short`)}, ${area}`;

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<Business>('assessment').toUpperCase()}`
  ) {
    diosMetaData.displayName = `${t?.(
      `System.Shared.Assessment.Short`
    )}, ${area}`;

    diosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Shared.Assessment.Values.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Shared.Assessment.Values.${history.newValue}`);

    return;
  }

  if (
    normalizedName ===
      `${namespace}.${nameOf<Business>(
        'developmentCostForYear'
      ).toUpperCase()}` ||
    normalizedName ===
      `${namespace}.${nameOf<Business>('operationCostForYear').toUpperCase()}`
  ) {
    diosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    diosMetaData.newValue = formatAsNumber(history.newValue, '');

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<Business>('functionalityOverlap').toUpperCase()}`
  ) {
    diosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Business.FunctionalityOverlap.Values.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Business.FunctionalityOverlap.Values.${history.newValue}`);

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<Business>('riskOfLockInEffects').toUpperCase()}`
  ) {
    if (
      history.type !== HistoryLogChangeType.Create &&
      history.type !== HistoryLogChangeType.AddToSet
    ) {
      diosMetaData.oldValue = !history.oldValue
        ? t?.(`Common.UI.NotSet`)
        : t?.(`System.Business.RiskOfLockInEffects.Values.${history.oldValue}`);
    }

    if (
      history.type !== HistoryLogChangeType.Delete &&
      history.type !== HistoryLogChangeType.RemoveFromSet
    ) {
      diosMetaData.newValue = !history.newValue
        ? t?.(`Common.UI.NotSet`)
        : t?.(`System.Business.RiskOfLockInEffects.Values.${history.newValue}`);
    }

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<Business>('criticality').toUpperCase()}`
  ) {
    diosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Business.Criticality.Values.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Business.Criticality.Values.${history.newValue}`);

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<Business>('businessAreasSupported').toUpperCase()}`
  ) {
    if (
      history.type !== HistoryLogChangeType.Create &&
      history.type !== HistoryLogChangeType.AddToSet
    ) {
      diosMetaData.oldValue = !history.oldValue
        ? t?.(`Common.UI.NotSet`)
        : t?.(
            businessAreas.find(
              (businessArea) => businessArea.value === history.oldValue
            )?.key ?? ''
          );
    }

    if (
      history.type !== HistoryLogChangeType.Delete &&
      history.type !== HistoryLogChangeType.RemoveFromSet
    ) {
      diosMetaData.newValue = !history.newValue
        ? t?.(`Common.UI.NotSet`)
        : t?.(
            businessAreas.find(
              (businessArea) => businessArea.value === history.newValue
            )?.key ?? ''
          );
    }

    return;
  }
};

const getItMetaData = (
  history: HistoryLogChange,
  metaData: HistoryLogChangeMetaData,
  diosMetaData: HistoryLogChangeMetaData,
  t?: TFunction<'translation', undefined>
) => {
  const normalizedName = metaData.name?.toUpperCase();
  const namespace = nameOf<Data>('it').toUpperCase();
  const area = t?.(`System.It.Title`);

  if (
    normalizedName === `${namespace}.${nameOf<It>('description').toUpperCase()}`
  ) {
    diosMetaData.displayName = `${t?.(
      `System.Shared.Description.Short`
    )}, ${area}`;

    return;
  }

  if (normalizedName === `${namespace}.${nameOf<It>('notes').toUpperCase()}`) {
    diosMetaData.displayName = `${t?.(`System.Shared.Notes.Short`)}, ${area}`;

    return;
  }

  if (
    normalizedName === `${namespace}.${nameOf<It>('assessment').toUpperCase()}`
  ) {
    diosMetaData.displayName = `${t?.(
      `System.Shared.Assessment.Short`
    )}, ${area}`;

    diosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Shared.Assessment.Values.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.Shared.Assessment.Values.${history.newValue}`);

    return;
  }

  if (
    normalizedName ===
      `${namespace}.${nameOf<It>('loginsPerUserAndYear').toUpperCase()}` ||
    normalizedName ===
      `${namespace}.${nameOf<It>('noOfIncidentsLastYear').toUpperCase()}` ||
    normalizedName ===
      `${namespace}.${nameOf<It>('noOfUpgradesLast3Years').toUpperCase()}` ||
    normalizedName ===
      `${namespace}.${nameOf<It>('noOfIntegrations').toUpperCase()}`
  ) {
    diosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    diosMetaData.newValue = formatAsNumber(history.newValue, '');

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<It>('hasOpenApiSupport').toUpperCase()}`
  ) {
    diosMetaData.oldValue = !history.oldValue
      ? ''
      : t?.(`System.It.HasOpenApiSupport.Values.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? ''
      : t?.(`System.It.HasOpenApiSupport.Values.${history.newValue}`);

    return;
  }

  if (
    normalizedName === `${namespace}.${nameOf<It>('hasSAD').toUpperCase()}` ||
    normalizedName ===
      `${namespace}.${nameOf<It>('hasControlOverData').toUpperCase()}` ||
    normalizedName ===
      `${namespace}.${nameOf<It>('isPartOfDataShared').toUpperCase()}`
  ) {
    diosMetaData.oldValue = !history.oldValue
      ? ''
      : t?.(`History.Common.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? ''
      : t?.(`History.Common.${history.newValue}`);

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<It>('operationInternalOrExternal').toUpperCase()}`
  ) {
    diosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.It.OperationInternalOrExternal.Values.${history.oldValue}`);
    diosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`System.It.OperationInternalOrExternal.Values.${history.newValue}`);

    return;
  }

  if (
    normalizedName ===
    `${namespace}.${nameOf<It>('percentageVerySatisfiedUsers').toUpperCase()}`
  ) {
    diosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(
          `System.It.PercentageVerySatisfiedUsers.Values.${history.oldValue}`
        );
    diosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(
          `System.It.PercentageVerySatisfiedUsers.Values.${history.newValue}`
        );

    return;
  }
};
