import {
  features_core_en,
  features_core_sv,
} from '@dimatech/features-core/lib/localization/';
import { defaultOptions } from '@dimatech/shared/lib/localization';
import shared_en from '@dimatech/shared/lib/localization/lang/en.json';
import shared_sv from '@dimatech/shared/lib/localization/lang/sv.json';
import { Language, Translations } from '@dimatech/shared/lib/models';
import { mergeJson } from '@dimatech/shared/lib/utils';
import { enGB as en, sv } from 'date-fns/locale';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import dios_administrate_custom_dimensions_en from './lang/administrate-custom-dimensions/en.json';
import dios_administrate_custom_dimensions_sv from './lang/administrate-custom-dimensions/sv.json';
import dios_en from './lang/en.json';
import dios_roles_en from './lang/roles/en.json';
import dios_roles_sv from './lang/roles/sv.json';
import dios_sv from './lang/sv.json';
import dios_system_en from './lang/system/en.json';
import dios_system_sv from './lang/system/sv.json';

registerLocale(Language.English, en);
registerLocale(Language.Swedish, sv);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(defaultOptions);

// Merge all shared language files with the language files in this app
i18n.addResourceBundle(
  Language.Swedish,
  Translations.Common,
  mergeJson(features_core_sv, [
    shared_sv,
    dios_roles_sv,
    dios_administrate_custom_dimensions_sv,
    dios_system_sv,
    dios_sv,
  ])
);

i18n.addResourceBundle(
  Language.English,
  Translations.Common,
  mergeJson(features_core_en, [
    shared_en,
    dios_roles_en,
    dios_administrate_custom_dimensions_en,
    dios_system_en,
    dios_en,
  ])
);

export default i18n;
