import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { Criticality, Node } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectCriticality = ({
  criticality,
  onSelectCriticality,
  style,
}: {
  criticality?: Criticality[];
  onSelectCriticality: (criticality: Criticality[]) => void;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const nodes: Node<Criticality>[] = Object.keys(Criticality).map((value) => ({
    id: value,
    name: t(`System.Business.Criticality.Values.${value}`),
    parentId: null,
    payload: value as Criticality,
  }));

  const handleSelectCriticality = (criticality: Node<Criticality>[]) => {
    onSelectCriticality(criticality.map((node) => node.payload));
  };

  return (
    <>
      <MultiSelect
        placeholder={t('System.Business.Criticality.All')}
        options={nodes}
        setSelected={(selectedNodes) => {
          handleSelectCriticality(selectedNodes);
        }}
        selected={nodes.filter((node) => criticality?.includes(node.payload))}
      />
    </>
  );
};

SelectCriticality.displayName = 'SelectCriticality';
