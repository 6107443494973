import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetVersionInstancesWithDataQuery } from 'api/instances/instancesApi';
import { useGetSystemDataQuery } from 'api/system/systemDataApi';
import { parseISO } from 'date-fns';
import { Business, It, VersionInstance } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SystemCopyFromPrevious = ({
  systemId,
  setDataBusiness,
  setDataIt,
}: {
  systemId: string;
  setDataBusiness?: (data: Business) => void;
  setDataIt?: (data: It) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [previousInstance, setPreviousInstance] = useState<VersionInstance>();
  const [previousStatus, setPreviousStatus] = useState<number>();
  const [hasCopied, setHasCopied] = useState(false);

  const {
    data: instances,
    isLoading,
    isFetching,
  } = useGetVersionInstancesWithDataQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const { data: previousData } = useGetSystemDataQuery(
    accessToken.customerId &&
      accessToken.user?.id &&
      previousInstance &&
      systemId
      ? {
          customerSystemId: systemId,
          versionInstanceId: previousInstance.id,
          _customerId: accessToken.customerId,
          _userId: accessToken.user?.id,
        }
      : skipToken
  );

  const handleCopyData = () => {
    setDataBusiness &&
      previousData?.business &&
      setDataBusiness(previousData.business);
    setDataIt && previousData?.it && setDataIt(previousData.it);

    setHasCopied(true);
  };

  useEffect(() => {
    if (instances) {
      const instance = [...instances]
        .sort((a, b) => (a.startDate < b.startDate ? 1 : -1))
        .find(
          (instance) =>
            parseISO(instance.startDate).getFullYear() !==
            new Date().getFullYear()
        );

      setPreviousInstance(instance);
    }
  }, [instances]);

  useEffect(() => {
    if (setDataBusiness) {
      setPreviousStatus(previousData?.answeredBusiness);
    } else if (setDataIt) {
      setPreviousStatus(previousData?.answeredIt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousData]);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return !hasCopied &&
    previousInstance &&
    previousData &&
    previousStatus &&
    previousStatus > 0 ? (
    <AlertInfo style={{ padding: 10, marginTop: 10, display: 'inline-block' }}>
      <div style={{ marginBottom: 5 }}>
        {t('System.CopyFromPrevious.Text', {
          dataName: setDataBusiness
            ? t('System.Business.Title')
            : t('System.It.Title'),
          versionName: previousInstance.name,
        })}
      </div>

      <ButtonLink onClick={handleCopyData}>
        {t('System.CopyFromPrevious.ButtonText', {
          versionName: previousInstance.name,
        })}
      </ButtonLink>
    </AlertInfo>
  ) : null;
};

SystemCopyFromPrevious.displayName = 'SystemCopyFromPrevious';
