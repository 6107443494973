import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Schedule } from './components/Schedule';

export const AdministrateSchedule = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Schedule.Title')}</Heading1>
      </ViewHeader>

      <CustomerAccountInfo />

      {accessToken && !accessToken?.user?.currentCustomer.isApproved && (
        <Card>
          <AlertInfo>{t('Administrate.Schedule.NotApproved')}</AlertInfo>
        </Card>
      )}

      {accessToken?.user?.currentCustomer.isApproved && (
        <Card>
          <CardBody>
            <div style={{ marginBottom: 20 }}>
              {t('Administrate.Schedule.Text')}
            </div>

            <Schedule />
          </CardBody>
        </Card>
      )}
    </>
  );
};

AdministrateSchedule.displayName = 'AdministrateSchedule';
