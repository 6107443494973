import { CacheTags, diosApi } from 'api/diosApi';
import { VersionInstance } from 'models';

const instancesApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get all version instances
     */
    getVersionInstances: build.query<VersionInstance[], void>({
      query: () => 'instances',
      transformResponse: (instances: VersionInstance[]) => {
        return [...(instances ?? [])].reverse();
      },
      providesTags: [CacheTags.Instances],
    }),

    /**
     * Get all version instances that have data for current customer
     */
    getVersionInstancesWithData: build.query<VersionInstance[], string>({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: (_customerId) => 'instances/valid',
      transformResponse: (instances: VersionInstance[]) => {
        // Store list of sorted instances in state
        return [...(instances ?? [])].reverse();
      },
    }),
  }),
});

export const {
  useGetVersionInstancesQuery,
  useGetVersionInstancesWithDataQuery,
} = instancesApi;
