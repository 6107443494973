import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { diosActions, selectFilterSettings } from 'api/diosSlice';
import {
  useGetVersionInstancesQuery,
  useGetVersionInstancesWithDataQuery,
} from 'api/instances/instancesApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { VersionInstance } from 'models';
import { useContext, useEffect, useState } from 'react';

export const SelectPeriod = ({
  instance,
  setInstance,
  isOnlyPeriodsWithDataShown = false,
}: {
  instance?: VersionInstance;
  setInstance: (instance?: VersionInstance) => void;
  isOnlyPeriodsWithDataShown?: boolean;
}): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const systemSettings = useAppSelector(selectFilterSettings);

  const [instances, setInstances] = useState<VersionInstance[] | undefined>();
  const { accessToken } = useContext(AuthenticationContext);

  const {
    data: instancesAll,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    isSuccess: isSuccessAll,
  } = useGetVersionInstancesQuery(
    !isOnlyPeriodsWithDataShown ? undefined : skipToken
  );

  const {
    data: instancesWithData,
    isLoading: isLoadingWithData,
    isFetching: isFetchingWithData,
    isSuccess: isSuccessWithData,
  } = useGetVersionInstancesWithDataQuery(
    isOnlyPeriodsWithDataShown && accessToken.customerId
      ? accessToken.customerId
      : skipToken
  );

  const isLoading = isOnlyPeriodsWithDataShown
    ? isLoadingWithData
    : isLoadingAll;

  const isSuccess = isOnlyPeriodsWithDataShown
    ? isSuccessWithData
    : isSuccessAll;

  const isFetching = isOnlyPeriodsWithDataShown
    ? isFetchingWithData
    : isFetchingAll;

  useEffect(() => {
    if (isOnlyPeriodsWithDataShown && instancesWithData) {
      setInstances(instancesWithData);
    }

    if (!isOnlyPeriodsWithDataShown && instancesAll) {
      setInstances(instancesAll);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instancesWithData, instancesAll]);

  // Notify that instances has been loaded
  useEffect(() => {
    dispatch(
      diosActions.setFilterIsInstanceLoaded(
        !isFetching && !isLoading && isSuccess
      )
    );

    if (!isFetching && !isLoading && instances) {
      dispatch(diosActions.setFilterHasInstances(instances.length > 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, isSuccess, accessToken.customerId, instances]);

  // Select latest instance if no instances was already selected
  useEffect(() => {
    if (instances && instances.length > 0 && instances[0].id) {
      if (!instance?.id || !instances.find((item) => item.id === instance.id)) {
        setInstance(instances[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, instances]);

  useEffect(() => {
    if (instances && systemSettings.hasChanges === false) {
      setInstance(instances[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemSettings]);

  if (isLoading) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      {instances && instances.length > 0 ? (
        <Select
          value={instance?.id}
          onChange={(e) => {
            setInstance(instances.find((i) => i.id === e.currentTarget.value));
          }}
        >
          {instances?.map((instance) => (
            <option key={instance.id} value={instance.id}>
              {instance.name}
            </option>
          ))}
        </Select>
      ) : (
        <Select disabled>
          <option>-</option>
        </Select>
      )}
    </>
  );
};

SelectPeriod.displayName = 'SelectPeriod';
