import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { Report as ReportModel } from 'models';
import { useTranslation } from 'react-i18next';
import { BsFileExcel } from 'react-icons/bs';
import styled from 'styled-components';

export const Reports = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    > div:first-of-type {
      margin-right: 20px;

      > h3 {
        margin-bottom: 10px;
      }
    }

    > div:last-of-type {
      display: flex;
      justify-content: flex-end;

      min-width: 150px;
      padding: 5px;
    }
  }

  > div:nth-child(odd) {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.background};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
  }
`;

Reports.displayName = 'Reports';

export const Report = ({
  report,
  isDownloading,
  isReadOnly = false,
  handleDownload,
}: {
  report: ReportModel;
  isDownloading: boolean;
  isReadOnly?: boolean;
  handleDownload: (report: ReportModel) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div className="size-l">{report.name}</div>

        {report.description && (
          <div className="size-s">{report.description}</div>
        )}
      </div>

      <div>
        {isDownloading ? (
          <LoaderSmall />
        ) : (
          <LinkWithTooltip
            isDisabled={isReadOnly}
            handleClick={() => handleDownload(report)}
            title={t('Reports.Download.Download')}
            tooltipTitle={t('Reports.Download.Tooltip')}
            tooltip={t('Reports.Download.TooltipTitle')}
            icon={<BsFileExcel />}
          />
        )}
      </div>
    </div>
  );
};

Report.displayName = 'Report';
