/* 
We allow this here since this is the entry point of 
getting all settings from the .env files
*/
/* eslint-disable no-process-env */
import {
  config as sharedConfig,
  Config as SharedConfig,
} from '@dimatech/shared/lib/config';

// NOTE: Add settings here that are unique for this app, shared settings should be added to shared config

export const config: Config = {
  ...sharedConfig,
};

type Config = SharedConfig;
