import { Tags } from '@dimatech/shared/lib/components/Tag';
import { Tag } from '@dimatech/shared/lib/models';
import { SelectRelationTag } from 'components/SelectRelationTag';
import { SystemRelation } from 'models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const SystemRelationTagList = ({
  canEdit,
  relation,
  handleChangeTags,
}: {
  canEdit: boolean;
  relation: SystemRelation;
  handleChangeTags: (tags: Tag[]) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const handleDeleteTag = (tag: Tag) => {
    handleChangeTags(relation.tags?.filter((t) => t.id !== tag.id) ?? []);
  };

  const handleAddTag = (
    e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => {
    if (tag) {
      if (relation.tags?.some((t) => t.id === tag.id)) {
        return;
      }

      handleChangeTags([...(relation.tags ?? []), tag]);
    }

    e.currentTarget.selectedIndex = 0;
  };

  return (
    <Style>
      <div>
        {canEdit && (
          <SelectRelationTag
            onSelectTag={handleAddTag}
            style={{ minWidth: 80, width: 'fit-content' }}
            placeholder={t('System.Relations.Tag.Add')}
          />
        )}

        {relation.tags && relation.tags.length > 0 && (
          <Tags
            style={{ marginLeft: 10 }}
            onDelete={canEdit ? handleDeleteTag : undefined}
            tags={relation.tags}
          />
        )}

        {relation.tags && relation.tags.length === 0 && !canEdit && (
          <div>{t('System.Relations.Tag.NoTags')}</div>
        )}
      </div>
    </Style>
  );
};

SystemRelationTagList.displayName = 'SystemRelationTagList';

const Style = styled.div`
  > div {
    display: flex;

    > div {
      margin-right: 10px;
    }
  }
`;
