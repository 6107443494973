import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Select } from '@dimatech/shared/lib/components/form';
import { Table, Td, Th, Tr } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { SearchAllSystems } from 'components/SearchAllSystems';
import {
  Criticality,
  Data,
  RelatedSystem,
  System,
  SystemRelation,
  SystemRelationType,
  Tag,
} from 'models';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArrowLeftCircle,
  BsArrowRightCircleFill,
  BsCircleFill,
  BsPlus,
  BsTrash,
} from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { getCriticalityColor } from 'utils';
import { SystemCopyRelationsFromPrevious } from './SystemCopyRelationsFromPrevious';
import { SystemRelationTagList } from './SystemRelationTagList';

export const RelationsEdit = ({
  systemId,
  data,
  relations,
  setRelations,
  canEdit,
  isValid,
}: {
  systemId: string;
  data: Data;
  relations?: SystemRelation[];
  setRelations: (relations: SystemRelation[]) => void;
  canEdit: boolean;
  isValid: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const [relatedSystems, setRelatedSystems] = useState<RelatedSystem[]>([]);

  const handleDelete = (relation: SystemRelation) => {
    if (!canEdit) {
      return;
    }

    if (relations && relation.uid) {
      const index = relations.findIndex((d) => d.uid === relation.uid);

      if (index > -1) {
        const newRelations = [...relations];

        newRelations.splice(index, 1);

        setRelations(newRelations);
      }
    }
  };

  const handleAdd = (e: React.SyntheticEvent) => {
    const newRelations = [...(relations ?? [])];

    newRelations.push({
      uid: nanoid(6),
    });

    setRelations(newRelations);
  };

  const handleChange = (relation: RelatedSystem) => {
    if (!relations) {
      return;
    }

    const newRelations = [...relations];
    const index = newRelations.findIndex((item) => item.uid === relation.uid);

    if (index === -1) {
      return;
    }

    newRelations[index] = {
      customerSystemRelationCriticality: relation.criticality,
      fromCustomerSystemId:
        relation.type === SystemRelationType.From
          ? systemId
          : relation.systemId,
      toCustomerSystemId:
        relation.type === SystemRelationType.To ? systemId : relation.systemId,
      fromCustomerSystemName:
        relation.type === SystemRelationType.From ? data.name : relation.name,
      toCustomerSystemName:
        relation.type === SystemRelationType.To ? data.name : relation.name,
      tags: relation.tags,
      id: relation.id,
      uid: relation.uid,
    };

    setRelations(newRelations);
  };

  useEffect(() => {
    const rel = relations?.map((relation) => {
      const type =
        relation.fromCustomerSystemId === systemId
          ? SystemRelationType.From
          : SystemRelationType.To;

      return {
        id: relation.id,
        systemId:
          type === SystemRelationType.From
            ? relation.toCustomerSystemId
            : relation.fromCustomerSystemId,
        name:
          type === SystemRelationType.From
            ? relation.toCustomerSystemName
            : relation.fromCustomerSystemName,
        criticality: relation.customerSystemRelationCriticality,
        type,
        uid: relation.uid,
        tags: relation.tags,
        isCopy: relation.isCopy,
      };
    });

    setRelatedSystems(rel ?? []);
  }, [relations, systemId]);

  if (!relatedSystems) {
    return null;
  }

  return (
    <>
      <Heading3>{t('System.Relations.Title')}</Heading3>

      {canEdit && data && (!data.relations || data.relations.length === 0) && (
        <SystemCopyRelationsFromPrevious
          setRelations={setRelations}
          systemId={systemId}
        />
      )}

      <LinkWithTooltip
        title={t('System.Relations.Add.Title')}
        tooltipTitle={t('System.Relations.Add.Title')}
        tooltip={t('System.Relations.Add.Tooltip')}
        icon={<BsPlus />}
        isPrimary={true}
        handleClick={handleAdd}
        isDisabled={!canEdit}
        style={{ margin: '15px 0' }}
      />

      {relatedSystems.length === 0 && (
        <div>{t('System.Relations.NoRelations')}</div>
      )}

      {relatedSystems.length > 0 && (
        <TableStyle>
          <thead>
            <Tr>
              <Th style={{ width: 250 }}>
                {t('System.Relations.Type.Direction')}
              </Th>
              <Th style={{ width: 200 }}>
                {t('System.Relations.Criticality')}
              </Th>
              <Th>{t('System.Title')}</Th>
              <Th>{t('System.Relations.Tag.Tags')}</Th>
              {canEdit && <Th style={{ width: 25 }} />}
            </Tr>
          </thead>

          <tbody>
            {relatedSystems?.map((relation) => (
              <Tr key={relation.uid}>
                <Relation
                  relation={relation}
                  handleChange={handleChange}
                  systemId={systemId}
                  canEdit={canEdit}
                  isValid={isValid}
                />

                {canEdit && (
                  <Td>
                    <BadgeMini
                      tooltip={t('System.Relations.Delete.Tooltip')}
                      onClick={() => handleDelete(relation)}
                    >
                      <BsTrash />
                    </BadgeMini>
                  </Td>
                )}
              </Tr>
            ))}
          </tbody>
        </TableStyle>
      )}
    </>
  );
};

RelationsEdit.displayName = 'RelationsEdit';

const Relation = withTheme(
  ({
    relation,
    handleChange,
    systemId,
    canEdit,
    isValid,
    theme,
  }: {
    relation: RelatedSystem;
    handleChange: (relation: RelatedSystem) => void;
    systemId: string;
    canEdit: boolean;
    isValid: boolean;
    theme: Theme;
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <Td>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            {relation.type === SystemRelationType.From ? (
              <BsArrowLeftCircle />
            ) : (
              <BsArrowRightCircleFill />
            )}

            <Select
              name="relationType"
              id="relationType"
              value={relation.type}
              onChange={(e) => {
                handleChange({
                  ...relation,
                  type: e.currentTarget.value as SystemRelationType,
                });
              }}
              style={{ width: 'fit-content' }}
              disabled={!canEdit}
            >
              <option value="To">{t('System.Relations.Type.To')}</option>
              <option value="From">{t('System.Relations.Type.From')}</option>
            </Select>
          </div>
        </Td>

        <Td>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <BsCircleFill
              style={{
                color: getCriticalityColor(theme, relation.criticality),
              }}
            />

            <Select
              name="criticality"
              id="criticality"
              value={relation.criticality ?? ''}
              onChange={(e) => {
                handleChange({
                  ...relation,
                  criticality: e.currentTarget.value as Criticality,
                });
              }}
              style={{ width: 'fit-content' }}
              disabled={!canEdit}
            >
              <option value="">- {t('Common.UI.Select')}</option>
              {Object.keys(Criticality)
                .filter((value) => value !== Criticality.NotSet)
                .map((value) => (
                  <option key={value} value={value}>
                    {t(`System.Business.Criticality.Values.${value}`)}
                  </option>
                ))}
            </Select>
          </div>
        </Td>

        {!relation.id && !relation.isCopy && (
          <Td>
            <SearchAllSystems
              filter={(systems?: System[]) =>
                systems?.filter(
                  (s) => s.id !== relation.systemId && s.id !== systemId
                ) ?? []
              }
              isInvalid={!isValid && !relation.systemId}
              setSystem={(system?: System) => {
                handleChange({
                  ...relation,
                  systemId: system?.id,
                  name: system?.name,
                });
              }}
            />
          </Td>
        )}

        {(relation.id || relation.isCopy) && <Td>{relation.name}</Td>}

        <Td>
          <SystemRelationTagList
            relation={relation}
            canEdit={canEdit}
            handleChangeTags={(tags: Tag[]) => {
              handleChange({
                ...relation,
                tags,
              });
            }}
          />
        </Td>
      </>
    );
  }
);

const TableStyle = styled(Table)`
  margin-top: 20px;

  td {
    vertical-align: middle;

    svg {
      font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
    }
  }
`;
