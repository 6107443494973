import { Color, ProductPrimaryColor, Theme } from '@dimatech/shared/lib/themes';
import {
  Assessment,
  Criticality,
  DiosColors,
  SystemWithRelations,
} from 'models';
import { Edge, MarkerType, Node } from 'reactflow';

// cspell:ignore reactflow

export const createNodes = (systems: SystemWithRelations[]): Node[] => {
  return systems
    .filter((system) => system.relations && system.relations.length > 0)
    .map(
      (system) =>
        ({
          id: system.id,
          position: { x: 0, y: 0 },
          data: {
            label: system.name,
            system,
            ...getNodeStyle(system.assessment),
          },
          style: {
            color: Color.White,
            strokeWidth: 1,
            border: 'none',
            backgroundColor: 'transparent',
          },
          type: 'systemMapNode',
        } as Node)
    );
};

export const createEdges = (
  systems: SystemWithRelations[],
  theme: Theme
): Edge[] => {
  return systems.reduce((result: Edge[], system) => {
    system.relations?.forEach((relation) => {
      if (result.some((edge) => edge.id === relation.id)) {
        return;
      }

      result.push({
        id: relation.id,
        source: relation.fromCustomerSystemId,
        target: relation.toCustomerSystemId,
        label: relation.tags?.map((tag) => tag.displayName).join(', '),
        style: {
          backgroundColor: ProductPrimaryColor.Dios,
          strokeWidth: 1,
          ...getEdgeStyle(theme, relation.customerSystemRelationCriticality),
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: getCriticalityColor(
            theme,
            relation.customerSystemRelationCriticality
          ),
        },
      } as Edge);
    });

    return result;
  }, []);
};

export const getCriticalityColor = (
  theme: Theme,
  criticality?: Criticality | string | null
) => {
  switch (criticality as Criticality) {
    case Criticality.NotAtAll:
      return theme.colors.success;
    case Criticality.SignificantlyNot:
      return theme.colors.info;
    case Criticality.SignificantlyYes:
      return theme.colors.warning;
    case Criticality.Completely:
      return theme.colors.error;
    default:
      return theme.colors.lowPriority;
  }
};

const getEdgeStyle = (
  theme: Theme,
  criticality?: Criticality | string | null
) => {
  return {
    stroke: getCriticalityColor(theme, criticality),
  };
};

const getNodeStyle = (assessment: Assessment | string | null | undefined) => {
  switch (assessment as Assessment) {
    case Assessment.Consolidation:
      return {
        backgroundColor: DiosColors.consolidate,
        color: Color.Grey10,
      };
    case Assessment.Liquidation:
      return {
        backgroundColor: DiosColors.liquidate,
        color: Color.White,
      };
    case Assessment.Development:
      return {
        backgroundColor: DiosColors.develop,
        color: Color.White,
      };
    case Assessment.Maintenance:
      return {
        backgroundColor: DiosColors.uphold,
        color: Color.White,
      };
    default:
      return undefined;
  }
};
