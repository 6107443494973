import { CacheTags, diosApi } from 'api/diosApi';
import { SystemRelation, SystemWithRelations } from 'models';
import { nanoid } from 'nanoid';

const systemRelationApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get system relations
     */
    getSystemRelations: build.query<
      SystemRelation[],
      {
        versionInstanceId: string;
        customerSystemId: string;
        _customerId: string;
      }
    >({
      query: ({ versionInstanceId, customerSystemId, _customerId }) =>
        `systems/relations?versionInstanceId=${versionInstanceId}&customerSystemId=${customerSystemId}`,
      transformResponse: (result: SystemRelation[]) => {
        return result.map((relation) => ({
          ...relation,
          uid: nanoid(6),
        }));
      },
    }),

    /**
     * Get systems that have relations with any other system
     */
    getSystemsForMap: build.query<
      SystemWithRelations[],
      {
        versionInstanceId: string;
        _customerId: string;
      }
    >({
      query: ({ versionInstanceId, _customerId }) =>
        `systems/map?versionInstanceId=${versionInstanceId}`,
      transformResponse: (result: SystemWithRelations[]) => {
        return result.map((system) => ({
          ...system,
          tags: system.tags?.sort((a, b) =>
            (a.displayName as string) > (b.displayName as string) ? 1 : -1
          ),
        }));
      },
      providesTags: [CacheTags.SystemWithRelations],
    }),

    /**
     *  Upsert system relations
     */
    upsertSystemRelations: build.mutation({
      query: ({
        relations,
        customerSystemId,
      }: {
        relations: SystemRelation[];
        customerSystemId: string;
      }) => ({
        url: `systems/relations/${customerSystemId}`,
        method: 'POST',
        body: relations.map((relation) => ({
          ...relation,
          tags: relation.tags?.map((tag) => tag.id),
        })),
      }),
      // NOTE: invalidatesTags is called from System view so we can control reloading of data when relations is saved
    }),
  }),
});

export const {
  useUpsertSystemRelationsMutation,
  useLazyGetSystemsForMapQuery,
  useGetSystemRelationsQuery,
} = systemRelationApi;
