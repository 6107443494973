import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { AccountAdmins } from '@dimatech/features-core/lib/features/administrate-account/components/AccountAdmins';
import { ApiKey } from '@dimatech/features-core/lib/features/administrate-account/components/ApiKey';
import { BillingInfo } from '@dimatech/features-core/lib/features/administrate-account/components/BillingInfo';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading1, Heading3 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const AdministrateAccount = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const { data: entities, isLoading } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const rootEntityId = entities?.find((e) => !e.parentId);

  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.dios.isPublicApiEnabled];

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Account.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <AccountAdmins />
        </CardBody>
      </Card>

      <Card>
        <CardBody isLoading={isLoading}>
          <Heading3>{t('UserRoles.Reader.Title')}</Heading3>
          <Viewers
            isEntityFilterShown={false}
            rootEntityId={rootEntityId?.id}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <BillingInfo />
        </CardBody>
      </Card>

      {isPublicApiEnabledFlagOn && (
        <Card>
          <CardBody>
            <ApiKey />
          </CardBody>
        </Card>
      )}
    </>
  );
};

AdministrateAccount.displayName = 'AdministrateAccount';
