import { useGetTagsQuery } from '@dimatech/features-core/lib/api/tag/tagApi';
import {
  selectSelectedTag,
  tagActions,
} from '@dimatech/features-core/lib/api/tag/tagSlice';
import { TagProperties } from '@dimatech/features-core/lib/features/administrate-tags/components/TagProperties';
import { TagsTree } from '@dimatech/features-core/lib/features/administrate-tags/components/TagsTree';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CacheTags, diosApi } from 'api/diosApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Tag, TagMutation } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { isAdminReadOnly } from 'utils';
import { TabContentStyle } from './TabContentStyle';

export const Tags = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [tags, setTags] = useState<Tag[]>();
  const selectedTag = useAppSelector(selectSelectedTag);

  const {
    data,
    isFetching: isFetchingTags,
    isLoading: isLoadingTags,
  } = useGetTagsQuery(accessToken.customerId ?? skipToken);

  const handleCreate = () => {
    const tag: TagMutation = { parentId: null };

    if (selectedTag) {
      tag.parentId = selectedTag.id;
    }

    dispatch(tagActions.selectedTag(tag));
  };

  const handleTagMovedOrDeleted = () => {
    dispatch(
      diosApi.util.invalidateTags([
        CacheTags.Systems,
        CacheTags.SystemsSearch,
        CacheTags.SystemsMy,
        CacheTags.SystemsAnalytics,
        CacheTags.SystemWithRelations,
        CacheTags.SystemData,
        CacheTags.SystemOverview,
      ])
    );
  };

  useEffect(() => {
    setTags(data);
  }, [data]);

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        {t('Administrate.Tag.Text')}
      </div>

      <LinkWithTooltip
        isPrimary={true}
        isDisabled={isReadOnly}
        handleClick={handleCreate}
        title={t('Administrate.Tag.Add.TooltipTitle')}
        tooltipTitle={t('Administrate.Tag.Add.TooltipTitle')}
        tooltip={t('Administrate.Tag.Add.Tooltip')}
        icon={<BsPlus />}
        style={{ marginBottom: 20 }}
      />

      {(isFetchingTags || isLoadingTags) && <LoaderOverlay />}

      {(!tags || tags.length === 0) && !selectedTag ? (
        <>
          <div style={{ marginBottom: 20 }}>{t('Administrate.Tag.NoTags')}</div>
          <ButtonLink type="button" onClick={handleCreate}>
            {t('Administrate.Tag.NoTagsAddFirst')}
          </ButtonLink>
        </>
      ) : (
        <TabContentStyle>
          <TagsTree />

          {selectedTag && (
            <div style={{ flexGrow: 1, maxWidth: 800 }}>
              <TagProperties
                handleDeleted={handleTagMovedOrDeleted}
                handleMoved={handleTagMovedOrDeleted}
              />
            </div>
          )}
        </TabContentStyle>
      )}
    </>
  );
};

Tags.displayName = 'Tags';
