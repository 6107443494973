import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Impersonate } from '@dimatech/features-core/lib/features/authentication/components';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  Td,
  TdRight,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Admin } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import { selectSelectedSystem } from 'api/system/systemSlice';
import { useAppSelector } from 'hooks';
import { Respondent } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsClockHistory,
  BsPerson,
  BsPersonPlus,
  BsTrash,
} from 'react-icons/bs';
import { isAdminReadOnly } from 'utils';
import { RespondentAdd } from './RespondentAdd';
import { RespondentMessageHistory } from './RespondentMessageHistory';
import { RespondentMessageStatus } from './RespondentMessageStatus';

export const RespondentsList = ({
  respondents,
  setRespondents,
  title,
}: {
  respondents: Respondent[];
  setRespondents: (respondents: Respondent[]) => void;
  title: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedSystem = useAppSelector(selectSelectedSystem);

  const [isAdding, setIsAdding] = useState(false);
  const [isShowingMessagesForRespondent, setIsShowingMessagesForRespondent] =
    useState<Respondent | null>(null);

  const handleAddRespondent = (user: Admin) => {
    if (!user.email) {
      return;
    }

    if (respondents.some((o) => o.email === user.email)) {
      return;
    }

    setRespondents([...respondents, { email: user.email }]);
  };

  return (
    <>
      <Heading4 style={{ marginTop: 30 }}>{title}</Heading4>

      <LinkWithTooltip
        handleClick={() => setIsAdding(true)}
        title={t('Administrate.System.Respondent.Add.Title')}
        tooltipTitle={t('Administrate.System.Respondent.Add.TooltipTitle')}
        tooltip={t('Administrate.System.Respondent.Add.Tooltip')}
        icon={<BsPersonPlus />}
        style={{ marginTop: 10, marginBottom: 10 }}
      />

      {respondents?.length === 0 && (
        <div style={{ marginTop: 20 }}>
          {t('Administrate.System.Respondent.NoRespondents')}
        </div>
      )}

      {isAdding && (
        <RespondentAdd
          setIsAdding={setIsAdding}
          handleAdd={handleAddRespondent}
        />
      )}

      {selectedSystem && isShowingMessagesForRespondent && (
        <RespondentMessageHistory
          systemName={selectedSystem.name as string}
          respondent={isShowingMessagesForRespondent}
          setIsShowingMessagesForRespondent={setIsShowingMessagesForRespondent}
        />
      )}

      {respondents && respondents.length > 0 && (
        <Table style={{ marginBottom: 30 }}>
          <thead>
            <tr>
              <Th style={{ width: 35 }} />
              <Th style={{ paddingLeft: 0 }}>
                {t('Administrate.System.Respondent.Respondent')}
              </Th>
              <Th>{t('Administrate.System.Respondent.Messages.Title')}</Th>
              <Th />
            </tr>
          </thead>
          <tbody>
            {respondents?.map((respondent, index) => {
              const latestMessage = [...(respondent.messages ?? [])].sort(
                (a, b) =>
                  (a.sentDate as string) > (b.sentDate as string) ? -1 : 1
              )?.[0];

              return (
                <Tr key={index}>
                  <Td>
                    <BsPerson />
                  </Td>
                  <Td style={{ paddingLeft: 0 }}>{respondent.email}</Td>

                  <Td>
                    {latestMessage && (
                      <div
                        style={{
                          verticalAlign: 'middle',
                          display: 'flex',
                          alignItems: 'baseline',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <RespondentMessageStatus
                          status={latestMessage.status}
                        />

                        <span style={{ marginRight: 10, marginLeft: 10 }}>
                          {latestMessage.sentDate &&
                            formatDate(new Date(latestMessage.sentDate))}
                        </span>
                        <BadgeMini
                          data-tip
                          data-for="tt-message-history"
                          onClick={() =>
                            setIsShowingMessagesForRespondent(respondent)
                          }
                        >
                          <BsClockHistory />
                        </BadgeMini>
                      </div>
                    )}
                  </Td>
                  <TdRight
                    style={{
                      verticalAlign: 'middle',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <EditButtons
                      respondent={respondent}
                      respondents={respondents}
                      setRespondents={setRespondents}
                      isReadOnly={isReadOnly}
                    />
                  </TdRight>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

RespondentsList.displayName = 'RespondentsList';

const EditButtons = ({
  respondent,
  respondents,
  setRespondents,
  isReadOnly,
}: {
  respondent?: Respondent;
  respondents: Respondent[];
  setRespondents: (respondents: Respondent[]) => void;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    if (respondent) {
      setRespondents(respondents?.filter((r) => r !== respondent));
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Administrate.System.Respondent.Delete.ConfirmTitle')}>
          <div>{t('Administrate.System.Respondent.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <Impersonate email={respondent?.email as string} />

      <BadgeMini
        tooltipTitle={t('Administrate.System.Respondent.Delete.TooltipTitle')}
        tooltip={t('Administrate.System.Respondent.Delete.Tooltip')}
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginLeft: 7 }}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};
