import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetVersionInstancesWithDataQuery } from 'api/instances/instancesApi';
import { useGetSystemRelationsQuery } from 'api/system/systemRelationApi';
import { parseISO } from 'date-fns';
import { SystemRelation, VersionInstance } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SystemCopyRelationsFromPrevious = ({
  systemId,
  setRelations,
}: {
  systemId: string;
  setRelations: (relations: SystemRelation[]) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [previousInstance, setPreviousInstance] = useState<VersionInstance>();
  const [previousStatus, setPreviousStatus] = useState<number>();
  const [hasCopied, setHasCopied] = useState(false);

  const {
    data: instances,
    isLoading,
    isFetching,
  } = useGetVersionInstancesWithDataQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const { data: previousRelations } = useGetSystemRelationsQuery(
    accessToken.customerId && previousInstance && systemId
      ? {
          customerSystemId: systemId as string,
          versionInstanceId: previousInstance.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handleCopyData = () => {
    setRelations &&
      previousRelations &&
      setRelations(
        previousRelations.map((relation) => ({
          ...relation,
          isCopy: true,
          id: undefined,
        }))
      );

    setHasCopied(true);
  };

  useEffect(() => {
    if (instances) {
      const instance = [...instances]
        .sort((a, b) => (a.startDate < b.startDate ? 1 : -1))
        .find(
          (instance) =>
            parseISO(instance.startDate).getFullYear() !==
            new Date().getFullYear()
        );

      setPreviousInstance(instance);
    }
  }, [instances]);

  useEffect(() => {
    setPreviousStatus(previousRelations ? previousRelations.length : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRelations]);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return !hasCopied &&
    previousInstance &&
    previousRelations &&
    previousStatus &&
    previousStatus > 0 ? (
    <AlertInfo style={{ padding: 10, marginTop: 10, display: 'inline-block' }}>
      <div style={{ marginBottom: 5 }}>
        {t('System.CopyRelationsFromPrevious.Text', {
          versionName: previousInstance.name,
        })}
      </div>

      <ButtonLink onClick={handleCopyData}>
        {t('System.CopyRelationsFromPrevious.ButtonText', {
          versionName: previousInstance.name,
        })}
      </ButtonLink>
    </AlertInfo>
  ) : null;
};

SystemCopyRelationsFromPrevious.displayName = 'SystemCopyRelationsFromPrevious';
