import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Node, Tag } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetRelationTagsQuery } from 'api/system/relationTagApi';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectRelationTags = ({
  tagIds,
  setTagIds,
  placeholder,
  filter,
  autoFocus,
  inverted = false,
  tags,
  style,
}: {
  tagIds?: string[];
  setTagIds: (tagIds: string[]) => void;
  placeholder?: string;
  filter?: (tag: Tag) => boolean;
  autoFocus?: boolean;
  inverted?: boolean;
  tags?: Tag[];
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [nodes, setNodes] = useState<Node<Tag>[] | undefined>();

  const { data, isLoading } = useGetRelationTagsQuery(
    accessToken.customerId ?? skipToken
  );

  const handleChange = (selectedNodes: Node<Tag>[]) => {
    setTagIds(selectedNodes.map((node) => node.id as string));
  };

  useEffect(() => {
    const list = tags ?? data;

    if (list) {
      setNodes(
        list
          .filter((tag) => !filter || filter(tag))
          .map((tag) => ({
            id: tag.id as string,
            name: tag.displayName as string,
            description: tag.description,
            parentId: tag.parentId ?? null,
            payload: tag,
          }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tags]);

  if (isLoading) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      {nodes && (
        <MultiSelect
          inverted={inverted}
          style={style}
          placeholder={placeholder ?? t('Search.AnyRelationTag')}
          options={nodes}
          setSelected={(selectedNodes) => {
            handleChange(selectedNodes);
          }}
          selected={nodes.filter((node) => tagIds?.includes(node.id))}
        />
      )}
    </>
  );
};

SelectRelationTags.displayName = 'SelectRelationTags';
