import { useGetTagsQuery } from '@dimatech/features-core/lib/api/tag/tagApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MessageSchedule, Node, Reminder, Tag } from 'models';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';
import { ScheduleReminder } from './ScheduleReminder';
import { ScheduleReminderAdd } from './ScheduleReminderAdd';

export const ScheduleReminders = ({
  reminders,
  setReminders,
  schedule,
  setSchedule,
  setIsValidReminder,
}: {
  reminders: Reminder[];
  setReminders: Dispatch<SetStateAction<Reminder[]>>;
  schedule: MessageSchedule | undefined;
  setSchedule: Dispatch<SetStateAction<MessageSchedule | undefined>>;
  setIsValidReminder: (isValid: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const isScheduleEnableTagsForRemindersOn =
    useFlags()[flags.permanent.app.dios.scheduleEnableTagsForReminders];

  const [tags, setTags] = useState<Node<Tag>[] | undefined>();
  const { data } = useGetTagsQuery(
    isScheduleEnableTagsForRemindersOn && accessToken.customerId
      ? accessToken.customerId
      : skipToken
  );

  useEffect(() => {
    if (data) {
      setTags(
        data.map((tag) => ({
          id: tag.id as string,
          name: tag.displayName as string,
          parentId: tag.parentId ?? null,
          payload: tag,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!reminders) {
    return <Loader>{t('Common.UI.Loading')}</Loader>;
  }

  return (
    <>
      {[...reminders]
        ?.sort(
          (a, b) =>
            new Date(a.sendDate ?? '').getTime() -
            new Date(b.sendDate ?? '').getTime()
        )
        ?.map((reminder, index) => (
          <ScheduleReminder
            key={reminder.uid ?? index}
            reminders={reminders}
            setReminders={setReminders}
            schedule={schedule}
            setIsValidReminder={setIsValidReminder}
            tags={tags}
            reminder={reminder}
            label={`${t('Administrate.Schedule.Reminder')} ${index + 1}`}
            isReadOnly={isReadOnly}
          />
        ))}

      <ScheduleReminderAdd reminders={reminders} setReminders={setReminders} />
    </>
  );
};

ScheduleReminders.displayName = 'ScheduleReminders';
