import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import {
  InputNumber,
  Label,
  Select,
  TextArea,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import {
  Assessment,
  Data,
  HasOpenApiSupport,
  It,
  LifeCyclePhase,
  OperationInternalOrExternal,
  PercentageVerySatisfiedUsers,
  Risk,
  YesNo,
} from 'models';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SystemCopyFromPrevious } from './SystemCopyFromPrevious';

/* eslint-disable max-lines-per-function */

export const ItEdit = ({
  systemId,
  data,
  dataIt,
  setDataIt,
  canEdit,
}: {
  systemId: string;
  data: Data;
  dataIt: It | undefined;
  setDataIt: (data: It) => void;
  canEdit: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const [isYearOfDecommissionShown, setIsYearOfDecommissionShown] =
    useState<boolean>(
      data?.it?.lifeCyclePhase === LifeCyclePhase.Decommissioned
    );

  return dataIt ? (
    <div>
      <Heading3>{t('System.It.Title')}</Heading3>

      {canEdit && data && (!data.answeredIt || data.answeredIt === 0) && (
        <SystemCopyFromPrevious setDataIt={setDataIt} systemId={systemId} />
      )}

      <div style={{ flexDirection: 'column' }}>
        <div>
          <Label htmlFor="description">
            {t('System.Shared.Description.Title')}
          </Label>
        </div>

        <TextArea
          id="description"
          name="description"
          value={dataIt.description ?? ''}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              description: e.currentTarget.value,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="assessment">
            {t('System.Shared.Assessment.Title')}
          </Label>

          <HelpIcon
            title={t('System.Shared.Assessment.Short')}
            text={<Trans i18nKey={'System.Shared.Assessment.Help'} />}
          />
        </div>

        <Select
          name="assessment"
          id="assessment"
          value={dataIt.assessment ?? undefined}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              assessment: e.currentTarget.value
                ? (e.currentTarget.value as Assessment)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(Assessment)
            .filter((value) => value !== Assessment.NotSet)
            .map((value) => (
              <option key={value} value={value}>
                {t(`System.Shared.Assessment.Values.${value}`)}
              </option>
            ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="noOfActiveUsers">
            {t('System.It.NoOfActiveUsers.Title')}
          </Label>
        </div>

        <InputNumber
          id="noOfActiveUsers"
          name="noOfActiveUsers"
          value={dataIt.noOfActiveUsers ?? ''}
          onValueChange={({ value }) => {
            setDataIt({
              ...dataIt,
              noOfActiveUsers: !isNaN(parseInt(value)) ? +value : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="operationInternalOrExternal">
            {t('System.It.OperationInternalOrExternal.Title')}
          </Label>
        </div>

        <Select
          name="operationInternalOrExternal"
          id="operationInternalOrExternal"
          value={dataIt.operationInternalOrExternal ?? undefined}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              operationInternalOrExternal: e.currentTarget.value
                ? (e.currentTarget.value as OperationInternalOrExternal)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(OperationInternalOrExternal).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.OperationInternalOrExternal.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="loginsPerUserAndYear">
            {t('System.It.LoginsPerUserAndYear.Title')}
          </Label>

          <HelpIcon
            title={t('System.It.LoginsPerUserAndYear.Title')}
            text={
              <Trans i18nKey="System.It.LoginsPerUserAndYear.Description" />
            }
          />
        </div>

        <InputNumber
          id="loginsPerUserAndYear"
          name="loginsPerUserAndYear"
          value={dataIt.loginsPerUserAndYear ?? ''}
          onValueChange={({ value }) => {
            setDataIt({
              ...dataIt,
              loginsPerUserAndYear: !isNaN(parseInt(value))
                ? +value
                : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="percentageVerySatisfiedUsers">
            {t('System.It.PercentageVerySatisfiedUsers.Title')}
          </Label>

          <HelpIcon
            title={t('System.It.PercentageVerySatisfiedUsers.Title')}
            text={t('System.It.PercentageVerySatisfiedUsers.Description')}
          />
        </div>

        <Select
          name="percentageVerySatisfiedUsers"
          id="percentageVerySatisfiedUsers"
          value={dataIt.percentageVerySatisfiedUsers ?? undefined}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              percentageVerySatisfiedUsers: e.currentTarget.value
                ? (e.currentTarget.value as PercentageVerySatisfiedUsers)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(PercentageVerySatisfiedUsers).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.PercentageVerySatisfiedUsers.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div style={{ width: '100%' }}>
        <div>
          <Label htmlFor="noOfIncidentsLastYear">
            {t('System.It.NoOfIncidentsLastYear.Title')}
          </Label>

          <HelpIcon
            title={t('System.It.NoOfIncidentsLastYear.Title')}
            text={t('System.It.NoOfIncidentsLastYear.Description')}
          />
        </div>

        <InputNumber
          id="noOfIncidentsLastYear"
          name="noOfIncidentsLastYear"
          value={dataIt?.noOfIncidentsLastYear ?? ''}
          onValueChange={({ value }) => {
            setDataIt({
              ...dataIt,
              noOfIncidentsLastYear: !isNaN(parseInt(value))
                ? +value
                : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="noOfUpgradesLast3Years">
            {t('System.It.NoOfUpgradesLast3Years.Title')}
          </Label>
        </div>

        <InputNumber
          id="noOfUpgradesLast3Years"
          name="noOfUpgradesLast3Years"
          value={dataIt?.noOfUpgradesLast3Years ?? ''}
          onValueChange={({ value }) => {
            setDataIt({
              ...dataIt,
              noOfUpgradesLast3Years: !isNaN(parseInt(value))
                ? +value
                : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="noOfIntegrations">
            {t('System.It.NoOfIntegrations.Title')}
          </Label>

          <HelpIcon
            title={t('System.It.NoOfIntegrations.Title')}
            text={t('System.It.NoOfIntegrations.Description')}
          />
        </div>

        <InputNumber
          id="noOfIntegrations"
          name="noOfIntegrations"
          value={dataIt?.noOfIntegrations ?? ''}
          onValueChange={({ value }) => {
            setDataIt({
              ...dataIt,
              noOfIntegrations: !isNaN(parseInt(value)) ? +value : undefined,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div>
          <Label htmlFor="hasSAD">{t('System.It.HasSAD.Title')}</Label>

          <HelpIcon
            title={t('System.It.HasSAD.Title')}
            text={t('System.It.HasSAD.Description')}
          />
        </div>

        <Select
          name="hasSAD"
          id="hasSAD"
          value={
            dataIt?.hasSAD === true
              ? YesNo.Yes
              : dataIt?.hasSAD === false
              ? YesNo.No
              : undefined
          }
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              hasSAD:
                e.currentTarget.value && e.currentTarget.value === YesNo.Yes
                  ? true
                  : e.currentTarget.value && e.currentTarget.value === YesNo.No
                  ? false
                  : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value={`${null}`}>- {t('Common.UI.Select')}</option>
          {Object.keys(YesNo).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.HasSAD.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="hasControlOverData">
            {t('System.It.HasControlOverData.Title')}
          </Label>
        </div>

        <Select
          name="hasControlOverData"
          id="hasControlOverData"
          value={
            dataIt?.hasControlOverData === true
              ? YesNo.Yes
              : dataIt?.hasControlOverData === false
              ? YesNo.No
              : undefined
          }
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              hasControlOverData:
                e.currentTarget.value && e.currentTarget.value === YesNo.Yes
                  ? true
                  : e.currentTarget.value && e.currentTarget.value === YesNo.No
                  ? false
                  : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value={`${null}`}>- {t('Common.UI.Select')}</option>
          {Object.keys(YesNo).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.HasControlOverData.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="hasOpenApiSupport">
            {t('System.It.HasOpenApiSupport.Title')}
          </Label>
        </div>

        <Select
          name="hasOpenApiSupport"
          id="hasOpenApiSupport"
          value={dataIt?.hasOpenApiSupport ?? undefined}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              hasOpenApiSupport: e.currentTarget.value
                ? (e.currentTarget.value as HasOpenApiSupport)
                : undefined,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(HasOpenApiSupport).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.HasOpenApiSupport.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="isPartOfDataShared">
            {t('System.It.IsPartOfDataShared.Title')}
          </Label>
        </div>

        <Select
          name="isPartOfDataShared"
          id="isPartOfDataShared"
          value={
            dataIt?.isPartOfDataShared === true
              ? YesNo.Yes
              : dataIt?.isPartOfDataShared === false
              ? YesNo.No
              : undefined
          }
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              isPartOfDataShared:
                e.currentTarget.value && e.currentTarget.value === YesNo.Yes
                  ? true
                  : e.currentTarget.value && e.currentTarget.value === YesNo.No
                  ? false
                  : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value={`${null}`}>- {t('Common.UI.Select')}</option>
          {Object.keys(YesNo).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.IsPartOfDataShared.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <div>
          <Label htmlFor="lifeCyclePhase">
            {t('System.It.LifeCyclePhase.Title')}
          </Label>
        </div>

        <Select
          name="lifeCyclePhase"
          id="lifeCyclePhase"
          value={dataIt?.lifeCyclePhase ?? undefined}
          onChange={(e) => {
            setIsYearOfDecommissionShown(
              (e.currentTarget.value as LifeCyclePhase) ===
                LifeCyclePhase.Decommissioned
            );
            setDataIt({
              ...dataIt,
              lifeCyclePhase: e.currentTarget.value
                ? (e.currentTarget.value as LifeCyclePhase)
                : null,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(LifeCyclePhase).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.LifeCyclePhase.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      {isYearOfDecommissionShown && (
        <div>
          <div>
            <Label htmlFor="yearOfDecommission">
              {t('System.It.YearOfDecommission.Title')}
            </Label>

            <HelpIcon
              title={t('System.It.YearOfDecommission.Title')}
              text={t('System.It.YearOfDecommission.Description')}
            />
          </div>

          <YearPicker
            name="yearOfDecommission"
            date={dataIt?.yearOfDecommission?.toString()}
            setDate={(date: Date | null) =>
              setDataIt(
                dataIt
                  ? {
                      ...dataIt,
                      yearOfDecommission: date?.getFullYear(),
                    }
                  : dataIt
              )
            }
            disabled={!canEdit}
          />
        </div>
      )}

      <div>
        <div>
          <Label htmlFor="risk">{t('System.It.Risk.Title')}</Label>
          <HelpIcon
            title={t('System.It.Risk.Title')}
            text={<Trans i18nKey="System.It.Risk.Description" />}
          />
        </div>

        <Select
          name="risk"
          id="risk"
          value={dataIt?.risk ?? undefined}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              risk: e.currentTarget.value
                ? (e.currentTarget.value as Risk)
                : undefined,
            });
          }}
          disabled={!canEdit}
        >
          <option value="">- {t('Common.UI.Select')}</option>
          {Object.keys(Risk).map((value) => (
            <option key={value} value={value}>
              {t(`System.It.Risk.Values.${value}`)}
            </option>
          ))}
        </Select>
      </div>

      <div style={{ flexDirection: 'column' }}>
        <div>
          <Label htmlFor="externalConnections">
            {t('System.It.ExternalConnections.Title')}
          </Label>
        </div>

        <TextArea
          id="externalConnections"
          name="externalConnections"
          value={dataIt.externalConnections ?? ''}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              externalConnections: e.currentTarget.value,
            });
          }}
          disabled={!canEdit}
        />
      </div>

      <div style={{ flexDirection: 'column' }}>
        <div>
          <Label htmlFor="notes">{t('System.Shared.Notes.Title')}</Label>
        </div>

        <TextArea
          id="notes"
          name="notes"
          value={dataIt.notes ?? ''}
          onChange={(e) => {
            setDataIt({
              ...dataIt,
              notes: e.currentTarget.value,
            });
          }}
          disabled={!canEdit}
        />
      </div>
    </div>
  ) : null;
};

ItEdit.displayName = 'ItEdit';
