import { Gauge } from '@dimatech/features-core/lib/components/Gauge';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CurrencyIcon } from '@dimatech/shared/lib/components/CurrencyIcon';
import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency, formatAsPercent } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/diosSlice';
import { useGetSystemsProgressQuery } from 'api/statistics/statisticsApi';
import { useAppSelector } from 'hooks';
import { PortfolioValue, Turnover } from 'models/statistics';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsPiggyBank, BsSpeedometer } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const StatisticsKpi = withTheme(
  ({
    theme,
    turnover,
    portfolioValueData,
  }: {
    theme: Theme;
    turnover?: Turnover;
    portfolioValueData?: PortfolioValue;
  }): JSX.Element => {
    const { t } = useTranslation();
    const systemsFilter = useAppSelector(selectFilter);
    const { accessToken } = useContext(AuthenticationContext);

    const [portfolioValue, setPortfolioValue] = useState<number | undefined>();
    const [savingPotential, setSavingPotential] = useState<
      number | undefined
    >();
    const [operationalValue, setOperationalValue] = useState<
      number | undefined
    >();

    const { data: progress } = useGetSystemsProgressQuery(
      systemsFilter.instance && accessToken.customerId
        ? {
            filter: systemsFilter,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

    useEffect(() => {
      setPortfolioValue(
        (portfolioValueData?.portfolioValue?.developmentBudget || 0) +
          (portfolioValueData?.portfolioValue?.operationalBudget || 0)
      );

      setSavingPotential(
        (portfolioValueData?.potentialSavings?.developmentBudget || 0) +
          (portfolioValueData?.potentialSavings?.operationalBudget || 0)
      );

      setOperationalValue(
        portfolioValueData?.portfolioValue?.operationalBudget || 0
      );
    }, [portfolioValueData]);

    return (
      <Style>
        <Gauge
          value={
            (portfolioValue ? (operationalValue || 0) / portfolioValue : 0) *
            100
          }
          defaultValue="-"
          name={t('Overview.Statistics.DriftOfTotal.Title')}
          text={<Trans i18nKey="Overview.Statistics.DriftOfTotal.Help" />}
        />

        <Number
          icon={<BsPiggyBank />}
          color={theme.colors.primary}
          text={t('Overview.Statistics.PotentialSavings.Title', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          help={t('Overview.Statistics.PotentialSavings.Help')}
          formattedValue={formatAsCurrency(savingPotential)}
        />

        <Number
          style={{ paddingLeft: 30 }}
          icon={<BsSpeedometer />}
          color={theme.colors.primary}
          text={t('Overview.Statistics.Turnover.Title')}
          help={t('Overview.Statistics.Turnover.Help')}
          formattedValue={formatAsPercent((turnover?.value || 0) * 100, '0')}
        />

        <Number
          icon={<CurrencyIcon currency={accessToken.locale.currency} />}
          color={theme.colors.primary}
          text={t('Overview.Statistics.PortfolioValue.Title', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          help={t('Overview.Statistics.PortfolioValue.Help')}
          formattedValue={formatAsCurrency(portfolioValue)}
        />

        <Gauge
          value={(progress?.answeredIt ?? 0) * 100}
          defaultValue="-"
          color={theme.colors.secondary}
          name={t('Overview.Statistics.StatusIt.Title')}
          text={t('Overview.Statistics.StatusIt.Help')}
        />

        <Gauge
          value={(progress?.answeredBusiness ?? 0) * 100}
          defaultValue="-"
          color={theme.colors.secondary}
          name={t('Overview.Statistics.StatusBusiness.Title')}
          text={t('Overview.Statistics.StatusBusiness.Help')}
        />
      </Style>
    );
  }
);

StatisticsKpi.displayName = 'StatisticsKpi';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};
  margin-bottom: 20px;

  > div {
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
