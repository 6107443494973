import { PrivateSection } from '@dimatech/features-core/lib/components/PrivateSection';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparator,
} from '@dimatech/shared/lib/components/workspace';
import { config } from '@dimatech/shared/lib/config';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color } from '@dimatech/shared/lib/themes';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsApp,
  BsBarChart,
  BsCameraVideo,
  BsCardList,
  BsChat,
  BsDiagram3,
  BsDiagram3Fill,
  BsEnvelopeFill,
  BsFileExcel,
  BsFileText,
  BsGear,
  BsGlobe,
  BsLightbulb,
  BsPeople,
  BsPlay,
  BsQuestionCircle,
  BsServer,
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { CommonRoleSets } from 'utils';

export const Navigation = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <PrivateSection
        requireRole={[
          ...CommonRoleSets.AnyAdmin,
          ...CommonRoleSets.AnyExternal,
          ...CommonRoleSets.AnyReader,
        ]}
      >
        <NavigationItem
          $selected={
            location.pathname === '/overview' ||
            (location.pathname === '/' &&
              !accessToken.isInRole(CommonRoles.Reader))
          }
        >
          <BsBarChart />
          <Link to="/overview">{t('Navigation.Overview')}</Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[CommonRoles.GlobalAdmin, CommonRoles.DiosRespondent]}
      >
        <NavigationItem
          $selected={
            location.pathname === '/my-systems' ||
            (location.pathname === '/' &&
              accessToken.isInRole(CommonRoles.DiosRespondent) &&
              !accessToken.isInRole([
                CommonRoles.CustomerAccountAdmin,
                CommonRoles.CustomerAdmin,
              ]))
          }
        >
          <BsServer />
          <Link to="/my-systems">{t('Navigation.MySystems')}</Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[
          ...CommonRoleSets.AnyAdmin,
          ...CommonRoleSets.AnyExternal,
          ...CommonRoleSets.AnyReader,
        ]}
      >
        <NavigationItem $selected={location.pathname === '/systems'}>
          <BsServer />
          <Link to="/systems">{t('Navigation.Systems')}</Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[
          ...CommonRoleSets.AnyAccountAdmin,
          ...CommonRoleSets.AnyExternal,
          ...CommonRoleSets.AnyReader,
        ]}
        requireFlag={flags.permanent.app.dios.isSystemConnectionsEnabled}
      >
        <NavigationItem $selected={location.pathname === '/systems-map'}>
          <BsDiagram3Fill />
          <Link to="/systems-map">{t('Navigation.SystemsMap')}</Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[
          ...CommonRoleSets.AnyAdmin,
          ...CommonRoleSets.AnyExternal,
          ...CommonRoleSets.AnyReader,
        ]}
      >
        <NavigationItem $selected={location.pathname === '/analytics'}>
          <BsLightbulb />
          <Link to="/analytics">{t('Navigation.Analytics')}</Link>
        </NavigationItem>

        <NavigationItem $selected={location.pathname === '/national-result'}>
          <BsGlobe />
          <Link to="/national-result">{t('Navigation.NationalResult')}</Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[
          ...CommonRoleSets.AnyAdmin,
          ...CommonRoleSets.AnyExternal,
        ]}
      >
        <NavigationSeparator />

        <NavigationHeader>
          {t('Navigation.Administrate.Administrate')}
        </NavigationHeader>

        <NavigationItem
          $selected={location.pathname === '/administrate/organisation'}
        >
          <BsDiagram3 />
          <Link to="/administrate/organisation">
            {t('Navigation.Administrate.Organisation')}
          </Link>
        </NavigationItem>

        <NavigationItem
          $selected={location.pathname === '/administrate/system'}
        >
          <BsServer />
          <Link to="/administrate/system">
            {t('Navigation.Administrate.System')}
          </Link>
        </NavigationItem>

        <NavigationItem
          $selected={location.pathname === '/administrate/schedule'}
        >
          <BsPlay />
          <Link to="/administrate/schedule">
            {t('Navigation.Administrate.Schedule')}
          </Link>
        </NavigationItem>

        <NavigationItem
          $selected={location.pathname === '/administrate/message-template'}
        >
          <BsEnvelopeFill />
          <Link to="/administrate/message-template">
            {t('Navigation.Administrate.MessageTemplates')}
          </Link>
        </NavigationItem>

        <NavigationItem
          $selected={
            location.pathname === '/administrate/tags' ||
            location.pathname === '/administrate/custom-tags' ||
            location.pathname === '/administrate/custom-system-relation-tags'
          }
        >
          <BsCardList />
          <Link to="/administrate/custom-tags">
            {t('Navigation.Administrate.CustomDimensions')}
          </Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[
          ...CommonRoleSets.AnyAccountAdmin,
          ...CommonRoleSets.AnyExternal,
        ]}
      >
        <NavigationItem $selected={location.pathname === '/administrate/users'}>
          <BsPeople />
          <Link to="/administrate/users">
            {t('Navigation.Administrate.Users')}
          </Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={CommonRoleSets.AnyAccountAdmin}
        requireFlag={flags.permanent.shared.isUserMessagesForCustomersEnabled}
      >
        <NavigationItem
          $selected={location.pathname === '/administrate/messages'}
        >
          <BsChat />
          <Link to="/administrate/messages">
            {t('Navigation.Administrate.Messages')}
          </Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection requireRole={CommonRoleSets.AnyAccountAdmin}>
        <NavigationItem
          $selected={location.pathname === '/administrate/account'}
        >
          <BsGear />
          <Link to="/administrate/account">
            {t('Navigation.Administrate.Account')}
          </Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection requireRole={[CommonRoles.GlobalAdmin]}>
        <NavigationSeparator />

        <NavigationHeader>
          {t('Navigation.GlobalAdministrate.GlobalAdministrate')}
        </NavigationHeader>

        <NavigationItem
          $selected={location.pathname === '/global-administrate/reports'}
        >
          <BsFileExcel />
          <Link to="/global-administrate/reports">
            {t('Navigation.GlobalAdministrate.Reports')}
          </Link>
        </NavigationItem>
      </PrivateSection>

      <PrivateSection
        requireRole={[CommonRoles.Researcher]}
        forbidRole={[CommonRoles.GlobalAdmin]}
      >
        <NavigationSeparator />

        <NavigationHeader>
          {t('Navigation.Researcher.Researcher')}
        </NavigationHeader>

        <NavigationItem
          $selected={location.pathname === '/global-administrate/reports'}
        >
          <BsFileExcel />
          <Link to="/global-administrate/reports">
            {t('Navigation.Researcher.Reports')}
          </Link>
        </NavigationItem>
      </PrivateSection>

      <NavigationSeparator />

      <NavigationItem $selected={location.pathname === '/help'}>
        <BsQuestionCircle />
        <Link to="/help">{t('Navigation.Help')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/video-library'}>
        <BsCameraVideo />
        <Link to="/video-library">{t('Navigation.VideoLibrary')}</Link>
      </NavigationItem>

      <NavigationItem
        $selected={location.pathname.startsWith('/release-notes')}
      >
        <BsFileText />
        <Link to="/release-notes">{t('Navigation.ReleaseNotes')}</Link>
      </NavigationItem>

      <NavigationSeparator />

      <NavigationItem>
        <BsApp style={{ color: Color.DimiosBlue }} />
        <ProductLink
          productId={config.products.dimios.id as string}
          productUrl={config.products.dimios.url}
          productName={t('Account.Dimios.Name')}
          orderRoute="/order-dimios"
        />
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.RadicalRed }} />
        <ProductLink
          productId={config.products.pios.id as string}
          productUrl={config.products.pios.url}
          productName={t('Account.Pios.Name')}
          orderRoute="/order-pios"
        />
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.Amethyst }} />
        <ProductLink
          productId={config.products.dikios.id as string}
          productUrl={config.products.dikios.url}
          productName={t('Account.Dikios.Name')}
          orderRoute="/order-dikios"
        />
      </NavigationItem>

      <PrivateSection requireRole={[CommonRoles.GlobalAdmin]}>
        <NavigationItem>
          <BsApp style={{ color: Color.DimatechBlue }} />
          <a href={config.products.admin.url}>{t('Account.Admin.Name')}</a>
        </NavigationItem>
      </PrivateSection>
    </>
  );
};

Navigation.displayName = 'Navigation';

const ProductLink = ({
  productId,
  productUrl,
  productName,
  orderRoute,
}: {
  productId: string;
  productUrl: string;
  productName: string;
  orderRoute: string;
}) => {
  const { accessToken } = useContext(AuthenticationContext);

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isDemoUser = accessToken.isInRole(CommonRoles.DemoUser);

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));
  const isAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAdmin));

  const customerHasAccess =
    (isCustomerAccountAdmin || isAdmin || isDemoUser || isResearcher) &&
    accessToken.hasCustomerProductAccess(productId);

  if (isGlobalAdmin) {
    return <a href={productUrl}>{productName}</a>;
  }

  if (customerHasAccess && accessToken.hasUserProductAccess(productId)) {
    return <a href={productUrl}>{productName}</a>;
  }

  if (isAdmin || isCustomerAccountAdmin) {
    return <Link to={orderRoute}>{productName}</Link>;
  }

  return <a href={`${productUrl}/register`}>{productName}</a>;
};
