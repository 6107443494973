import { SystemHistory as SystemHistoryFeature } from 'features/systems/SystemHistory';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const SystemHistory = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Systems.Title')}>
      <SystemHistoryFeature />
    </View>
  );
};

SystemHistory.displayName = 'SystemHistory';
