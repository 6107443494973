import { SelectEntityWithoutSurvey } from '@dimatech/features-core/lib/components/SelectEntityWithoutSurvey';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  useLazyGetSystemsQuery,
  useMoveSystemMutation,
} from 'api/system/systemApi';
import { systemActions } from 'api/system/systemSlice';
import { useAppDispatch } from 'hooks';
import { Entity, SortDirection, System } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowsMove } from 'react-icons/bs';

export const SystemActionMove = ({
  system,
  showListButton = false,
}: {
  system: System;
  showListButton?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [moveSystem] = useMoveSystemMutation();
  const [getSystems] = useLazyGetSystemsQuery();

  const [isMoving, setIsMoving] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [entityId, setEntityId] = useState<string>('');
  const [validationError, setValidationError] = useState('');

  const handleMove = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    entityId &&
      accessToken.customerId &&
      getSystems(
        {
          filter: {
            paginator: {
              page: 1,
              pageSize: 10000,
              orderBy: 'name',
              orderDirection: SortDirection.Asc,
            },
            searchTerm: '',
            entityIds: [entityId],
          },
          _customerId: accessToken.customerId,
        },
        true
      )
        .unwrap()
        .then((result) => {
          const searchSystem = result?.records.find(
            (s) => s.name === system.name
          );

          if (searchSystem) {
            setValidationError('Duplicate');
            return;
          }

          if (system.id && entityId) {
            moveSystem({
              systemId: system.id,
              entityId,
              copy: isCopying,
            })
              .unwrap()
              .then(() => {
                setIsMoving(false);
                setEntityId('');
                dispatch(systemActions.selectedSystem());
              });
          }
        });
  };

  const handleConfirmMove = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setValidationError('');

    setEntityId('');

    setIsMoving(show);
  };

  useEffect(() => {
    setValidationError('');
  }, [entityId]);

  return (
    <>
      {isMoving && (
        <Modal
          title={t('Administrate.System.MoveOrCopy.Title')}
          handleKeyEnter={handleMove}
          handleKeyEsc={(e) => handleConfirmMove(e, false)}
        >
          <div>{t('Administrate.System.MoveOrCopy.Text')}</div>

          {validationError && (
            <AlertWarning style={{ marginTop: 15 }}>
              {t(`Administrate.System.ValidationError.${validationError}`)}
            </AlertWarning>
          )}

          <SelectEntityWithoutSurvey
            entityId={entityId}
            setEntityId={setEntityId}
            placeholder={t('Administrate.System.MoveOrCopy.SelectEntity')}
            filter={(e: Entity) => e.id !== entityId && !e.isReadOnly}
          />

          <div>
            <Label htmlFor="copy">
              <Checkbox
                name="copy"
                id="copy"
                checked={isCopying}
                onChange={(e) => setIsCopying(e.currentTarget.checked)}
                style={{ marginRight: 10 }}
              />
              {t('Administrate.System.MoveOrCopy.Copy')}
            </Label>
          </div>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmMove(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={handleMove}
              disabled={
                (system.entityId === entityId && !isCopying) ||
                !entityId ||
                isReadOnly
              }
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {!showListButton && (
        <ButtonSecondary
          type="button"
          onClick={(e) => handleConfirmMove(e, true)}
        >
          {t('Administrate.System.MoveOrCopy.Move')}
        </ButtonSecondary>
      )}

      {showListButton && (
        <BadgeMini
          tooltip={t('Administrate.System.MoveOrCopy.Move')}
          onClick={(e) => handleConfirmMove(e, true)}
        >
          <BsArrowsMove />
        </BadgeMini>
      )}
    </>
  );
};

SystemActionMove.displayName = 'SystemActionMove';
