import { Buttons, ButtonSecondary } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Table, Td, Th, Tr } from '@dimatech/shared/lib/components/table';
import { formatDate } from '@dimatech/shared/lib/utils';
import { Respondent } from 'models';
import { useTranslation } from 'react-i18next';
import { RespondentMessageStatus } from './RespondentMessageStatus';

export const RespondentMessageHistory = ({
  respondent,
  setIsShowingMessagesForRespondent,
  systemName,
}: {
  respondent: Respondent;
  setIsShowingMessagesForRespondent: (respondent: Respondent | null) => void;
  systemName: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Modal
      style={{ width: '70%', maxWidth: 800, minWidth: 300 }}
      title={t('Administrate.System.Respondent.Messages.Title')}
      handleKeyEsc={() => setIsShowingMessagesForRespondent(null)}
    >
      <div>
        {t('Administrate.System.Respondent.Messages.Text', {
          email: respondent.email,
          systemName,
        })}
      </div>

      <Table style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <Th>{t('Administrate.System.Respondent.Messages.Status')}</Th>
            <Th>{t('Administrate.System.Respondent.Messages.DateSent')}</Th>
            <Th>{t('Administrate.System.Respondent.Messages.Error')}</Th>
          </tr>
        </thead>
        <tbody>
          {respondent.messages?.map((message, index) => (
            <Tr key={index}>
              <Td>
                <RespondentMessageStatus status={message.status} />
              </Td>
              <Td>
                {message.sentDate && formatDate(new Date(message.sentDate))}
              </Td>
              <Td>{message.error ? message.error : '-'}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>

      <Buttons>
        <ButtonSecondary
          type="button"
          onClick={() => setIsShowingMessagesForRespondent(null)}
        >
          {t('Common.UI.Ok')}
        </ButtonSecondary>
      </Buttons>
    </Modal>
  );
};

RespondentMessageHistory.displayName = 'RespondentMessageHistory';
