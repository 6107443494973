import { Gauge } from '@dimatech/features-core/lib/components/Gauge';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CurrencyIcon } from '@dimatech/shared/lib/components/CurrencyIcon';
import { Number } from '@dimatech/shared/lib/components/Number';
import {
  ViewRow,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency, formatAsNumber } from '@dimatech/shared/lib/utils';
import { PortfolioValue, Turnover } from 'models/statistics';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsPiggyBank, BsSpeedometer } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const Statistics = withTheme(
  ({
    theme,
    portfolioValueData,
    turnover,
  }: {
    theme: Theme;
    portfolioValueData?: PortfolioValue;
    turnover?: Turnover;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const [portfolioValue, setPortfolioValue] = useState<number | undefined>();
    const [savingPotential, setSavingPotential] = useState<
      number | undefined
    >();
    const [operationalValue, setOperationalValue] = useState<
      number | undefined
    >();

    useEffect(() => {
      setPortfolioValue(
        (portfolioValueData?.portfolioValue?.developmentBudget || 0) +
          (portfolioValueData?.portfolioValue?.operationalBudget || 0)
      );

      setSavingPotential(
        (portfolioValueData?.potentialSavings?.developmentBudget || 0) +
          (portfolioValueData?.potentialSavings?.operationalBudget || 0)
      );

      setOperationalValue(
        portfolioValueData?.portfolioValue?.operationalBudget || 0
      );
    }, [portfolioValueData]);

    return (
      <Style>
        <Gauge
          value={
            (portfolioValue ? (operationalValue || 0) / portfolioValue : 0) *
            100
          }
          defaultValue="-"
          name={t('NationalResult.Statistics.DriftOfTotal.Title')}
          text={<Trans i18nKey="NationalResult.Statistics.DriftOfTotal.Help" />}
        />

        <Number
          icon={<BsPiggyBank />}
          color={theme.colors.primary}
          text={t('NationalResult.Statistics.PotentialSavings.Title', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          help={t('NationalResult.Statistics.PotentialSavings.Help')}
          formattedValue={formatAsCurrency(savingPotential)}
        />
        <ViewRowBreak />

        <Number
          style={{ paddingLeft: 30 }}
          icon={<BsSpeedometer />}
          color={theme.colors.primary}
          text={t('NationalResult.Statistics.Turnover.Title')}
          help={t('NationalResult.Statistics.Turnover.Help')}
          formattedValue={formatAsNumber(turnover?.value || 0, '0')}
        />

        <Number
          icon={<CurrencyIcon currency={accessToken.locale.currency} />}
          color={theme.colors.primary}
          text={t('NationalResult.Statistics.PortfolioValue.Title', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          help={t('NationalResult.Statistics.PortfolioValue.Help')}
          formattedValue={formatAsCurrency(portfolioValue)}
        />
      </Style>
    );
  }
);

Statistics.displayName = 'Statistics';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};
  margin-bottom: 20px;

  > div {
    align-content: center;
    min-width: 35%;
    margin-right: 15px;
  }
`;
