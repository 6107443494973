import { SearchAdministrateUsersRole } from '@dimatech/features-core/lib/models/user';
import { Message as MessageFeature } from 'features/administrate-messages';
import { CommonRoles } from 'models';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Message = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.Messages.Title')}>
      <MessageFeature
        searchableRoles={[
          SearchAdministrateUsersRole.Read,
          SearchAdministrateUsersRole.Write,
          CommonRoles.DiosRespondent,
        ]}
        isEntityFilterShown={false}
      />
    </View>
  );
};

Message.displayName = 'Message';
