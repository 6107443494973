import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CurrencyIcon } from '@dimatech/shared/lib/components/CurrencyIcon';
import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  formatAsCurrency,
  formatAsNumber,
  formatAsPercent,
} from '@dimatech/shared/lib/utils';
import { System, SystemPaginatedResult } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsExclamationOctagon, BsServer } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const Statistics = withTheme(
  ({
    theme,
    systems,
  }: {
    theme: Theme;
    systems: SystemPaginatedResult<System> | undefined;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    return (
      <Style>
        <Number
          icon={<BsServer />}
          color={theme.colors.primary}
          text={t('Analytics.Statistics.NoOfSystems.Title')}
          formattedValue={formatAsNumber(systems?.totalRecords, '0')}
        />

        <Number
          style={{ paddingLeft: 30 }}
          icon={<CurrencyIcon currency={accessToken.locale.currency} />}
          color={theme.colors.primary}
          text={t('Analytics.Statistics.DriftBudget.Title', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          formattedValue={formatAsCurrency(systems?.operationalBudget)}
        />

        <Number
          icon={<CurrencyIcon currency={accessToken.locale.currency} />}
          color={theme.colors.primary}
          text={t('Analytics.Statistics.DevelopmentBudget.Title', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          formattedValue={formatAsCurrency(systems?.developmentBudget)}
        />

        <Number
          icon={<BsExclamationOctagon />}
          color={theme.colors.primary}
          text={t('Analytics.Statistics.AverageCriticality.Title')}
          formattedValue={formatAsPercent(systems?.averageCriticality, '0')}
        />
      </Style>
    );
  }
);

Statistics.displayName = 'Statistics';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  > div {
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
