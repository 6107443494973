import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Loader } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Paginator, SortDirection } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import { useLazyGetFailedMessagesQuery } from 'api/system/systemApi';
import { SystemFailedMessage } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory } from 'react-icons/bs';
import { RespondentMessageHistory } from './RespondentMessageHistory';
import { RespondentMessageStatus } from './RespondentMessageStatus';

export const RespondentsFailedMessagesList = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const customerId = accessToken.customerId;

  const [isShowingMessagesForRespondent, setIsShowingMessagesForRespondent] =
    useState<SystemFailedMessage | null>(null);
  const [respondents, setRespondents] = useState<SystemFailedMessage[]>([]);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 50,
    orderBy: 'email',
    orderDirection: SortDirection.Asc,
  };

  const [getFailedMessages, { data, isFetching }] =
    useLazyGetFailedMessagesQuery();

  const handleGetFailedMessages = (paginator: Paginator = initialPaginator) => {
    if (accessToken.customerId) {
      getFailedMessages(
        {
          paginator,
          _customerId: accessToken.customerId,
        },
        true
      );
    }
  };

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: handleGetFailedMessages,
  });

  useEffect(() => {
    handleGetFailedMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    setRespondents(data?.records ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      {respondents.length === 0 && (
        <div style={{ marginTop: 20 }}>
          {t('Administrate.System.Respondent.FailedMessages.NoFailedMessages')}
        </div>
      )}

      {isShowingMessagesForRespondent && (
        <RespondentMessageHistory
          systemName={isShowingMessagesForRespondent.customerSystemName}
          respondent={isShowingMessagesForRespondent}
          setIsShowingMessagesForRespondent={(respondent) =>
            setIsShowingMessagesForRespondent(
              respondent ? isShowingMessagesForRespondent : null
            )
          }
        />
      )}

      <TooltipContent
        id="tt-message-history"
        title={t('Administrate.System.Respondent.Messages.Title')}
        text={t('Administrate.System.Respondent.Messages.Tooltip')}
      />

      <TooltipContent
        id="tt-link-copy"
        title={t('Administrate.System.Respondent.CopyLink.TooltipTitle')}
        text={t('Administrate.System.Respondent.CopyLink.Tooltip')}
      />

      <Pagination
        currentPage={paginator.page}
        totalCount={respondents.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />

      {respondents && respondents.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <Th sortKey="email" sorter={sorter}>
                  {t('Administrate.System.Respondent.Respondent')}
                </Th>
                <Th sortKey="customerSystemName" sorter={sorter}>
                  {t('Administrate.System.Respondent.System')}
                </Th>
                <Th>{t('Administrate.System.Respondent.Messages.Title')}</Th>
                <Th />
                <Th />
              </tr>
            </thead>
            <tbody>
              {respondents.map((respondent, index) => {
                const latestMessage = [...respondent.messages].sort((a, b) =>
                  (a.sentDate as string) > (b.sentDate as string) ? -1 : 1
                )?.[0];

                return (
                  <Tr key={index}>
                    <Td
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {respondent.email}
                    </Td>
                    <Td
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {respondent.customerSystemName}
                    </Td>
                    <Td>
                      {latestMessage && (
                        <div
                          style={{
                            verticalAlign: 'middle',
                            display: 'flex',
                            alignItems: 'baseline',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <RespondentMessageStatus
                            status={latestMessage.status}
                            error={latestMessage.error}
                          />

                          <span style={{ marginRight: 10, marginLeft: 10 }}>
                            {latestMessage.sentDate &&
                              formatDate(new Date(latestMessage.sentDate))}
                          </span>
                          <BadgeMini
                            data-tip
                            data-for="tt-message-history"
                            onClick={() =>
                              setIsShowingMessagesForRespondent(respondent)
                            }
                          >
                            <BsClockHistory />
                          </BadgeMini>
                        </div>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={respondents.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />
    </>
  );
};

RespondentsFailedMessagesList.displayName = 'RespondentsFailedMessagesList';
