import { BadgeLarge } from '@dimatech/shared/lib/components/Badge';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { withTheme } from 'styled-components';

/* eslint-disable max-lines-per-function */

export const SystemListEditButtons = withTheme(
  ({
    systemId,
    handleSave,
    canSave,
    theme,
  }: {
    systemId: string;
    handleSave: () => void;
    canSave: boolean;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isHistoryEnabledFlagOn =
      useFlags()[flags.permanent.app.dios.isHistoryEnabled];

    const handleViewHistory = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      navigate(`/system/${systemId}/history`);
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            gap: 10,
            justifyContent: 'flex-end',
          }}
        >
          {isHistoryEnabledFlagOn && (
            <>
              <BadgeLarge
                tooltip={t('System.History')}
                onClick={(e) => handleViewHistory(e)}
                $inverted={true}
              >
                <BsClockHistory />
              </BadgeLarge>
            </>
          )}

          <BadgeLarge
            tooltip={t('Common.Form.Save')}
            onClick={() => {
              if (canSave) {
                handleSave();
              }
            }}
            $inverted={true}
            disabled={!canSave}
            style={
              canSave
                ? {
                    backgroundColor: theme.colors.secondary,
                    color: theme.colors.onSecondary,
                  }
                : undefined
            }
          >
            <FaSave />
          </BadgeLarge>
        </div>
      </>
    );
  }
);

SystemListEditButtons.displayName = 'SystemListEditButtons';
