import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useTranslation } from 'react-i18next';
import { RespondentsFailedMessagesList } from './components/RespondentsFailedMessagesList';

export const RespondentsFailedMessages = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <ViewHeader style={{ justifyContent: 'space-between', paddingRight: 15 }}>
        <Heading1>
          {t('Administrate.System.Respondent.FailedMessages.Title')}
        </Heading1>
      </ViewHeader>

      <CustomerAccountInfo />

      <Card innerStyle={{ display: 'table', width: '100%' }}>
        <CardBody>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.System.Respondent.FailedMessages.Text')}
          </div>

          <RespondentsFailedMessagesList />
        </CardBody>
      </Card>
    </>
  );
};

RespondentsFailedMessages.displayName = 'RespondentsFailedMessages';
