import { ConfirmEmail as ConfirmEmailFeature } from 'features/confirm-email';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const ConfirmEmail = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('ConfirmEmail.Title')}>
      <ConfirmEmailFeature />
    </ViewCentered>
  );
};

ConfirmEmail.displayName = 'ConfirmEmail';
