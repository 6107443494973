import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { System } from 'models';
import { RootState } from 'store';

export const initialState: {
  selectedSystem?: System;
} = {};

export const { reducer: systemReducer, actions: systemActions } = createSlice({
  name: 'system',
  initialState,
  reducers: {
    selectedSystem: (state, action: PayloadAction<System | undefined>) => {
      state.selectedSystem = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(appActions.resetApplication, () => initialState);
  },
});

export const selectSelectedSystem = (state: RootState): System | undefined =>
  state.system.selectedSystem;
