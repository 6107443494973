import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  ComboBoxList,
  ComboBoxListItem,
  Input,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { useLazySearchSystemsQuery } from 'api/system/systemApi';
import { UseComboboxStateChange, useCombobox } from 'downshift';
import { System } from 'models';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

export const SearchAllSystems = ({
  setSystem,
  setSearchValue,
  filter,
  isInvalid,
  style,
  autoFocus,
}: {
  setSystem: (system: System | undefined) => void;
  setSearchValue?: (value: string | null) => void;
  filter?: (systems?: System[]) => System[];
  isInvalid?: boolean;
  style?: CSSProperties;
  autoFocus?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [search, setSearch] = useState('');
  const [systems, setSystems] = useState<System[]>();
  const [debouncedSearch] = useDebounce(search, 300);

  const handleSearch = (searchTerm?: string) => {
    accessToken.customerId &&
      searchSystems(
        {
          searchTerm: searchTerm || '',
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const [searchSystems, { data, isFetching }] = useLazySearchSystemsQuery();

  useEffect(() => {
    handleSearch(debouncedSearch.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    setSystems(filter ? filter(data) : data ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: systems ?? [],
    itemToString: (system: System | null) => system?.name ?? '',
    onInputValueChange: (changes: UseComboboxStateChange<System>) => {
      const value = (changes.inputValue || '').trim();

      setSearch(value);
      setSearchValue && setSearchValue(value);
    },
    onSelectedItemChange: (changes: UseComboboxStateChange<System>) => {
      setSystem(changes.selectedItem ?? undefined);
    },
  });
  return (
    <div>
      <div {...getComboboxProps()}>
        <Input
          autoFocus={autoFocus}
          {...getInputProps()}
          placeholder={t('Search.SearchSystem')}
          style={style}
          invalid={isInvalid}
        />
      </div>

      <ComboBoxList {...getMenuProps()}>
        {isOpen && isFetching && (
          <ComboBoxListItem>
            <LoaderSmall />
          </ComboBoxListItem>
        )}
        {isOpen &&
          systems?.map((system, index) => (
            <ComboBoxListItem
              $highlighted={highlightedIndex === index}
              key={`${system.id}${index}`}
              {...getItemProps({ item: system, index })}
            >
              {system.name}
            </ComboBoxListItem>
          ))}
      </ComboBoxList>
    </div>
  );
};

SearchAllSystems.displayName = 'SearchAllSystems';
