import { System } from 'models';
import { SystemActionDelete } from './SystemActionDelete';
import { SystemActionMove } from './SystemActionMove';
import { SystemActionNavigateToHistory } from './SystemActionNavigateToHistory';
import { SystemActionNavigateToSystem } from './SystemActionNavigateToSystem';

export const SystemListActions = ({
  system,
}: {
  system: System;
}): JSX.Element => {
  return (
    <div style={{ display: 'flex', gap: 7 }}>
      <SystemActionMove system={system} showListButton={true} />
      <SystemActionNavigateToHistory system={system} />
      <SystemActionNavigateToSystem system={system} />
      <SystemActionDelete system={system} showListButton={true} />
    </div>
  );
};

SystemListActions.displayName = 'SystemListActions';
