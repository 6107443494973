import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Heatmap,
  HeatmapConfig,
} from '@dimatech/shared/lib/components/heatmap';
import { DataPlot } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency } from '@dimatech/shared/lib/utils';
import { DiosColors, SystemByAssessment } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import {
  mapCriticalityByAssessment,
  mapQualityByAssessment,
} from 'utils/calculations';

export const HeatmapSystems = withTheme(
  ({
    theme,
    systemsByAssessment,
  }: {
    theme: Theme;
    systemsByAssessment?: SystemByAssessment[];
  }): JSX.Element | null => {
    const { accessToken } = useContext(AuthenticationContext);

    const cellSize = 1;
    const cellSizePixels = window.innerWidth < 600 ? 25 : 32;

    const { t } = useTranslation();
    const [data, setData] = useState<DataPlot[]>();

    const formatterX = (value: number) =>
      `${((value % 4) / 4) * 100} % - ${
        (((value % 4) + cellSize) / 4) * 100
      } %`;
    const formatterY = (value: number) =>
      `${((value % 5) / 5) * 100} % - ${
        (((value % 5) + cellSize) / 5) * 100
      } %`;
    const formatterZ = (value: number) => formatAsCurrency(value) ?? '0';

    const config: HeatmapConfig = {
      gridSize: { x: 8, y: 10 },
      cellSize,
      cellSizePixels,
      calcX: (index: number) => index,
      calcY: (index: number) => index,
      colorFrom: theme.colors.surface,
      colorTo: theme.colors.primary,
      tooltip: {
        showDetails: false,
        labelZ: t('CommonTerms.Budget', {
          currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
        }),
        labelX: t('CommonTerms.Criticality'),
        labelY: t('CommonTerms.Quality'),
        formatterX,
        formatterY,
        formatterZ,
      },
    };

    useEffect(() => {
      if (systemsByAssessment) {
        const mapped = systemsByAssessment
          .filter(
            (system) =>
              system.assessment &&
              system.criticality &&
              system.quality &&
              (system.operationalBudget || system.developmentBudget)
          )
          .map((system) => {
            return {
              y: mapQualityByAssessment(system.assessment, system.quality),
              x: mapCriticalityByAssessment(
                system.assessment,
                system.criticality
              ),
              z:
                (system.developmentBudget || 0) +
                (system.operationalBudget || 0),
            } as DataPlot;
          });
        setData(mapped);
      }
    }, [systemsByAssessment]);

    return (
      <Style>
        <div>
          <div>
            <BsCircleFill style={{ color: DiosColors.develop }} />
            {t('CommonTerms.Develop')}
          </div>
          <div>
            <BsCircleFill style={{ color: DiosColors.uphold }} />
            {t('CommonTerms.Uphold')}
          </div>
        </div>

        <Heatmap data={data ?? []} config={config} />

        <div>
          <div>
            <BsCircleFill style={{ color: DiosColors.liquidate }} />
            {t('CommonTerms.Liquidate')}
          </div>
          <div>
            <BsCircleFill style={{ color: DiosColors.consolidate }} />
            {t('CommonTerms.Consolidate')}
          </div>
        </div>
      </Style>
    );
  }
);

HeatmapSystems.displayName = 'HeatmapSystems';

const Style = styled.div`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  > div:first-of-type {
    margin-left: 35px;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }
  }

  > div:last-of-type {
    margin-left: 35px;
    display: flex;
    justify-content: space-between;
    margin-top: -30px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  svg {
    margin-right: 5px;
  }
`;
