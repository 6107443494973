import { tagActions } from '@dimatech/features-core/lib/api/tag/tagSlice';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useAppDispatch } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RelationTags } from './RelationTags';
import { Tags } from './Tags';

enum TabIndexes {
  Tags = 0,
  RelationTags = 1,
}

export const AdministrateCustomDimensions = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('custom-system-relation-tags')
      ? TabIndexes.RelationTags
      : TabIndexes.Tags
  );

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    dispatch(tagActions.selectedTag());

    navigate(
      `/administrate/custom-${
        index === TabIndexes.RelationTags ? 'system-relation-tags' : 'tags'
      }`
    );
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.CustomDimension.Header')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.Tags}
          handleSelect={() => handleSelectTab(TabIndexes.Tags)}
          style={{ minWidth: 80 }}
        >
          {t('Administrate.Tag.Tags')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === TabIndexes.RelationTags}
          handleSelect={() => handleSelectTab(TabIndexes.RelationTags)}
          style={{ minWidth: 80 }}
        >
          {t('Administrate.CustomDimension.RelationTag.Title')}
        </Tab>
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === TabIndexes.Tags && <Tags />}

          {selectedTabIndex === TabIndexes.RelationTags && <RelationTags />}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateCustomDimensions.displayName = 'AdministrateCustomDimensions';
