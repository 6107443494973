import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  AlertError,
  AlertLowPrio,
} from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useAddReminderMutation,
  useGetRemindersQuery,
  useUpdateReminderMutation,
} from 'api/schedule/reminderApi';
import {
  useGetScheduleQuery,
  useUpdateScheduleMutation,
} from 'api/schedule/scheduleApi';
import { validateReminders } from 'features/administrate-schedule/validation';
import { MessageSchedule, Reminder } from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { isAdminReadOnly } from 'utils';
import { ScheduleDate } from './ScheduleDate';
import { ScheduleReminders } from './ScheduleReminders';

export const Schedule = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [schedule, setSchedule] = useState<MessageSchedule>();
  const [reminders, setReminders] = useState<Reminder[]>([]);

  const {
    data,
    isFetching: isFetchingSchedule,
    isLoading: isLoadingSchedule,
  } = useGetScheduleQuery(accessToken.customerId ?? skipToken);

  const {
    data: dataReminders,
    isFetching: isFetchingReminders,
    isLoading: isLoadingReminders,
  } = useGetRemindersQuery(accessToken.customerId ?? skipToken);

  const [updateSchedule, { error: updateScheduleError }] =
    useUpdateScheduleMutation();
  const [addReminder, { error: addReminderError }] = useAddReminderMutation();
  const [updateReminder, { error: updateReminderError }] =
    useUpdateReminderMutation();

  const [hasChanges, setHasChanges] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [isValidReminder, setIsValidReminder] = useState<boolean>(true);

  const handleCancel = () => {
    setReminders(dataReminders ? [...dataReminders] : []);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (!schedule) {
      return;
    }

    const startDate = date ? date.toISOString() : null;

    setSchedule({
      ...schedule,
      startDate,
    });

    if (!startDate) {
      return;
    }

    const validatedReminders = validateReminders(reminders, startDate);

    setIsValidReminder(
      !validatedReminders?.some((reminder) => reminder.isValid === false)
    );

    setHasChanges(true);
  };

  const handleSave = () => {
    if (isReadOnly || !isValidReminder) {
      return;
    }

    if (!hasChanges && !reminders.some((r) => r.isDirty)) {
      return;
    }

    reminders?.forEach((reminder) => {
      if (reminder.isDirty) {
        if (reminder.id) {
          updateReminder({
            ...reminder,
          });
        } else if (reminder.sendDate) {
          addReminder({
            ...reminder,
          });
        }
      }
    });

    if (hasChanges && schedule?.startDate) {
      updateSchedule({
        startDate: schedule.startDate,
      });
    }

    setHasChanges(false);
    setIsChangesSaved(true);
  };

  useEffect(() => {
    if (data) {
      setSchedule(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setReminders(dataReminders ? [...dataReminders] : []);
    setIsValidReminder(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReminders]);

  if (
    isFetchingSchedule ||
    isLoadingSchedule ||
    isFetchingReminders ||
    isLoadingReminders
  ) {
    return <Loader>{t('Common.UI.Loading')}</Loader>;
  }

  return (
    <>
      <Style>
        <div>
          {!isValidReminder && (
            <AlertError style={{ marginTop: 10 }}>
              {!isValidReminder &&
                t('Administrate.Schedule.ValidationErrors.InvalidReminder')}
            </AlertError>
          )}

          <AlertErrors
            error={
              addReminderError ?? updateReminderError ?? updateScheduleError
            }
            translationPath="Administrate.Schedule.ValidationErrors"
          />

          <DateStyle>
            <ScheduleDate
              hasChanges={hasChanges}
              date={schedule?.startDate ?? null}
              label={t('Administrate.Schedule.StartDate')}
              handleChange={(date) => {
                handleChangeStartDate(date);
              }}
              isReadOnly={isReadOnly}
            />

            <ScheduleReminders
              reminders={reminders}
              setReminders={setReminders}
              schedule={schedule}
              setSchedule={setSchedule}
              setIsValidReminder={setIsValidReminder}
            />
          </DateStyle>

          <ButtonFooterWithToast
            isSaved={isChangesSaved}
            setIsSaved={setIsChangesSaved}
            style={{ marginTop: 0, marginBottom: 20 }}
          >
            <Buttons>
              <ButtonSecondary type="button" onClick={handleCancel}>
                {t('Common.Form.Cancel')}
              </ButtonSecondary>

              <Button
                type="button"
                disabled={
                  isReadOnly ||
                  !(
                    (hasChanges || reminders.some((r) => r.isDirty)) &&
                    schedule?.startDate &&
                    isValidReminder
                  )
                }
                onClick={handleSave}
              >
                {t('Common.Form.Save')}
              </Button>
            </Buttons>
          </ButtonFooterWithToast>
        </div>

        <Help />
      </Style>
    </>
  );
};

Schedule.displayName = 'Schedule';

const DateStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div:first-child,
      label {
        width: 120px;
        padding-bottom: 3px;
      }
    }
  }
`;

const Style = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;

  > div:first-of-type {
    justify-content: flex-end;
    flex-grow: 1;
    flex-basis: 400px;
    padding: 0 50px 0 10px;
  }

  > div:last-of-type {
    flex-grow: 16;
    flex-basis: 250px;
    padding: 5px 30px 0 10px;
  }
`;

const Help = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <AlertLowPrio>
        <BsInfoCircleFill className="size-xl" style={{ marginRight: 8 }} />
        <Trans i18nKey="Help.Whitelisting" />
      </AlertLowPrio>
      <p>
        <Trans i18nKey="Administrate.Schedule.Help.General" />
      </p>
      <p>
        <Trans i18nKey="Help.TestMessage" />
      </p>
      <p>
        <strong>{t('Administrate.Schedule.StartDate')}</strong>
        <br />
        <Trans i18nKey="Administrate.Schedule.Help.StartDate" />
      </p>
      <p>
        <strong>{t('Administrate.Schedule.Reminder')}</strong>
        <br />
        <Trans i18nKey="Administrate.Schedule.Help.Reminders" />
      </p>
      <p>
        <Trans i18nKey="Administrate.Schedule.Help.RemindersTags" />
      </p>
    </div>
  );
};
