import { CacheTags, diosApi } from 'api/diosApi';
import { Tag, TagMutation } from 'models';

const relationTagApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get relation tags
     */
    getRelationTags: build.query<Tag[], string>({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: (_customerId) => 'tags/relation',
      transformResponse: (result: Tag[]) => {
        return result.sort((a, b) =>
          (a.displayName as string) > (b.displayName as string) ? 1 : -1
        );
      },
      providesTags: [CacheTags.RelationTag],
    }),

    /**
     * Create new relation tag
     */
    addRelationTag: build.mutation({
      query: (tag: TagMutation) => ({
        url: `tags/relation`,
        method: 'POST',
        body: tag,
      }),
      invalidatesTags: [CacheTags.RelationTag],
      transformResponse: (result: TagMutation, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Update relation tag
     */
    updateRelationTag: build.mutation({
      query: (tag: TagMutation) => ({
        url: `tags/relation/${tag.id}`,
        method: 'PUT',
        body: {
          displayName: tag.displayName,
          description: tag.description,
        },
      }),
      invalidatesTags: [CacheTags.RelationTag],
    }),

    /**
     * Delete relation tag
     */
    deleteRelationTag: build.mutation({
      query: (tag: TagMutation) => ({
        url: `tags/relation/${tag.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTags.RelationTag],
    }),

    /**
     * Move relation tag
     */
    moveRelationTag: build.mutation({
      query: ({ tagId, parentId }: { tagId: string; parentId?: string }) => ({
        url: `tags/relation/${tagId}/move/${parentId}`,
        method: 'PUT',
      }),
      invalidatesTags: [CacheTags.RelationTag],
      transformResponse: (_result, _meta, arg) => {
        return {
          id: arg.tagId,
          parentId: !arg.parentId ? null : arg.parentId,
        };
      },
    }),
  }),
});

export const {
  useGetRelationTagsQuery,
  useAddRelationTagMutation,
  useUpdateRelationTagMutation,
  useDeleteRelationTagMutation,
  useMoveRelationTagMutation,
} = relationTagApi;
