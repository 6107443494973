import { UserFilters } from '@dimatech/features-core/lib/components/UserFilters';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Impersonate,
  SendForgotPasswordEmail,
} from '@dimatech/features-core/lib/features/authentication/components';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Loader, LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin, ApiError, SortDirection } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import {
  getManagersExport,
  selectManagerExportIsPosting,
} from 'api/system/managerExportSlice';
import {
  useAddRespondentsMutation,
  useLazyGetRespondentsQuery,
  useRemoveUserRespondentsMutation,
} from 'api/system/systemManagerApi';
import { selectSelectedSystem, systemActions } from 'api/system/systemSlice';
import { Managers } from 'components/Managers';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import i18next from 'i18next';
import { RespondentType, SearchAdministrateUsers, System } from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsFileExcel,
  BsPersonBadge,
  BsPersonPlus,
  BsTrash,
} from 'react-icons/bs';
import { RespondentAdd } from './RespondentAdd';
import { SystemsListForUser } from './SystemsListForUser';

// eslint-disable-next-line max-lines-per-function
export const Respondents = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);
  const selectedSystem = useAppSelector(selectSelectedSystem);
  const isExportPosting = useAppSelector(selectManagerExportIsPosting);

  const [filter, setFilter] = useState<SearchAdministrateUsers>();
  const [selectedUser, setSelectedUser] = useState<Admin | undefined>();
  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();
  const [getRespondents, { data, isLoading }] = useLazyGetRespondentsQuery();

  const [addRespondents, { error }] = useAddRespondentsMutation();

  const handleGetRespondents = (entityIds?: string[], email?: string) => {
    accessToken.customerId &&
      getRespondents(
        {
          filter: {
            entityIds: entityIds || [],
            email: email || '',
          },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const { items, sorter } = useSortableData(data ?? [], {
    key: 'email',
    direction: SortDirection.Asc,
  });

  const handleAdd = (
    user: Admin,
    system: System,
    respondentType: RespondentType
  ) => {
    if (isReadOnly) {
      return;
    }
    setValidationErrors(undefined);

    if (!user || !user.email || !system) {
      return;
    }

    if (user.email.includes(' ')) {
      setValidationErrors?.(['Respondents']);
      return;
    }

    if (
      (respondentType === RespondentType.It ||
        respondentType === RespondentType.All) &&
      system.itRespondents?.some((r) => r.email === user.email)
    ) {
      setValidationErrors(['Conflict']);
      return;
    }

    if (
      (respondentType === RespondentType.Business ||
        respondentType === RespondentType.All) &&
      system.businessRespondents?.some((r) => r.email === user.email)
    ) {
      setValidationErrors(['Conflict']);
      return;
    }

    if (
      respondentType === RespondentType.It ||
      respondentType === RespondentType.All
    ) {
      addRespondents({
        customerSystemId: system.id as string,
        respondents: [{ email: user.email, it: true }],
      })
        .unwrap()
        .then(() => {
          if (selectedSystem) {
            dispatch(systemActions.selectedSystem());
          }
        });
    }

    if (
      respondentType === RespondentType.Business ||
      respondentType === RespondentType.All
    ) {
      addRespondents({
        customerSystemId: system.id as string,
        respondents: [{ email: user.email, it: false }],
      })
        .unwrap()
        .then(() => {
          if (selectedSystem) {
            dispatch(systemActions.selectedSystem());
          }
        });
    }
  };

  const handleSelect = (user: Admin) => {
    setSelectedUser(selectedUser?.email === user.email ? undefined : user);
  };

  const handleSearch = () => {
    handleGetRespondents(filter?.entityIds, filter?.email?.toLowerCase());
  };

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    if (filter) {
      dispatch(
        getManagersExport({
          filter,
          name: t('UserRoles.DiosRespondent.Title'),
        })
      );
    }
  };

  useEffect(() => {
    setFilter({
      email: undefined,
      entityIds: undefined,
      hasChanges: false,
    });
    setValidationErrors(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  useEffect(() => {
    if (filter?.hasChanges === false) {
      handleGetRespondents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (error) {
      setValidationErrors((error as ApiError)?.validationErrors);
    } else {
      setValidationErrors(undefined);
    }
  }, [error]);

  if (isLoading) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.DiosRespondent.Text')}

        {i18next.exists('UserRoles.DiosRespondent.Help') && (
          <HelpIcon
            style={{ marginLeft: 7 }}
            title={t('UserRoles.DiosRespondent.Title')}
            text={<TransArray i18nKey="UserRoles.DiosRespondent.Help" />}
          />
        )}
      </HelpIconLeft>

      <LinkWithTooltip
        isPrimary={true}
        isDisabled={isReadOnly}
        handleClick={() => setIsAdding(true)}
        title={t('Administrate.SystemManagers.Add.Title')}
        tooltipTitle={t('Administrate.SystemManagers.Add.TooltipTitle')}
        tooltip={t('Administrate.SystemManagers.Add.Tooltip')}
        icon={<BsPersonPlus />}
        style={{ marginTop: 20, marginBottom: 10 }}
      />

      {data && data.length > 0 && (
        <>
          <LinkWithTooltip
            title={t('Administrate.Entity.Export.Download.Download')}
            tooltipTitle={t('Administrate.Entity.Export.Download.TooltipTitle')}
            tooltip={t('Administrate.Entity.Export.Download.Tooltip')}
            icon={isExportPosting ? <LoaderSmall /> : <BsFileExcel />}
            handleClick={(e) => !isExportPosting && handleExportDownload(e)}
            isDisabled={isReadOnly || isExportPosting}
            style={{ paddingTop: 10 }}
          />
        </>
      )}

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(
                `Administrate.System.Respondent.ValidationError.${validationError}`
              )}
            </div>
          ))}
        </AlertWarning>
      )}

      {isAdding && (
        <RespondentAdd setIsAdding={setIsAdding} handleAdd={handleAdd} />
      )}

      <UserFilters
        handleSearch={handleSearch}
        filter={filter}
        setFilter={setFilter}
      />

      {data && data.length === 0 && (
        <div style={{ marginTop: 20 }}>
          {t('Administrate.SystemManagers.NoUsers')}
        </div>
      )}

      {data && data.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 15, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th sortKey="email" sorter={sorter}>
                  {t('Administrate.Entity.Email')}
                </Th>
                <ThRight sortKey="lastLoginDate" sorter={sorter}>
                  {t('Administrate.Entity.LastLogin')}
                </ThRight>
                <Th />
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Tr
                      isSelected={selectedUser?.email === item.email}
                      isHoverable={true}
                      onSelect={() => handleSelect(item)}
                    >
                      <Td style={{ width: 25 }}>
                        <BsPersonBadge />
                      </Td>
                      <Td>
                        <Managers managers={[item.email as string]} />
                      </Td>
                      <TdRight
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item.lastLoginDate
                          ? formatDate(parseISO(item.lastLoginDate))
                          : '-'}
                      </TdRight>
                      <TdRight>
                        {item.email !== accessToken.user?.email && (
                          <div
                            style={{
                              verticalAlign: 'middle',
                              display: 'flex',
                              justifyContent: 'end',
                            }}
                          >
                            <EditButtons
                              user={item}
                              setValidationErrors={setValidationErrors}
                              isReadOnly={isReadOnly}
                            />
                          </div>
                        )}
                      </TdRight>
                    </Tr>

                    {selectedUser && selectedUser.email === item.email && (
                      <Tr>
                        <Td colSpan={4} style={{ padding: 0 }}>
                          <SystemsListForUser list={item.systems} />
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

Respondents.displayName = 'Respondents';

const EditButtons = ({
  user,
  setValidationErrors,
  isReadOnly,
}: {
  user: Admin;
  setValidationErrors: (validationErrors: string[] | undefined) => void;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedSystem = useAppSelector(selectSelectedSystem);

  const [removeUserRespondents] = useRemoveUserRespondentsMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirmDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    if (!user.email) {
      return;
    }

    removeUserRespondents({
      email: user.email,
    })
      .unwrap()
      .then(() => {
        if (selectedSystem) {
          dispatch(systemActions.selectedSystem());
        }
      });

    setIsDeleting(false);
  };

  const handleDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setValidationErrors(undefined);
    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t('Administrate.SystemManagers.Delete.ConfirmTitle')}
          handleKeyEnter={(e) => handleConfirmDelete(e)}
          handleKeyEsc={(e) => handleDelete(e, false)}
        >
          <div>{t('Administrate.SystemManagers.Delete.ConfirmText')}</div>

          <AlertWarning style={{ marginTop: 10, marginBottom: 10 }}>
            {t('Administrate.SystemManagers.Delete.Warning')}
          </AlertWarning>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleConfirmDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <Impersonate email={user.email as string} />
      <SendForgotPasswordEmail email={user.email as string} />

      <BadgeMini
        tooltipTitle={t('Administrate.SystemManagers.Delete.TooltipTitle')}
        tooltip={t('Administrate.SystemManagers.Delete.Tooltip')}
        onClick={(e) => handleDelete(e, true)}
        style={{ marginLeft: 7 }}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};
