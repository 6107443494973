import { CacheTags, diosApi } from 'api/diosApi';
import { MessageSchedule } from 'models';

const scheduleApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get schedule
     */
    getSchedule: build.query<MessageSchedule, string>({
      query: (_customerId) => `reminders/schedule`,
      providesTags: [CacheTags.Schedule],
    }),

    /**
     * Update schedule
     */
    updateSchedule: build.mutation({
      query: ({ startDate }: { startDate: string | null }) => ({
        url: `reminders/schedule`,
        method: 'PUT',
        body: {
          startDate,
        },
      }),
      invalidatesTags: [CacheTags.Schedule],
    }),
  }),
});

export const { useGetScheduleQuery, useUpdateScheduleMutation } = scheduleApi;
